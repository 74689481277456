import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import useLanguageStrings from "../../hooks/useLanguageStrings";
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import Modal from '../Modal';
import { useSiteSettingsContext } from '../../context/SiteSetting';
import Image from '../Image';
import { buildFullAssetUrl } from '../../utils';
import { SubmitFormSuccessResponse, WebForm } from '../../shared/form';
import { getCookie, setCookie } from '../../utils/cookies';

import Button from "../../components/Button";

const logoWidthMobile = 44;
const logoWidth = 60;
const cookieYear = 'xv-year';

const useStyles = createUseThemedStyles((theme) => ({
  success: {
    color: theme.color.white,
    fontFamily: theme.fonts.heading,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    [theme.breakpoints.md]: {
      minWidth: 500,
      minHeight: 300,
    },
  },
  successTitle: {
    fontSize: 25,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    textTransform: 'uppercase',
    [theme.breakpoints.md]: {
      fontSize: 32,
      marginBottom: theme.spacing.large,
    },
  },
  successDescription: {
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.md]: {
      fontSize: 20,
    },
  },
  ageCookieModal: {
    background: theme.color.whiteText,
    maxWidth: 750,
    '& > [class^="button-"]': {
      display: 'none',
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      display: 'none',
    },
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  ageModal: {
    fontFamily: theme.fonts.heading,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    [theme.breakpoints.md]: {
      minWidth: 500,
      minHeight: 300,
    },
  },
  ageModalTitle: {
    fontSize: 17,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    color: theme.color.gray,
    textTransform: 'uppercase',
    margin: ['1.5rem', 0],
    textAlign: 'center',
  },
  ageModalDescription: {
    margin: 0,
    fontSize: 15,
    color: theme.color.grayText,
    lineHeight: '160%',
    letterSpacing: '0.1px',
  },
  terms: {
    color: theme.color.grayMask,
    fontFamily: theme.fonts.base,
    fontSize: 13,
    lineHeight: '140%',
    margin: [theme.spacing.large, 0],
    '& a': {
      color: theme.color.primary,
      display: 'inline',
      textDecoration: 'underline',
    },
  },
  aTerms: {
    color: theme.color.primary,
    display: 'inline',
    textDecoration: 'underline',
  },
  footer: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    textTransform: 'uppercase',
    color: theme.color.gray,
    marginBottom: 0,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2.5rem',
  },
  logo: {
    // filter: ({ ht }: { ht: HeaderTheme }) => ht === 'light' ? 'invert(1)' : 'none',
    filter: 'invert(1)',
    flex: `0 0 ${logoWidthMobile}px`,
    width: logoWidthMobile,
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.md]: {
      flex: `0 0 ${logoWidth}px`,
      width: logoWidth,
    },
    transition: 'filter .3s ease',
  },
  formContainer: {
    fontFamily: theme.fonts.base,
    textAlign: 'center',

  },
  formError: {
    color: theme.color.primary,
    marginTop: theme.spacing.small,
    fontSize: '12px',
  },
  contentLink: {
    
    borderRadius: '0px',
    backgroundColor: '#ffffff', /* Establece el fondo del botón como transparente */
    colorBorder: 'none',
    border: 'none',
    minWidth: '86px',
    height: '60px',
    textAlign: 'center',

  },
  
  buttonText: {
    color: '#7C7E7F',
    minWidth: '86px',
    fontSize: 16,
  },

  visiterAge:{
    color: '#E84B31',
    fontSize: '12px',
    lineHeight: '25px',
    fontFamily: 'Playfair Display',

  },
  buttonLeft:{
    marginLeft: '37px',
  }
}));

const AgeCookie: React.FC = () => {
  const styles = useStyles();
  const dictionary = useLanguageStrings();
  const { logo } = useSiteSettingsContext();
  const url = logo?.file?.data.attributes.uri.url;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<SubmitFormSuccessResponse | null>(null);
  const [mostrarBoton, setMostrarBoton] = useState(false);

  const onSubmitSuccess = () => {
    setCookie(cookieYear, "true");
    ageVerify();
  };

  const handleConfirmClose = () => {
    setMostrarBoton(true);
  };

  const ageVerify = () => {
    const confirm = getCookie(cookieYear);

    if (confirm === 'true') {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    ageVerify();
  });

  return (
    <>
      <Modal isOpen={!!success} handleClose={() => setSuccess(null)}>
        <div className={styles.success}>
          <h3 className={styles.successTitle}>{success?.success_title}</h3>
          <p className={styles.successDescription}>
            {success?.success_message}
          </p>
        </div>
      </Modal>

      <Modal
        className={styles.ageCookieModal}
        isOpen={!!isOpen}
        handleClose={() => setIsOpen(false)}
        permanent={true}
      >
        <div className={styles.ageModal}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              {url ? <Image src={buildFullAssetUrl(url)} alt={logo?.alt} /> : null}
            </div>
          </div>
          <p className={styles.ageModalDescription}>
            {dictionary['age.modal.description']}
          </p>
          <h3 className={styles.ageModalTitle}>{dictionary['age.modal.title']}</h3>

          <div className={styles.formContainer}>

          <Button 
            className={styles.contentLink}
            onClick={onSubmitSuccess} 
           >
          <span className={styles.buttonText}>{dictionary["yes"]}</span>

          </Button>

          <Button
          className={`${styles.contentLink} ${styles.buttonLeft}`}
          onClick={handleConfirmClose}
          >
             <span className={styles.buttonText}>{dictionary["no"]}</span>

          </Button>       

          {mostrarBoton && (   
          <p
            className={styles.visiterAge}
            dangerouslySetInnerHTML={{ __html: dictionary['visiter_age'] }}
          />
          )}
          </div>
         
          <p
            className={styles.terms}
            dangerouslySetInnerHTML={{ __html: dictionary['age.modal.footer.terms'] }}
          />

          <p className={styles.footer}>{dictionary['age.modal.footer']}</p>
        </div>
      </Modal>
    </>
  )
}

export default AgeCookie;
