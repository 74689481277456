import React from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { color } from "../theme";

const useStyles = createUseThemedStyles(theme => ({
  burger: {
    display: 'flex',
    flexDirection: 'column',
    width: 25,
    cursor: 'pointer',
    background: 'none',
    appearance: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    margin: 0,
    '& span': {
      background: ({ color }: { color: Color }) => theme.color[color],
      borderRadius: 2,
      height: 2,
      margin: [2, 0],
      transformOrigin: 'left',
      transition: 'transform .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6), background .3s ease',
      width: '100%',
      '&:nth-of-type(1)': {
        transform: 'scaleX(70%)',
      },
      '&:nth-of-type(2)': {
        transform: 'scaleX(100%)',
      },
      '&:nth-of-type(3)': {
        transform: 'scaleX(50%)',
      },
    },
    '&:hover': {
      '& span': {
        '&:nth-of-type(1)': {
          transform: 'scaleX(50%)',
        },
        '&:nth-of-type(2)': {
          transform: 'scaleX(85%)',
        },
        '&:nth-of-type(3)': {
          transform: 'scaleX(70%)',
        },
      },
    }
  },
}));

type Color = keyof typeof color;

const Burger: React.FC<JSX.IntrinsicElements["button"] & { color?: Color }> = ({ color = 'white', ...props }) => {
  const styles = useStyles({ color });
  return (
    <button className={styles.burger} {...props}>
      <span/>
      <span/>
      <span/>
    </button>
  )
}

export default Burger;
