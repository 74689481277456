import React from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import hexToRgba from "hex-to-rgba";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  hovered?: boolean;
  children?: React.ReactNode;
  zIndex?: number,
}

const useStyles = createUseThemedStyles({
  hover: {
    background: ({ theme }) => hexToRgba(theme.color.primary, 0.95),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: ({ zIndex }: { zIndex: number }) => zIndex,
    color: '#fff',
  },
})

const PrimaryHover: React.FC<Props> = ({ hovered = false, children, zIndex = 2 }) => {
  const styles = useStyles({ zIndex });
  return (
    <AnimatePresence>
      {hovered && (
        <motion.div
          className={styles.hover}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeIn', duration: .3 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default PrimaryHover;
