import React, { useEffect } from 'react';
import { Marker, Popup, useMap } from "react-leaflet";

type Props = {
  location: GeolocationPosition | null
}

const UserLocation: React.FC<Props> = ({ location }) => {
  const map = useMap();

  useEffect(() => {
    // fly only on click
    if (location && Math.abs(location.timestamp - Date.now()) < 1000) {
      map.flyTo([location.coords.latitude, location.coords.longitude], map.getZoom())
    }
  }, [map, location]);

  return location && (
    <Marker position={[location.coords.latitude, location.coords.longitude]} />
  );
}

export default UserLocation;
