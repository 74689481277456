const borderBottom = '2px solid white';
export const button = {
  primary: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ':hover': { borderBottom },
    ':active': { borderBottom }
  },
  active: { borderBottom },
  rotating: {
    transition: 'transform .3s ease',
    '&:hover': {
      transform: 'rotate(180deg)'
    },
  }
};
