import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppLanguage } from "../../shared/local";
import LanguageProvider from "./LanguageProvider";

interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  defaultLanguage?: AppLanguage;
  children: React.ReactNode,
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  defaultLanguage = AppLanguage.French,
}) => (
  <RouterComponent>
    <Route path="/:lang([a-zA-Z]{2})">
      {({ match, location }) => {
        /**
         * Get current language
         * Set default locale to en if base path is used without a language
         */
        const lang = (match?.params?.lang ?? defaultLanguage) as AppLanguage;

        /**
         * If language is not in route path, redirect to language root
         */
        const { pathname } = location;
        if (!pathname.includes(`/${lang}/`)) {
          return <Redirect to={`/${lang}/`} />;
        }

        /**
         * Return Intl provider with default language set
         */
        return (
          <LanguageProvider locale={lang}>
            {children}
          </LanguageProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
