import React from 'react';
import { NestedMenu } from "../../utils";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import FooterNavItem from "./NavItem";

type Props = {
  items: NestedMenu,
}

const useStyles = createUseThemedStyles(theme => ({
  nav: {
    flexGrow: 1,
    width: '100%',
  },
  navLevel1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    width: '100%',
    [theme.breakpoints.md]: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
    }
  },
}))

const FooterNav: React.FC<Props> = ({ items = [] }) => {
  const styles = useStyles();

  return <nav className={styles.nav}>
    <ul className={styles.navLevel1}>
      {items.map(menuItem => (
        <FooterNavItem menuItem={menuItem} key={menuItem.item.id} />
      ))}
    </ul>
  </nav>
};

export default FooterNav;
