import React from 'react';
import { createUseStyles } from "react-jss";
import cn from 'classnames';

type Props = {
  children: React.ReactNode,
  tag?: any;
  href?: string;
  target?: string;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const useStyles = createUseStyles({
  button: {
    appearance: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    outline: 'none',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    textDecoration: 'none',
  }
});

const IconButton = React.forwardRef<HTMLElement | null, Props>(
  ({ tag: Wrapper = 'button', children, className, ...props }, ref) => {
    const styles = useStyles();
    return (
      <Wrapper
        {...props}
        ref={ref}
        // @ts-ignore
        className={cn(styles.button, className)}
        >
        {children}
      </Wrapper>
    )
  });

export default IconButton;
