import React, { useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import SliderNavigation from "./SliderNavigation";
import { useTheme } from "react-jss";
import { VignonTheme } from "../theme";
import { Swiper as SwiperClass } from "swiper/types";
import SliderPagination from "./SliderPagination";
import useMeasure from "react-use-measure";
import { useIsDesktop } from "../context/ScreenSize";

interface SlideData {
  isActive: boolean;
  isVisible: boolean;
  isDuplicate: boolean;
  isPrev: boolean;
  isNext: boolean;
}

type Props = {
  items?: (React.ReactNode | ((slideData: SlideData) => React.ReactNode))[],
  className?: string;
  navigation?: React.ReactNode;
  pagination?: boolean;
  title?: string;
  subtitle?: string;
  options?: SwiperProps;
  staticItem?: React.ReactNode,
}

const useStyles = createUseThemedStyles((theme) => ({
  container: {
    overflow: 'hidden',
    paddingTop: 70,
    paddingBottom: theme.spacing.xlarge,
    [theme.breakpoints.md]: {
      padding: [86, 0, theme.spacing.xlarge],
    }
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.large,
    ...theme.container.small,
    [theme.breakpoints.md]: {
      padding: 0,
    },
    margin: 'none',
  },
  sliderContainer: {
    [theme.breakpoints.md]: {
      ...theme.container.small,
      height: 542 + 56 + 32,
      position: 'relative',
    }
  },
  slider: {
    width: `calc(100% - 20px)`,
    position: 'relative',
    left: 20,
    [theme.breakpoints.md]: {
      position: 'absolute',
      top: 0,
      left: theme.container.small.padding[1],
      height: '100%',
      width: `calc(100vw - ((100vw - ${theme.container.small.maxWidth}px + (${theme.container.small.padding[1]} * 2)) / 2))`,
      overflow: 'hidden',
    }
    //
  },
  slide: {
    width: '100%',
    maxWidth: 450,
    [theme.breakpoints.md]: {
      width: 350,
    }
  },
  swiper: {
    width: '100%',
    overflow: 'visible',
    margin: 0,
    '& .swiper-wrapper': {
      alignItems: 'stretch',
    },
    '& .swiper-slide': {
      height: 'auto',
    },
    [theme.breakpoints.md]: {
      width: 350,
    }
  },
  pagination: {
    flex: '0 0 0',
  },
  content: {
    flex: '1 0 0',
  },
  navigation: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.large,
  },
  title: {
    color: theme.color.gray,
    textTransform: 'uppercase',
    fontSize: 25,
    lineHeight: '29px',
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    margin: 0,
  },
  subTitle: {
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    color: theme.color.primary,
    fontSize: 20,
    lineHeight: '27px',
    margin: 0,
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.md]: {
      flexDirection: 'row',
    },
  },
}));


const SliderGallery: React.FC<Props> = ({ items = [], className, pagination, title, subtitle, options, staticItem }) => {
  const styles = useStyles();
  const theme = useTheme() as VignonTheme;
  const [swiper, setSwiper] = useState<SwiperClass | undefined>();
  const [ref, bounds] = useMeasure();
  const isDesktop = useIsDesktop();

  if (!items.length) return null;

  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>

      <div className={styles.sliderContainer} style={{ height: isDesktop ? 542 + bounds.height + 32 : undefined }}>
        <div className={styles.slider} style={{ height: isDesktop ? 542 + bounds.height + 32 : undefined }}>
          <div className={styles.contentContainer} ref={ref}>
            <div className={styles.content}>
              <h3 className={styles.title}>{title}</h3>
              <p className={styles.subTitle}>{subtitle}</p>
            </div>
            {items.length > 1 && isDesktop ? (
              <SliderPagination
                className={styles.pagination}
                color={theme.color.grayLine}
                slides={items.length}
                swiper={swiper}
              />
            ) : null}
            {items.length > 1 && isDesktop ? (
              <SliderNavigation
                className={styles.navigation}
                color={theme.color.grayLine}
                slides={items.length}
                swiper={swiper}
              />
            ) : null}
          </div>
          <div className={styles.sliderWrapper}>
            { staticItem && isDesktop && staticItem }
            <Swiper
              className={styles.swiper}
              onSwiper={s => setSwiper(s)}
              {...options}
            >
              {[
                staticItem && !isDesktop ? <SwiperSlide key={-1} className={styles.slide}>{staticItem}</SwiperSlide> : null,
                ...items.map((slide, index) => (
                <SwiperSlide key={index} className={styles.slide}>
                  {slide}
                </SwiperSlide>
              ))]}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderGallery;
