import { getPage, getView } from "../api";
import { GetArticleViewResponse } from "../shared/article";
import { TranslatePathResponse } from "../shared/router";
import { DrupalBlogRestResponse } from "../shared/blog";
import { BlogPageData, PageData } from "./pageData";
import { ViewType } from "../shared/views";
import { DefaultPageResponse } from "../shared/page";

export type getDataFn<T = PageData> = {
  (entity: TranslatePathResponse["entity"], search?: string): Promise<T | undefined>
}

const getData: getDataFn = async function(entity, search) {
  if (entity.type && entity.bundle && entity.id) {
    const response = await getPage<DefaultPageResponse>(entity, search);
    const data = response.data;
    const { breadcrumbs, field_metatags: meta, alternate_links: alternateLinks, ...pageData } = data;
    return {
      meta,
      breadcrumbs,
      alternateLinks,
      data: pageData,
    };
  }
}

const getBlogPageData: getDataFn = async function(entity) {
  if (entity.type && entity.bundle && entity.id) {
    const response = await getPage<DrupalBlogRestResponse>(entity);
    const data = response.data;
    const { breadcrumbs, field_blog_page_content: content, field_metatags: meta, alternate_links: alternateLinks } = data;
    const pageContent = await Promise.all(content.map(async (paragraph) => {
      if ('view' in paragraph) {
        const viewData = await getView<GetArticleViewResponse>({ type: paragraph.view?.type, id: paragraph.view?.id })
        return {
          view: { ...paragraph?.view, data: viewData ?? [] }
        };
      }
      return paragraph;
    }))

    return {
      meta,
      breadcrumbs,
      alternateLinks,
      content: pageContent as BlogPageData["content"]
    };
  }
}

export const createGetData = (type: ViewType): getDataFn | null => {
  switch (type) {
    case ViewType.PAGE_BLOG: {
      return getBlogPageData;
    }
    case ViewType.PAGE_ARTICLE: {
      return getData;
    }
    case ViewType.PAGE_GAMME: {
      return getData;
    }
    case ViewType.PAGE_HOME: {
      return getData;
    }
    case ViewType.PAGE_PRODUCT: {
      return getData;
    }
    case ViewType.TERM_GAMME: {
      return getData;
    }
    case ViewType.PAGE_PAGE: {
      return getData;
    }
    case ViewType.EMPTY:
    case ViewType.PAGE_404:
    default: {
      return null;
    }
  }
}
