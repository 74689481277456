import React, { useState } from 'react';
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import useWebForm from '../../hooks/useWebForm';
import { useSiteSettingsContext } from '../../context/SiteSetting';
import SubscribeForm from '../../components/Subscribe/SubscribeForm';
import { getSubscribeForm } from '../../api/form';
import bgLogo from '../../assets/logo.svg';
import { SubmitFormSuccessResponse, WebForm } from '../../shared/form';
import useLanguageStrings from '../../hooks/useLanguageStrings';
import Modal from '../Modal';

const useStyles = createUseThemedStyles((theme) => ({
  footer: {
    position: 'relative',
    width: '100%',
    padding: '30px 20px',
    minHeight: '325px',
    [theme.breakpoints.md]: {
      padding: '70px 50px',
      flexGrow: 1,
    },
  },
  footerBg: {
    position: 'absolute',
    width: '200px',
    height: '200px',
    top: '-20px',
    right: '-65px',
    zIndex: -1,
    background: `url('${bgLogo}') no-repeat top right`,
    backgroundSize: '140px',
    transform: 'rotate(30deg) translate3d(0, 0, 0)', //translate to fix antialiasing
    [theme.breakpoints.md]: {
      width: '300px',
      height: '500px',
      backgroundSize: '290px',
      top: '0',
      right: '30px',
    },
  },
  subscribeForm: {
    display: 'inline-block',
  },
  description: {
    color: theme.color.white,
    fontFamily: theme.fonts.base,
    marginBottom: 40,
    width: '100%',
    // maxWidth: 500,
    '& h5': {
      fontFamily: "'Ubuntu', sans-serif",
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 25,
      lineHeight: 1.2,
      textTransform: 'uppercase',
      margin: 0,
    },
    '& p': {
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
  success: {
    color: theme.color.white,
    fontFamily: theme.fonts.heading,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    [theme.breakpoints.md]: {
      minWidth: 500,
      minHeight: 300,
    },
  },
  successTitle: {
    fontSize: 25,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    textTransform: 'uppercase',
    [theme.breakpoints.md]: {
      fontSize: 32,
      marginBottom: theme.spacing.large,
    },
  },
  successDescription: {
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.md]: {
      fontSize: 20,
    },
  },
}));

const ReservationPageFooter: React.FC = () => {
  const styles = useStyles();
  const dictionary = useLanguageStrings();

  const { footer: { subscribeForm } } = useSiteSettingsContext();
  const webForm = useWebForm(getSubscribeForm);
  const [success, setSuccess] = useState<SubmitFormSuccessResponse | null>(null);

  const handleSuccess = (response: SubmitFormSuccessResponse) => {
    setSuccess(response);
  };

  return (
    <>
      <Modal isOpen={!!success} handleClose={() => setSuccess(null)}>
        <div className={styles.success}>
          <h3 className={styles.successTitle}>{success?.success_title}</h3>
          <p className={styles.successDescription}>
            {success?.success_message}
          </p>
        </div>
      </Modal>

      <div className={styles.footer}>
        <div className={styles.footerBg}></div>
        <div className={styles.description}>
          <h5>{dictionary['newsletter.title']}</h5>
          <p>
            {dictionary['newsletter.desc']}
          </p>
        </div>
        <div className={styles.subscribeForm}>
          <SubscribeForm webForm={webForm} onSubmitSuccess={handleSuccess} />
        </div>
      </div>
    </>
  );
};

export default ReservationPageFooter;
