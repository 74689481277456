import React from "react";
import { DefaultViewProps } from "../shared/views";
import Meta from "../components/Meta";
import { PageHomeData } from "../router/pageData";
import IconButton from "../components/IconButton";
import { BsChevronDown } from "react-icons/bs";
import Slider from "../components/Slider/Slider";
import SliderNavigation, { navHeight } from "../components/SliderNavigation";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { Heading, Markup, TitleWithLine } from "../components/Typography";
import GammeComponent from "../components/GammeComponent";
import { headerHeightDesktop } from "../shared/styles";
import useLanguageStrings from "../hooks/useLanguageStrings";
import Image from "../components/Image";
import useMeasure from "react-use-measure";
import SliderGallery from "../components/SliderGallery";
import hexToRgba from "hex-to-rgba";
import { useIsDesktop } from "../context/ScreenSize";
import PageHomeContentBlock from "../components/Content/PageHomeContentBlock";
import { AutoplayOptions } from "swiper/types";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import BlogPageSlider from "../components/Blog/BlogPageSlider";
import { DrupalRestResponseSlider } from "../shared/blog";

const slideContentWidth = 1224;
const slideContentSmallDesktop = 900;
const slideContentWidthMobile = 290;

const useStyles = createUseThemedStyles((theme) => ({
  slider: {
    position: "relative",
  },
  sliderImg: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    zIndex: 1,
    "& img": {
      height: "100%",
      objectFit: "cover",
    },
  },
  sliderTitle: {
    marginBottom: "6vw",
  },
  sliderDescription: {
    fontSize: 42,
    lineHeight: "64px",
    margin: 0,
    "& .wysiwyg": {
      lineHeight: "inherit",
      fontSize: 54,
    },
    [theme.breakpoints.md]: {
      fontSize: 54,
    },
  },
  sliderContent: {
    ...theme.container.base,
    maxWidth: slideContentWidthMobile,
    position: "relative",
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 100,
    paddingTop: `${navHeight + 10 + headerHeightDesktop}px`,
    color: "#fff",
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.md]: {
      paddingBottom: 100,
      justifyContent: "flex-end",
      maxWidth: slideContentSmallDesktop,
    },
    [theme.breakpoints.lg]: {
      maxWidth: slideContentWidth,
    },
  },
  sliderNavigationContainer: {
    ...theme.container.base,
    maxWidth: slideContentWidth,
    position: "absolute",
    bottom: 100,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  sliderNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  sliderSmallText: {
    fontSize: 20,
    marginTop: "6vw",
    fontFamily: theme.fonts.heading,
    fontStyle: "italic",
    [theme.breakpoints.md]: {
      maxWidth: `calc(50% - 35px)`,
    },
  },
  scrollDown: {
    position: "absolute",
    left: "50%",
    zIndex: 10,
    transform: "translateX(-50%)",
    bottom: theme.spacing.large,
    color: theme.color.white,
  },
  content: {
    background: theme.color.white,
    display: "flex",
    padding: 0,
    overflow: "hidden",
  },
  testimonials: {
    background: `linear-gradient(0deg, ${theme.color.white} 0%, ${theme.color.white} 20%, ${theme.color.grayBg} 20%, ${theme.color.grayBg} 100%)`,
    [theme.breakpoints.md]: {
      background: `linear-gradient(0deg, ${theme.color.white} 0%, ${theme.color.white} 20%, ${theme.color.grayBg} 20%, ${theme.color.grayBg} 95%, ${theme.color.white} 95%, ${theme.color.white} 100%)`,
    },
  },
  testimonialsItem: {
    position: "relative",
    width: "100%",
    height: "auto",
    boxSizing: "border-box",
    "&:before": {
      content: "''",
      position: "absolute",
      zIndex: 2,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: hexToRgba(theme.color.black, 0.8),
      transition: "background .3s ease",
    },
    "&$testimonialsItemActive:before": {
      background: hexToRgba(theme.color.black, 0.4),
    },
    [theme.breakpoints.md]: {
      width: 350,
    },
  },
  testimonialsContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    padding: [0, theme.spacing.medium, theme.spacing.large],
    zIndex: 3,
    textAlign: "center",
    "& .wysiwyg": {
      "& p": {
        fontWeight: 700,
        margin: 0,
        textTransform: "uppercase",
        fontSize: 16,
        lineHeight: "18px",
      },
      "& h1, & h2, & h3, & h4, & h5, & h6": {
        margin: 0,
        fontWeight: 400,
        fontStyle: "italic",
      },
      "& h4": {
        fontSize: 20,
        lineHeight: "27px",
      },
    },
  },
  testimonialsImage: {
    position: "relative",
    zIndex: 1,
  },
  testimonialsItemActive: {},
  testimonialsLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: [theme.spacing.large, 0],
  },
  contentLink: {
    color: "rgb(232, 75, 49)",
    cursor: "pointer",
    display: "inline-flex",
    outline: "none",
    padding: "10px 10px 10px 15px",
    fontSize: "14px",
    minWidth: "170px",
    background: "none",
    minHeight: "36px",
    alignItems: "center",
    borderColor: "rgb(232, 75, 49)",
    borderStyle: "solid",
    borderWidth: "1.5px",
    borderRadius: "9999px",
    textTransform: "uppercase",
    justifyContent: "space-between",
    textDecoration: "none",
    appearance: "none",

    "& svg": {
      marginLeft: theme.spacing.medium,
      fontSize: "16px",
      "& path": {
        strokeWidth: 50,
      },
    },
  },
  blogsSection: {
    background: "white",
    position: "relative",
    overflow: "hidden",
    "&:before": {
      content: "''",
      background: theme.color.grayBg,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      [theme.breakpoints.md]: {
        top: "5%",
        left: "10%",
        width: "100%",
        height: "90%",
      },
    },
  },
  blogs: {
    padding: [80, 0],
  },
  blogsItem: {
    position: "relative",
    width: 300,
    height: "auto",
    boxSizing: "border-box",
    textDecoration: "none",
    [theme.breakpoints.md]: {
      width: 350,
    },
  },
  blogsContent: {
    background: "white",
    width: "100%",
    height: 130,
    padding: theme.spacing.medium,
    zIndex: 3,
    textAlign: "center",
    [theme.breakpoints.md]: {
      height: 110,
    },
    "& .wysiwyg": {
      "& p": {
        fontWeight: 700,
        margin: 0,
        textTransform: "uppercase",
        fontSize: 16,
        lineHeight: "18px",
      },
      "& h1, & h2, & h3, & h4, & h5, & h6": {
        margin: 0,
        fontWeight: 400,
        fontStyle: "italic",
      },
      "& h4": {
        fontSize: 20,
        lineHeight: "27px",
      },
    },
  },
  blogsImage: {
    position: "relative",
    zIndex: 1,
  },
  blogsItemActive: {},
  blogsLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    padding: [0, 0, "7rem", 0],
    zIndex: 1,
    position: "relative",
    marginTop: "-20px",
    [theme.breakpoints.md]: {
      marginTop: "-120px",
    },
  },
  title: {
    fontFamily: theme.fonts.heading,
    fontStyle: "italic",
    textTransform: "none",
    letterSpacing: "0.5px",
    "&:before": {
      display: "none",
    },
  },
  description: {
    color: theme.color.grayMask,
    fontFamily: theme.fonts.base,
    fontStyle: "normal",
    fontSize: 17,
    textTransform: "uppercase",
    fontWeight: "bold",
    margin: ["0.5rem", 0, "1rem", 0],

    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

const HomeView: React.FC<DefaultViewProps & { data: PageHomeData }> = ({
  data: pageData,
}) => {
  const slider = pageData.data.field_slider ?? [];
  const styles = useStyles();
  const dictionary = useLanguageStrings();
  const [ref, bounds] = useMeasure();

  const content =
    (pageData?.data?.field_content && pageData?.data?.field_content[0]) ?? {};

  const handleScrollDownClick = () => {
    window?.scrollTo({
      top: bounds.top,
      behavior: "smooth",
    });
  };

  const isDesktop = useIsDesktop();
  const autoplaySwiper: AutoplayOptions = {
    delay: 4000,
  };

  // const blogs = pageData.data.field_slider_blog?.map(slide => ({
  //   field_title: '',
  //   field_text: '',
  //   field_link: '',
  //   alt: '',
  //   url: '',
  // })) as DrupalRestResponseSlider["slider"];

  return (
    <>
      <Meta tags={pageData.meta} />
      <div className={styles.slider}>
        <Slider
          autoplay={autoplaySwiper}
          pagination={!isDesktop}
          items={slider.map((item, index) => (
            <React.Fragment key={index}>
              <Image
                className={styles.sliderImg}
                src={item.url}
                alt={item.alt}
              />
              <div className={styles.sliderContent}>
                <TitleWithLine
                  linePosition="left"
                  className={styles.sliderTitle}
                >
                  {item.field_title}
                </TitleWithLine>
                <Heading className={styles.sliderDescription} tag="h3">
                  <Markup html={item.field_text} />
                </Heading>
                <Markup
                  className={styles.sliderSmallText}
                  html={item.field_small_text}
                />
              </div>
            </React.Fragment>
          ))}
          navigation={
            <div className={styles.sliderNavigationContainer}>
              {slider.length > 1 ? (
                <SliderNavigation slides={slider.length} />
              ) : null}
            </div>
          }
        />
        {isDesktop && (
          <IconButton
            className={styles.scrollDown}
            onClick={handleScrollDownClick}
          >
            <BsChevronDown size={20} />
          </IconButton>
        )}
      </div>
      <section className={styles.content} ref={ref}>
        <PageHomeContentBlock
          title={content.field_block_title}
          subTitle={content.field_block_sub_title}
          image1={content.field_image_1}
          image1alt={content.field_image_1_alt}
          image={content.field_image}
          imageAlt={content.field_image_alt}
          imageRightSide={content.field_block_image_right_side}
          imageRightSideAlt={content.field_block_image_right_side_alt}
          content={isDesktop ? content.field_content : undefined}
          contentBottom={!isDesktop ? content.field_content : undefined}
          contentRight={content.field_content_1}
          link={content.field_link}
        />
      </section>
      <GammeComponent items={pageData.data.field_gamme} />
      <section className={styles.blogsSection}>
        <SliderGallery
          className={styles.blogs}
          title={dictionary.journal}
          subtitle="&nbsp;"
          items={pageData.data.field_slider_blog?.map(
            (item, index) =>
              ({ isActive }) =>
                (
                  <Link
                    key={index}
                    to={item.view_node ?? ""}
                    className={`${styles.blogsItem}${
                      isActive ? ` ${styles.blogsItemActive}` : ""
                    }`}
                  >
                    <div className={styles.blogsImage}>
                      <Image
                        src={item.field_image}
                        alt={item.title}
                        aspectRatio={0.9}
                      />
                    </div>
                    <div className={styles.blogsContent}>
                      <TitleWithLine className={styles.title}>
                        {item.field_category}
                      </TitleWithLine>
                      <Heading className={styles.description} tag="h3">
                        <Markup html={item.title} />
                      </Heading>
                    </div>
                  </Link>
                )
          )}
          options={{
            spaceBetween: 30,
          }}
          pagination
        />
        <div className={styles.blogsLink}>
          <Link to={"blog"} className={styles.contentLink}>
            <span>{dictionary.journal}</span>
            <IoAdd />
          </Link>
        </div>
      </section>
    </>
  );
};

export default HomeView;
