import React, { useEffect, useState } from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import IconButton from "./IconButton";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoChevronForwardCircleOutline } from 'react-icons/io5';
import hexToRgba from "hex-to-rgba";
import { useSwiper } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";

type Props = {
  slides?: number;
  className?: string;
  color?: string;
  swiper?: SwiperClass
}

export const navHeight = 35;

const useStyles = createUseThemedStyles((theme) => ({
  container: {

  },
  navigation: {
    display: 'flex',
    alignItems: "center",
    '& svg': {
      display: 'block',
      stroke: ({ color }: { color: string }) => color ?? hexToRgba(theme.color.white, 0.4),
      transition: 'stroke .3s ease',
      '&:hover': {
        stroke: theme.color.primary,
      }
    }
  },
  counter: {
    margin: [0, theme.spacing.large],
    color: ({ color }: { color: string }) => color ?? theme.color.white,
    fontSize: 11,
  },
  active: {
    display: 'inline-block',
    color: theme.color.primary,
    transform: 'translateY(-15%)'
  }
}));

const SliderNavigation: React.FC<Props> = ({ slides = 0, className, color, swiper: swiperProp, }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const styles = useStyles({ color });
  let swiper = useSwiper();

  if (swiperProp) swiper = swiperProp;


  useEffect(() => {
    if (swiper) {
      const slideChange = (s: SwiperClass) => setActiveSlide(s.realIndex)
      swiper.on('slideChange', slideChange);

      return () => swiper.off('slideChange', slideChange);
    }
  }, [swiper])

  return (
    <div className={`${styles.navigation}${className ? ` ${className}` : ''}`}>
      <IconButton onClick={() => swiper?.slidePrev()}>
        <IoChevronBackCircleOutline size={navHeight}/>
      </IconButton>
      <div className={styles.counter}><span className={styles.active}>{activeSlide + 1}</span> / {slides}</div>
      <IconButton onClick={() => swiper?.slideNext()}>
        <IoChevronForwardCircleOutline size={navHeight} />
      </IconButton>
    </div>
  )
};

export default SliderNavigation;
