export const wrapper = {
  95: {
    maxWidth: '95%'
  },
  90: {
    maxWidth: '90%'
  },
  85: {
    maxWidth: '80%'
  }
};
