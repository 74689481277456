import { TermGammeProduct } from "../shared/termGamme";
import { useMemo } from "react";

type UseCreateProductGroups = (products?: Array<TermGammeProduct>) => [
  TermGammeProduct | undefined,
  Array<TermGammeProduct>,
  TermGammeProduct | undefined,
  Array<TermGammeProduct>,
  TermGammeProduct | undefined,
  Array<TermGammeProduct>
];

export const useCreateProductGroups: UseCreateProductGroups = (products = []) => {
  return useMemo(() => [products[0], products.slice(1, 4), products[4], products.slice(5, 8), products[8], products.slice(9)], [products])
}
