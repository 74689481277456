import React, { useMemo } from 'react'
import { getElementId, useWebformElement } from '../utils';

import WebformElementWrapper from './WebformElementWrapper';
import Select from 'react-select'
import { FormComponentType } from "../types";
import useLanguageStrings from "../../../../hooks/useLanguageStrings";
import { useTheme } from "react-jss";
import { VignonTheme } from "../../../../theme";
import ReactSelect from "react-select";
import hexToRgba from "hex-to-rgba";

type SelectStyles = Parameters<typeof ReactSelect>[0]["styles"];

export const WebformSelect: FormComponentType = ({ element, error, onChange = () => null, color, columns }) => {
	const id = getElementId(element.name);
	const [inputProps, settings] = useWebformElement(element, {
		className: 'form-control',
		name: element.name,
		id
	});

  const dictionary = useLanguageStrings();
  const theme = useTheme() as VignonTheme;

	const customStyles = useMemo<SelectStyles>(() => ({
		control: (provided) => ({
			...provided,
			backgroundColor: theme.color.white,
			border: 'none!important',
      boxShadow: 'none',
      borderRadius: 0,
			minHeight: 60,
			color: color ? theme.color[color] : theme.color.gray,
      outline: 'none',
		}),
		option: (provided) => ({
			...provided,
			backgroundColor: theme.color.white,
      minHeight: 60,
			border: 'none',
      display: 'flex',
      alignItems: 'center',
			color: color ? theme.color[color] : theme.color.gray,
			'&:hover': {
				backgroundColor: hexToRgba(theme.color.grayBg, 0.5),
				zIndex: 5,
			},
			'&:not(:last-of-type)': {
				borderBottom: '1px solid',
				borderColor: hexToRgba(color ? theme.color[color] : theme.color.gray, 0.8),
			}
		}),
		singleValue: (provided) => ({
			...provided,
			color: color ? theme.color[color] : theme.color.gray,
      border: 'none',
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: theme.color.white,
      borderRadius: 0,
			border: 'none',
			color: color ? theme.color[color] : theme.color.gray,
			boxShadow: 'none',
		}),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 18,
    }),
		indicatorsContainer: (provided) => ({
			...provided,
			div: {
				padding: 10,
				svg: {
					path: {
						fill: `${theme.color.primary}!important`,
					}
				}
			}
		}),
		indicatorSeparator: () => ({
			display: 'none',
		})
	}), [theme, color]);

	return (
		<WebformElementWrapper settings={settings} error={error} labelFor={id} color={color} columns={columns}>
			<Select
				{...inputProps}
				onChange={onChange}
				styles={customStyles}
				placeholder={inputProps.placeholder || dictionary.select}
				options={element.options}
				defaultValue = {element.defaultValue || inputProps.defaultValue || ''}
			/>
		</WebformElementWrapper>
	)
};

export default WebformSelect;
