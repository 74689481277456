import React from 'react';
// @ts-ignore
import { CookieBanner } from '@ashumenko/react-gdpr-cookie-banner';
import useLanguageStrings from "../../hooks/useLanguageStrings";

const GDPRBanner: React.FC = () => {
  const dictionary = useLanguageStrings();

  function initGTM(gtmID: string): void {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtmID;
    script.async = true;
    // Load track GTM script after load lib.
    script.onload = () => {
      const script2 = document.createElement('script');
      script2.innerHTML = 'window.dataLayer = window.dataLayer || [];\n' +
        '  function gtag(){dataLayer.push(arguments);}\n' +
        '  gtag(\'js\', new Date());\n' +
        '\n' +
        '  gtag(\'config\', \'' + gtmID + '\');';

      document.head.appendChild(script2);
    }
    document.head.appendChild(script);
  }

  return (
    <CookieBanner
      message={dictionary['message.cookie.banner']}
      showAcceptSelectionButton={true}
      acceptSelectionButtonText={dictionary['accept.selection.button.text']}
      acceptAllButtonText={dictionary['accept.all.button.text']}
      necessaryOptionText={dictionary['necessary.option.text']}
      preferencesOptionText={dictionary['preferences.option.text']}
      statisticsOptionText={dictionary['statistics.option.text']}
      marketingOptionText={dictionary['marketing.option.text']}
      privacyPolicyLinkText={dictionary['message.policy.link']}
      policyLink={'mentions-legales'}
      declineButtonText={dictionary['decline.all.button.text']}
      showDeclineButton={true}
      onAcceptPreferences = {() => {
        // load your preferences trackers here
      }}
      onAcceptStatistics = {() => {
        if (window.location.hostname !== 'localhost') {
          initGTM('G-0C358M83TN');
          initGTM('G-DV8VEVXLKG');
        }
      }}
      onAcceptMarketing = {() => {
        // load your marketing trackers here
      }}
      styles={{
        dialog: {
          bottom: 0,
          position: 'fixed',
          top: null,
          zIndex: 100000,
          left: 0,
          right: 0,
          backgroundColor: '#EDEDED',
          paddingBottom: '15px',
          paddingTop: '25px',
        },
        message: {
          fontSize: '14px',
          color: '#5F5C5B',
          fontStyle: 'normal',
          fontFamily: 'Playfair Display',
          lineHeight: '21px',
          marginBottom: '15px'
        },
        button: {
          backgroundColor: '#E84B31',
          borderRadius: '100px',
          padding: '7px 15px 7px 15px',
          textTransform: 'uppercase',
          color: 'white',
          fontSize: '14px',
          cursor: 'pointer',
          border: 'none',
          marginLeft: '5px',
          marginRight: '5px'
        },
        policy: {
          fontSize: '14px',
          color: 'rgb(95, 92, 91)',
        },
        optionWrapper: {
          display: 'inline-block',
          position: 'relative',
          marginRight: '15px',
          float: 'left'
        }
      }}
    />
  )
}

export default GDPRBanner;
