import React from 'react';

import {FormComponentType} from "../Form/Webform/types";
import {getAttributeValue, getElementId, INPUT_TYPES_WHITELIST, useWebformElement} from "../Form/Webform/utils";
import {createUseThemedStyles} from "../../hooks/createUseThemedStyles";
import Button from "../Button";
import {IoChevronForward} from "react-icons/io5";

const useStyles = createUseThemedStyles(theme => ({
  contactsButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    justifyContent: 'space-around',
  }
}))

export const WebformInputAction: FormComponentType = ({ element, error, color, columns }) => {
	const id = getElementId(element.name);
  const styles = useStyles({ color });
	const [inputProps, settings] = useWebformElement(element, {
		name: element.name,
		type: INPUT_TYPES_WHITELIST.includes(element.type) ? element.type : 'text',
		id
	});

	if (element.type === 'hidden') {
		return <input {...inputProps} />
	}

	return (
    <Button type="submit" className={styles.contactsButton} filled
            icon={<IoChevronForward/>} fontSize={0}>{getAttributeValue('#submit__label', element)}</Button>
	)
};

export default WebformInputAction;
