const heading = "'Playfair Display', sans-serif";
const base = "'Ubuntu', sans-serif";

const baseSize = 16;

export const fonts = {
  heading,
  base,
  baseSize,
}
