export const getModalRoot = () => {
  let modalRoot = document.getElementById('___vignon__modal-root')
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute("id", '___vignon__modal-root');
    const root = document.getElementById('root');
    if (root && root.parentNode) {
      root.parentNode.appendChild(modalRoot);
    } else {
      document.body.appendChild(modalRoot)
    }
  }
  return modalRoot;
};
