import { ArticlePreviewResponse } from "../shared/article";
import { getMonth, fromUnixTime } from "date-fns";

export const useGroupedArticles = (articles: ArticlePreviewResponse[]) => {
  const monthMap = new Map();

  articles.forEach((article) => {
    const created = fromUnixTime(Number(article.created));
    const month = getMonth(created);
    const articles = monthMap.get(month) ?? [];
    articles.push(article);
    monthMap.set(month, articles);
  })

  return Array.from<[number, ArticlePreviewResponse[]]>(monthMap.entries());
}
