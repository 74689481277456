import { DrupalAlternateLinks, DrupalBreadcrumbs, DrupalMetatags } from "./index";
import { DefaultPageResponse } from "./page";
import {link} from "fs";

export type ArticlePreviewResponse = {
  created: string;
  field_category: string;
  field_category_id: string;
  field_image: string;
  title:string;
  view_node: string;
  nid: string;
}

export type ArticleCategory = {
  field_category_id: string;
  field_category: string
}

export type GetArticleViewResponse = ArticlePreviewResponse[];

type ArticleResponsePagination = {
  title?: string;
  field_category?: string;
  slider?: Array<{ alt?: string; url?: string; }>;
  nid?: number;
  type?: string;
  url?: string;
}

export enum SocialNetworksEnum {
  instagram = 'Instagram',
  twitter = 'Twitter',
  facebook = 'Facebook',
  youtube = 'YouTube',
}

export type SocialNetworkResponse = {
  field_social_link: string;
  field_social_network: SocialNetworksEnum
}

export type ArticleResponse = {
  data: {
    field_category?: string;
    field_content?: string;
    created?: string;
    slider?: Array<{ alt: string; url: string; }>;
    field_author?: {
      title?: string;
      field_content?: string;
      field_author_image?: {
        alt?: string;
        url?: string;
      },
      field_social_networks?: Array<SocialNetworkResponse>;
      nid: number;
      type?: string;
    },
    next_article?: ArticleResponsePagination
    previous_article?: ArticleResponsePagination
    most_recent_articles?: {
      title?: string;
      url: string;
      data?: Array<{
        title?: string;
        field_category?: string;
        slider?: Array<{ alt?: string; url?: string; }>;
        nid: string,
        type?: string;
        url?: string;
      }>
    },
    categories?: {
      title?: string;
      data?: Array<{
        name?: string;
        id: number;
        count?: number;
        url: string;
      }>
    }
  }
} & DefaultPageResponse;
