import React, { createContext, useContext, useEffect, useState } from 'react';
import { screens } from "../theme";
import throttle from 'lodash.throttle';

type ScreenSize = keyof typeof screens;
type ScreenSizeContext = ScreenSize;
type Props = { children: React.ReactNode; }

const defaultScreenValue: ScreenSizeContext = 'xs'
const ScreenSizeContext = createContext<ScreenSizeContext>(defaultScreenValue);
const { Provider } = ScreenSizeContext;

const ScreenSizeProvider: React.FC<Props> = ({ children }) => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(defaultScreenValue);

  useEffect(() => {

    const policyUrl = document.querySelector(".react-cookie-law-policy") as HTMLElement;
    const policyText = document.querySelector(".react-cookie-law-msg");
    if (typeof policyText !== 'undefined' && typeof policyUrl !== 'undefined') {
      policyText?.appendChild(policyUrl);
    }
  }, []);

  useEffect(() => {
    const resizeListener = throttle(() => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
      const size = (Object.keys(screens) as Array<ScreenSize>).reverse().find(key => window.innerWidth >= screens[key]) ?? 'xs';
      setScreenSize(size);
    }, 300);
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [])

  return (
    <Provider value={screenSize}>{children}</Provider>
  )
}

export const useScreenSizeContext = () => {
  return useContext(ScreenSizeContext);
}

export const useIsDesktop = () => {
  const screenSize = useScreenSizeContext();
  return ['md', 'lg', 'xl'].includes(screenSize);
}

export default ScreenSizeProvider;
