import React from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import hexToRgba from "hex-to-rgba";
import { VignonTheme } from "../theme";

type Props = {
  className?: string;
  bg?: string;
  bgAfter?: string;
}

const useStyles = createUseThemedStyles({
  mask: {
    position: "absolute",
    zIndex: 2,
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: ({ theme, bg }: { theme: VignonTheme, bg?: string, bgAfter?: string }) => bg ?? hexToRgba(theme.color.grayLine, 0.2),
    opacity: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      zIndex: 2,
      background:  ({ bgAfter }: { theme: VignonTheme, bg?: string, bgAfter?: string }) => bgAfter ?? 'radial-gradient(60% 60% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    },
    '&:after': {
      // content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      zIndex: 2,
      background: 'linear-gradient(180deg, #000000 -40%, rgba(255, 255, 255, 0) 100%);',
    }
  },
})

const Mask: React.FC<Props> = ({ className, bg, bgAfter, }) => {
  const styles = useStyles({ bg, bgAfter });

  return (
    <div className={`${styles.mask}${className ? ` ${className}` : ''}`} />
  )
}

export default Mask;
