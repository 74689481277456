import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { DrupalAlternateLinks } from "../shared";

type AlternateLinksContext = [DrupalAlternateLinks, Dispatch<SetStateAction<DrupalAlternateLinks>>];
type Props = { children: React.ReactNode; }

const defaultAlternateLinksValue: AlternateLinksContext = [[], () => null]
const AlternateLinksContext = createContext<AlternateLinksContext>(defaultAlternateLinksValue);
const { Provider } = AlternateLinksContext;

const AlternateLinksProvider: React.FC<Props> = ({ children }) => {
  const [alternateLinks, setAlternateLinks] = useState<DrupalAlternateLinks>([]);
  return (
    <Provider value={[alternateLinks, setAlternateLinks]}>{children}</Provider>
  )
}

export const useAlternateLinks = () => {
  return useContext(AlternateLinksContext);
}

export const useSetAlternateLinks = () => {
  const [,setAlternateLinks] = useContext(AlternateLinksContext);
  return setAlternateLinks;
}

export default AlternateLinksProvider;
