import { jsonApi } from "./jsonApi";
import { SubmitFormResponse, WebFormResponse } from "../shared/form";
import { api } from "./api";
import drupalBaseEndpoint from "./base";
import { getApiLanguage } from "./i18n";
import { FormDataType } from "../components/Form/Webform/types";

export const getReservationForm = () => jsonApi<WebFormResponse>(`webform/webform/d215546d-3568-42f3-b73d-2e7cb15ad2f5`);
export const getSubscribeForm = () => jsonApi<WebFormResponse>(`webform/webform/ba07f00b-6b71-4c61-8060-0c671bc3c68d`);
export const submitWebForm = (data: FormDataType) => api<SubmitFormResponse>(`${drupalBaseEndpoint}/${getApiLanguage()}/gatsby_webform/submit`, { method: 'POST', body: JSON.stringify(data) })
export const getAgeCookieForm = () => jsonApi<WebFormResponse>(`webform/webform/98312e67-21e6-455b-8aa7-6cc4a3567814`);
