import React, { useEffect, useState } from 'react';
import { NestedMenu } from "../../utils";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { ArrayElement } from "../../shared";
import { useIsDesktop } from "../../context/ScreenSize";
import { Link } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  menuItem: ArrayElement<NestedMenu>,
}

const useStyles = createUseThemedStyles(theme => ({
  navItemLevel1: {
    borderTop: `1px solid ${theme.color.grayMask}`,
    '&:first-child': {
      border: 'none',
    },
    [theme.breakpoints.md]: {
      borderTop: 'none',
      paddingRight: theme.spacing.medium,
      paddingTop: theme.spacing.small,
      flex: '1 1 0',
      '&:last-child': {
        paddingRight: 0,
      }
    }
  },
  navLevel2: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  navLevel2Content: {
    paddingTop: theme.spacing.small,
    paddingBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      padding: 0,
    }
  },
  navItemLevel2: {
    padding: [12, 0],
  },
  navLinkLevel1: {
    ...theme.link.secondary,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20,
    cursor: 'default',
    minHeight: 46,
    fontFamily: theme.fonts.heading,
    margin: [theme.spacing.small, 0],
    [theme.breakpoints.md]: {
      marginBottom: theme.spacing.large,
      display: 'inline-block',
    }
  },
  navLinkLevel2: {
    ...theme.link.primary,
    display: 'inline-block',
    fontSize: 12,
    textTransform: 'none',
    [theme.breakpoints.md]: {
      textTransform: 'uppercase',
    }
  },
  navLinkIcon: {
    color: theme.color.primary,
    transition: '.3s ease',
    transformOrigin: 'center center',
    '&.active': {
      transform: 'rotate(180deg)',
    }
  }
}))

const FooterNavItem: React.FC<Props> = ({ menuItem }) => {
  const styles = useStyles();
  const isDesktop = useIsDesktop();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isDesktop);
  }, [isDesktop])

  return (
    <li className={styles.navItemLevel1} key={menuItem.item.id}>
      <Link
        to={menuItem.item.attributes.url}
        className={styles.navLinkLevel1}
        onClick={e => {
          e.preventDefault();
          if (!isDesktop) {
            setIsActive(active => !active);
          }
        }}
      >
        <span>{menuItem.item.attributes.title}</span>
        {!isDesktop && <FaChevronDown size={11} className={`${styles.navLinkIcon} ${isActive ? 'active': ''}`} />}
      </Link>
      <AnimatePresence>
        {isActive && (
          <motion.ul
            className={styles.navLevel2}
            animate={{ maxHeight: 2000 }}
            initial={{ maxHeight: 0 }}
            exit={{ maxHeight: 0, transition: { delay: 0.1 } }}
          >
            <motion.div
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
              className={styles.navLevel2Content}
            >
              {menuItem.children.map(item =>
                <li className={styles.navItemLevel2} key={item.id}>
                  <Link
                    to={item.attributes.url}
                    className={styles.navLinkLevel2}>
                    {item.attributes.title}
                  </Link>
                </li>
              )}
            </motion.div>
          </motion.ul>
        )}
      </AnimatePresence>
    </li>
  )
};

export default FooterNavItem;
