import React, { FormEvent, FormEventHandler, useRef, useState } from 'react';

import { getAttributeValue, formToJSON } from './utils'
import {
	WebformDebug,
	WebformInput,
	WebformSelect,
	WebformTextarea,
	WebformCheckbox,
	WebformCheckboxGroup,
	WebformText,
	WebformMarkup,
	WebformFile,
	WebformSelectOther
} from './components';
import Button from "../../Button";
import ReCAPTCHA from "react-google-recaptcha";
import { BsChevronRight } from 'react-icons/bs';
import { FormElement, SubmitFormSuccessResponse, WebForm } from "../../../shared/form";
import { Color, color as themeColor } from "../../../theme";
import { CustomComponentType, FormDataType, FormProps, WebFormComponent, WebFormError, WebFormErrors } from "./types";
import { omit } from "../../../utils";
import { createUseThemedStyles } from "../../../hooks/createUseThemedStyles";
import { submitWebForm } from "../../../api/form";
import useLanguageStrings from "../../../hooks/useLanguageStrings";

export const DEFAULT_SUBMIT_LABEL = 'Submit';

export class WebformError extends Error {
	response;

	constructor(response: any) {
		super();
		this.response = response
	}
}

const useStyles = createUseThemedStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: theme.spacing.medium,
  },
  submitDefault: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingTop: theme.spacing.medium,
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing.large,
    paddingRight: theme.spacing.large,
  }
}));

export function renderWebformElement(props : { element: FormElement, error?: WebFormError, CustomComponent?: CustomComponentType, debug?: boolean, color: Color, columns: number, styles: ReturnType<typeof useStyles> }) {
  const { element, error, CustomComponent, debug, color, columns, styles } = props;
	const customComponentAPI = {
		error,
		color,
		columns
	};

	// Render using custom compoennt if provided:
	if (CustomComponent) {
		return <CustomComponent element={element} {...customComponentAPI} />
	}

	// Othervise select renderer based on element type:
	switch (element.type) {
		case 'textfield':
			return <WebformInput element={{ ...element, type: 'text' }} {...customComponentAPI} />;
		case 'textarea':
			return <WebformTextarea element={element} {...customComponentAPI} />;
		case 'tel':
		case 'number':
		case 'email':
		case 'hidden':
		case 'webform_email_multiple':
			return <WebformInput element={element} {...customComponentAPI} />;
		case 'checkbox':
		case 'radio':
			/** Render single checkbox or radio element. */
			return <WebformCheckbox element={element} {...customComponentAPI} />;
		case 'checkboxes':
			return <WebformCheckboxGroup element={{ ...element, type: 'checkbox' }} {...customComponentAPI} />;
		case 'radios':
			return <WebformCheckboxGroup element={{ ...element, type: 'radio' }} {...customComponentAPI} />;
		case 'select':
			return <WebformSelect element={element} {...customComponentAPI} />;
		case 'webform_select_other':
			return <WebformSelectOther element={element} {...customComponentAPI} />;
		case 'processed_text':
			return <WebformText element={element} {...customComponentAPI} />;
		case 'webform_markup':
			return <WebformMarkup element={element} {...customComponentAPI} />;
		case 'webform_document_file':
			return <WebformFile element={element} {...customComponentAPI}/>;
		case 'webform_actions':
			return (
				<div className={styles.actions}>
					<Button type="submit" color={color} fontSize={0}>{getAttributeValue('#submit__label', element) || DEFAULT_SUBMIT_LABEL}</Button>
				</div>
			);
		// Unknown element type -> render as json string
		default:
			return debug ? <WebformDebug element={element} error={error} /> : null
	}
}

type Props = {
  webform: WebForm;
  columns?: number;
  color?: Color;
  debug?: boolean;
  customComponents?: {
    [key: string]: CustomComponentType;
  },
  noValidate?: boolean;
  onValidate?: (e: FormEvent) => void;
  onSubmit?: (data: FormDataType, event: FormEvent) => Promise<boolean>;
  onSuccess?: (response: SubmitFormSuccessResponse, data: FormDataType, event: FormEvent) => void;
  onError?: (errors: any, event: FormEvent) => void;
  endpoint?: string;
  extraData?: any;
  textSubmit?: string;
} & FormProps

/**
 * Drupal webform react component.
 */
const Webform: React.FC<Props> = ({ webform, customComponents = {}, debug, columns = 1, color = 'grayText', textSubmit = '', ...props }) => {
	const [errors, setErrors] = useState<WebFormErrors>({});
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
	const captchaRef = useRef<ReCAPTCHA>(null);
  const dictionary = useLanguageStrings();
  const styles = useStyles();

	const submitHandler: FormEventHandler = async (event) => {
		event.preventDefault();

		const target = event.currentTarget as HTMLFormElement;

		// Clear errors from previous submit.
		setErrors({});

		// Remove lingering css classes from previous submits.
		target.classList.remove('form-submitting', 'form-error', 'form-submitted');

		if ((!props.onValidate || props.onValidate(event)) && target.checkValidity()) {
			// Let css know that this form was validated and is being submitted.
			target.classList.add('was-validated', 'form-submitting');

			// Serialize form data.
			const data = await formToJSON(target.elements);

			try {
				// If onSubmit returns false skip submitting to API.
				if (props.onSubmit) {
          const submitted = await props.onSubmit(data, event);
          if (!submitted) {
            target.classList.replace('form-submitting', 'form-submitted');
            return
          }
				}

				// Send request!
				// Submit form to API.

				// const response = await axios.post(props.endpoint, formData, {
				const response = await submitWebForm({
						...props.extraData,
						...data,
             method: 'POST',
          webform_id: webform.attributes.drupal_internal__id,
					  'g-recaptcha-response': recaptchaToken
				});

				if (response?.error) {
					throw new WebformError(response)
				}

				// Convey current form state.
				target.classList.replace('form-submitting', 'form-submitted');
				props.onSuccess && props.onSuccess(response, data, event)
			} catch (err: any) {
				// API should return error structure if validation fails.
				// We use that to render error messages to the form.
				if (err.response && err.response.error) {
					setErrors(err.response.error)
				}

				// Convey current form state.
				target.classList.replace('form-submitting', 'form-error');
				props.onError && props.onError(err, event);
				setRecaptchaToken(null);
				captchaRef.current && captchaRef.current.reset();
			}
		} else {
			// Let css know this form was validated.
			target.classList.add('was-validated', 'form-error')
		}
	};

	return (
		<form
		  className={styles.form}
			onSubmit={submitHandler}
			noValidate={props.noValidate}
			data-webform-id={props.id}
			{...omit(props, ['onSuccess'])}
		>
			{/* Render webform elements */}
			{webform.attributes.elements.map(element => (
				<React.Fragment key={element.name}>
					{renderWebformElement({
						element,
						error: errors[element.name],
						CustomComponent: (customComponents && customComponents[element.name]),
						debug,
						columns,
						color,
            styles,
					})}
				</React.Fragment>
			))}
			{/* Render recatcha */}

			{/*<ElementWrapper*/}
			{/*	error={errors['g-recaptcha-response']}*/}
			{/*	settings={{ attributes: {}, states: {}, pure: false }}*/}
			{/*	color={color}*/}
			{/*>*/}
			{/*	<ReCAPTCHA*/}
			{/*		ref={captchaRef}*/}
			{/*		sitekey={''}*/}
			{/*		onChange={setRecaptchaToken}*/}
			{/*	/>*/}
			{/*</ElementWrapper>*/}

			{/* Render default submit button if it is not defined in elements array. */}
			{webform.attributes.elements.find(element => element.type === 'webform_actions' || element.name === 'actions') === undefined && (
				<div className={styles.submitDefault}>
					<Button type="submit">{textSubmit ? textSubmit : dictionary['submit']}
            <BsChevronRight size={15} />
          </Button>
				</div>
			)}
		</form>
	)
};

export default Webform;
