import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { createNestedMenu } from "../../utils";
import { HOMEPAGE_DEFAULT_ID } from "../../shared/settings";
import { useSiteSettingsContext } from "../../context/SiteSetting";
import { AnimatePresence, motion } from "framer-motion";
import IconButton from "../IconButton";
import { AiFillCloseCircle } from 'react-icons/ai';
import barrelsImg from '../../assets/barrels.png';
import useMeasure from 'react-use-measure'
import { VignonTheme } from "../../theme";
import { useTheme } from "react-jss";
import LanguageSwitcher from "../../i18n/components/LanguageSwitcher";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import { BiHomeAlt } from 'react-icons/bi';
import MenuItem from "./MenuItem";
import { Link } from 'react-router-dom';
import SocialsAside from "../SocialsAside";
import { useIsDesktop } from "../../context/ScreenSize";
import useDisableBody from "../../hooks/useDisableBody";
import {useLanguageContext} from "../../i18n/components/LanguageProvider";

type Props = {
  active?: boolean;
  setMenuActive?: Dispatch<SetStateAction<boolean>>;
}

const useStyles = createUseThemedStyles(theme => ({
  container: {
    ...theme.container.base,
    marginTop: 0,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'stretch',
    paddingBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      paddingBottom: 0,
      height: '100%',
    },
  },
  menu: {
    background: theme.color.gray,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: 'var(--app-height)',
    overflow: 'auto',
    zIndex: 15,
    transformOrigin: 'top',
  },
  topNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.color.primary,
    marginRight: theme.spacing.large,
    ...theme.button.rotating,
  },
  mainNav: {
    width: '100%',
    paddingTop: theme.spacing.medium,
    [theme.breakpoints.md]: {
      paddingTop: theme.spacing.large,
      paddingBottom: theme.spacing.large,
      paddingRight: theme.spacing.xlarge,
      overflow: 'auto',
    }
  },
  content: {
    height: '100%',
  },
  nav: {
    width: '100%',
  },
  homeButton: {
    color: theme.color.white,
    textTransform: 'uppercase',
    transition: 'color .3s ease',
    '& span': {
      ...theme.link.primary,
      fontWeight: 500,
      fontSize: 11,
    },
    '&:hover, &:active': {
      '& span:after': theme.link.primary['&:hover, &:active']['&:after']
    },
    '& svg': {
      marginRight: theme.spacing.large,
    }
  },
  closeBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItems: {
    padding: 0,
    margin: [theme.spacing.large, 0],
    listStyle: 'none',
  },
  homepageLink: {
    all: 'unset',
  }
}));

const MainMenu: React.FC<Props> = ({ active = false, setMenuActive }) => {
  const theme: VignonTheme = useTheme();
  const language = useLanguageContext();
  const [containerRef, { width: containerWidth }] = useMeasure();
  const [asideRef, { width: asideWidth }] = useMeasure();
  const styles = useStyles();
  const { burgerMenu, homeLink } = useSiteSettingsContext();
  const dictionary = useLanguageStrings();
  const handleOnClose = useCallback(() => setMenuActive ? setMenuActive(false) : null, [setMenuActive]);
  const items = useMemo(() => createNestedMenu(burgerMenu), [burgerMenu]);
  const homepage = items.find(({ item }) => item.id === HOMEPAGE_DEFAULT_ID);
  const menu = items.filter(item => item !== homepage);
  const homepageLink = homepage?.item.attributes.url ?? '/' + language;
  const isDesktop = useIsDesktop();

  useDisableBody(active);

  return (
    <AnimatePresence>
      {active && (
        <motion.nav
          className={styles.menu}
          animate={{ scaleY: 1 }}
          initial={{ scaleY: 0 }}
          exit={{ scaleY: 0, transition: { delay: 0.1 } }}
        >
          <motion.section
            animate={{ opacity: 1, transition: { delay: 0.2 } }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
            className={styles.content}
          >
            <div className={styles.container} ref={containerRef}>
              <div
                className={styles.mainNav}
                style={{ width: isDesktop ? `calc(100% - (${asideWidth}px - (31vw - ${containerWidth - theme.fonts.baseSize * 4}px) / 2))` : undefined }}
              >
                <div className={styles.topNav}>
                  <div className={styles.closeBlock}>
                    {setMenuActive ? (
                      <IconButton
                        onClick={handleOnClose}
                        className={styles.closeButton}
                      >
                        <AiFillCloseCircle size={35}/>
                      </IconButton>
                    ) : null}
                    <Link
                      className={styles.homepageLink}
                      to={homepageLink}
                      onClick={handleOnClose}
                    >
                      <IconButton className={styles.homeButton}>
                        <BiHomeAlt size={20}/>
                        <span>{homeLink}</span>
                      </IconButton>
                    </Link>
                  </div>
                  <LanguageSwitcher/>
                </div>
                <ul className={styles.menuItems} onClick={handleOnClose}>
                  {menu.map((item, index) => (
                    <MenuItem key={index} menuItem={item} index={index} />
                  ))}
                </ul>
              </div>
            </div>
            <SocialsAside bg={barrelsImg} />
          </motion.section>
        </motion.nav>
      )}
    </AnimatePresence>
  )
}

export default MainMenu;
