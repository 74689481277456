import React from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import { useLanguageContext } from "../../i18n/components/LanguageProvider";
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import { Heading } from "../Typography";

const locales = {
  fr,
  en: undefined,
}

type Props = {
  month: number;
  articlesQuantity: number;
  articles: any[];
}

const useStyles = createUseThemedStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    minHeight: '70vw',
    [theme.breakpoints.sm]: {
      minHeight: 550,
    },
    [theme.breakpoints.md]: {
      minHeight: 0,
      width: `calc((100% - ${40 * 2}px) / 3)`,
      flex: `0 0 calc((100% - ${40 * 2}px) / 3)`,
    }
  },
  monthName: {
    fontSize: 40,
    position: 'relative',
    zIndex: 2,
    margin: 0,
    textTransform: 'capitalize'
  },
  articles: {
    fontSize: 60,
    lineHeight: 1,
    color: theme.color.whiteText,
    position: 'relative',
    zIndex: 1,
    margin: [-30, 0, 0],
  },
  hashtags: {
    color: theme.color.primary,
    fontSize: 9,
    fontFamily: theme.fonts.base,
  }
}))

const ArticleMonthPreview: React.FC<Props> = ({ month, articlesQuantity, articles}) => {
  const styles = useStyles();
  const dictionary = useLanguageStrings();
  const language = useLanguageContext();
  const hashtags = articles.map((article) => article?.field_category);
  const filtered_hastags = hashtags.filter((type, index) =>  hashtags.indexOf(type) === index)
  const monthName = format(new Date(2022, month, 1), 'MMMM', { locale: locales[language]  })

  return (
    <li className={styles.container}>
      <Heading tag="h3" className={styles.monthName}>
        {monthName}
      </Heading>
      <Heading tag="p" className={styles.articles}>
        {articlesQuantity} {articlesQuantity > 1 ? dictionary['articles'] : dictionary['article']}
      </Heading>
      <Heading tag="span" className={styles.hashtags}>
        {filtered_hastags.join(' ')}
      </Heading>
    </li>
  )
}

export default ArticleMonthPreview;
