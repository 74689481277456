import React from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  className?: string;
}

const useStyles = createUseThemedStyles(theme => ({
  popover: {
    position: 'absolute',
    left: 0,
    top: '100%',
    transformOrigin: '0% 0%'
  }
}))

const Popover: React.FC<Props> = ({ children, active, className }) => {
  const styles = useStyles();
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={`${styles.popover}${className ? ` ${className}` : ''}`}
          animate={{ scaleY: 1, opacity: 1, }}
          initial={{ scaleY: 0, opacity: 0, }}
          exit={{ scaleY: 0, opacity: 0, transition: { delay: 0.1 } }}
        >
         <motion.div
           animate={{ opacity: 1, transition: { delay: 0.2 } }}
           initial={{ opacity: 0 }}
           exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
         >
           {children}
         </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Popover;
