import React, { forwardRef } from 'react';
import { useSiteSettingsContext } from "../context/SiteSetting";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { SocialLinkType } from "../shared/settings";
import { FaYoutube, FaInstagram, FaFacebookF, FaLinkedin } from "react-icons/fa";
import { AiOutlineMail } from 'react-icons/ai';
import Image from './Image';

const socialIcons = {
  [SocialLinkType.FACEBOOK]: FaFacebookF,
  [SocialLinkType.INSTAGRAM]: FaInstagram,
  [SocialLinkType.YOUTUBE]: FaYoutube,
  [SocialLinkType.LINKEDIN]: FaLinkedin,
}

const logoWidth = 66;

type AsidePosition = 'left' | 'right';

type Props = {
  bg: string;
  onClick?: () => any;
  position?: AsidePosition;
}

const useStyles = createUseThemedStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    position: 'relative',
    height: '100%',
  },
  logo: {
    margin: [80, 0],
    width: logoWidth,
    '& img': {
      width: '100%',
    }
  },
  link: {
    ...theme.link.secondary,
    color: theme.color.white,
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 25,
    lineHeight: 25,
  },
  socials: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    listStyle: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      height: '10vh',
      [theme.breakpoints.md]: {
        content: 'normal',
      }
    }
  },
  socialItem: {
    padding: 0,
    margin: [0, 0, 40],
    '&:last-child': {
      marginBottom: 0,
    }
  },
  linkMailto: {
    marginTop: '10vh',
  },
  aside: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.md]: {
      width: '35%',
      position: 'absolute',
      right: ({ position }: { position: AsidePosition }) => position === 'right' ? 0 : undefined,
      left: ({ position }: { position: AsidePosition }) => position === 'left' ? 0 : undefined,
      top: 0,
      height: '100%',
      //minWidth: 450,
    }
  },
  asideImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    'picture&': {
      position: 'absolute',
    },
    objectFit: 'cover',
    '& img': {
      height: '100%',
    }
  },
}))

const SocialsAside = forwardRef<HTMLElement, Props>(({ bg, onClick, position= 'right' }, ref) => {
  const { logo, socials } = useSiteSettingsContext();
  const url = logo?.file?.data.attributes.uri.url;
  const styles = useStyles({ position });

  return (
    <aside className={styles.aside} ref={ref} onClick={onClick}>
      <Image className={styles.asideImg} src={bg} alt="barrels"/>
      <div className={styles.content}>
        <a href="mailto:contact@xaviervignon.com" className={`${styles.link} ${styles.linkMailto}`}><AiOutlineMail /></a>
        <div className={styles.logo}>
          {url ? <Image src={url} alt={logo?.alt}/> : null}
        </div>
        <ul className={styles.socials}>
          {socials.map((social) => {
            const Icon = socialIcons[social.type];
            return (
              <li key={social.type} className={styles.socialItem}>
                <a
                  href={social.uri} target="_blank" className={styles.link}
                >
                  <Icon />
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </aside>
  )
});

export default SocialsAside;
