import React, { useEffect, useState } from 'react';
import { Markup } from "../Typography";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { AnimatePresence, motion } from "framer-motion";
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import useLanguageStrings from "../../hooks/useLanguageStrings";
import IconButton from "../IconButton";

type Props = {
  html?: string;
  className?: string;
  height?: number;
}

const useStyles = createUseThemedStyles(theme => ({
  accordion: {
    marginBottom: theme.spacing.xlarge,
  },
  showMore: {
    fontFamily: theme.fonts.base,
    fontWeight: 500,
    fontSize: 12,
    '& span': {
      display: 'inline-block',
      marginRight: theme.spacing.medium,
    }
  },
  accordionContent: {
    overflow: 'hidden',
  },
  line: {
    margin: [theme.spacing.large, 0, theme.spacing.medium],
    border: 'none',
    background: theme.color.grayLine,
    height: 1,
  },
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const MillesimesAccordion: React.FC<Props> = ({ html, className, height}) => {
  const styles = useStyles();
  const [open, set] = useState(false);
  const dictionary = useLanguageStrings();

  useEffect(() => { set(false) }, [html]);

  return (
    <div className={`${styles.accordion}${className ? ` ${className}` : ''}`}>
      <motion.div className={styles.accordionContent} animate={{ maxHeight: open ? 2000 : height }} initial={{ maxHeight: height }}>
        <Markup html={html} />
      </motion.div>
      <hr className={styles.line} />
      <AnimatePresence>
        {!open ? (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.showMoreContainer}
          >
            <IconButton className={styles.showMore} onClick={() => set(true)}>
              <span>{dictionary["show.more"]}</span>
              <BsChevronDown size={14} />
            </IconButton>
          </motion.div>
        ) : (
          <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.showMoreContainer}
          >
          <IconButton className={styles.showMore} onClick={() => set(false)}>
          <span>{dictionary["show.less"]}</span>
          <BsChevronUp size={14} />
          </IconButton>
          </motion.div>
          )}
      </AnimatePresence>
    </div>
  );
}

export default MillesimesAccordion;
