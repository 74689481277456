import React, { useState } from 'react';
import { PageGammeData } from "../router/pageData";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { useTheme } from "react-jss";
import { VignonTheme } from "../theme";
import PrimaryHover from "../components/PrimaryHover";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Markup } from "./Typography";
import Image from './Image';

type Props = {
  items: PageGammeData["data"]["field_gamme_item"]
}

const useStyles = createUseThemedStyles(theme => ({
  gamme: {
    background: theme.color.grayText,
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateAreas: `"A A"
                        "B B"
                        "C D"
                        "E E"
                        `,
    gap: 1,
    [theme.breakpoints.md]: {
      gridTemplateColumns: '50% 25% 25%',
      gridTemplateAreas: `"A B B"
                        "A C D"
                        "E E E"`,
    }
  },
  item: {
    position: 'relative',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.color.white,
    ...([
      { area: 'A', padding: '100%', paddingDesktop: 'calc(100% + 1.5px)', heading: 80, headingMobile: 60, text: 28, lineHeight: '28px'},
      { area: 'B', padding: '50%', heading: 60, headingMobile: 30,text: 28 , lineHeight: '28px'},
      { area: 'C', padding: '100%', heading: 30, headingMobile: 16, text: 18 },
      { area: 'D', padding: '100%', heading: 30, headingMobile: 16, text: 18 },
      { area: 'E', padding: '50%', paddingDesktop: '25%', heading: 60, headingMobile: 30, text: 28 , lineHeight: '28px'},
    ]).reduce((acc, { area, padding, heading, text, paddingDesktop, headingMobile, lineHeight}, index) => ({ ...acc,
      [`&:nth-child(${index + 1})`]: {
        gridArea: area,
        '& $img': { paddingBottom: padding, [theme.breakpoints.md]: { paddingBottom: paddingDesktop ?? padding } },
        '& $title': { fontSize: headingMobile, [theme.breakpoints.md]: { fontSize: heading, } },
        '& $hover .wysiwyg': { [theme.breakpoints.md]: { fontSize: text, lineHeight: lineHeight } },
      }
    }), {})
  },
  img: {
    position: 'relative',
    zIndex: 1,
    '& img': {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      zIndex: 1,
    }
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.medium,
    [theme.breakpoints.md]: {
      padding: theme.spacing.large,
    }
  },
  title: {
    position: 'relative',
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    fontWeight: 400,
    color: theme.color.white,
    zIndex: 2,
    fontSize: 30,
    textAlign: 'center',
    '&:before': {
      content: '""',
      height: 2,
      width: '1rem',
      background: theme.color.primary,
      position: 'absolute',
      top: '-30px !important',
      left: '50% !important',
      transform: undefined,
      [theme.breakpoints.md]: {
        top: '-30px !important',
        left: '50% !important',
        transform: 'translateY(-1px) !important',
      }
    },
  },
  icon: {
    transition: 'transform .3s ease',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
    }
  },
  hover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.medium,
    [theme.breakpoints.md]: {
      padding: theme.spacing.large,
    }
  },
  text: {
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: 16,
    marginBottom: theme.spacing.large,
    textAlign: 'center',
  }
}))

const GammeComponent: React.FC<Props> = ({
  items
}) => {
  const styles = useStyles();
  const theme = useTheme() as VignonTheme;
  const [hovered, setHovered] = useState<number | null>(null);
  return (
    <>
      <section className={styles.gamme}>
        {items?.map(([item], index) => (
          <Link
            key={index}
            to={item.field_link ?? '/'}
            className={styles.item}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
          >
            <Image className={styles.img} src={item.url} alt={item.alt}/>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.field_title}</h3>
            </div>
            <PrimaryHover hovered={index === hovered}>
              <div className={styles.hover}>
                <Markup html={item.field_text} className={styles.text} />
                <AiOutlinePlusCircle size={42} className={styles.icon} />
              </div>
            </PrimaryHover>
          </Link>
        ))}
      </section>
    </>
  )
}

export default GammeComponent;
