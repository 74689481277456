import React from 'react';
import ReactSelect from 'react-select'
import { useTheme } from "react-jss";
import { VignonTheme } from "../../theme";
import hexToRgba from "hex-to-rgba";
import { GroupBase } from "react-select/dist/declarations/src/types";

type SelectStyles = Parameters<typeof ReactSelect>[0]["styles"];
type SelectType = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(p: Parameters<typeof ReactSelect<Option, IsMulti, Group>>[0] | { styles: any }) => ReturnType<typeof ReactSelect<Option, IsMulti, Group>>

const createCustomStyles = (theme: VignonTheme, styles: any = {}): SelectStyles => ({
  control: (provided) => ({
    ...provided,
    display: 'inline-flex',
    minWidth: 140,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    ...styles.control,
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
    minWidth: 140,
    cursor: 'pointer',
    ...styles.menu,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 34,
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    color: state.isSelected ? theme.color.white : theme.color.primary,
    backgroundColor: state.isSelected ? theme.color.primary: (state.isFocused ? hexToRgba(theme.color.primary, 0.2) : theme.color.white),
    '&:active': {
      backgroundColor: state.isSelected ? hexToRgba(theme.color.primary, 0.8) : hexToRgba(theme.color.primary, 0.4),
    },
    ...styles.option,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 30,
    color: theme.color.primary,
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    ...styles.singleValue,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 34,
    color: theme.color.primary,
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    ...styles.input,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    border: 'none',
    ...styles.indicatorsContainer,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: `${theme.color.primary}!important`,
    display: "none",
    ...styles.dropdownIndicator,
  }),
  indicatorSeparator: () => ({}),
  valueContainer: provided => ({ ...provided, ...styles.valueContainer }),
  placeholder: provided => ({ ...provided, ...styles.placeholder }),
})

const SingleSelect: SelectType = ({ styles, ...props }) => {
  const theme = useTheme() as VignonTheme;
  const customStyles = createCustomStyles(theme, styles);
  return <ReactSelect {...props} styles={customStyles as any}  />
}

export default SingleSelect;
