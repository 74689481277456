import React from 'react';
import { ProductPreview as ProductPreviewType } from "../../shared/product";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { Heading, HeadingWithLine, Markup } from "../Typography";
import Image from '../Image';
import { Link } from 'react-router-dom';
import { BsPlusCircle } from 'react-icons/bs';
import { color, VignonTheme } from "../../theme";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import cn from 'classnames';
import Button from "../Button";
import {IoBagOutline} from "react-icons/io5";

type colorType = keyof typeof color;

type stylesProps = {
  size: PreviewSize;
  bgColor: colorType;
  theme: VignonTheme
}

export enum PreviewSize {
  small = 'small',
  big = 'big',
  rotated = 'rotated'
}

type Props = {
  product: ProductPreviewType;
  size?: PreviewSize;
  className?: string;
  bgColor?: colorType;
  rotated?: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
  product: {
    background: ({ bgColor }: stylesProps) => theme.color[bgColor],
    padding: 30,
    paddingTop: 40,
    display: 'flex',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    [theme.breakpoints.md]: {
      paddingTop: ({ size }: stylesProps) => size === PreviewSize.small ? 40 : 60,
      padding: ({ size }: stylesProps) => size === PreviewSize.small ? 50 : 60,
      '&$productRotated': {
        paddingRight: 200,
      },
    }
  },
  productRotated: {},
  title: {
    fontSize: ({ size }: stylesProps) => size === PreviewSize.small ? 13 : 25,
    lineHeight: 1.15,
    marginBottom: 5,
    margin: 0,
  },
  subtitle: {
    fontSize: ({ size }: stylesProps) => size === PreviewSize.small ? 12 : 20,
    color: theme.color.primary,
    margin: ({ size }: stylesProps) => size === PreviewSize.rotated ? 0 : `0 0 ${theme.spacing.medium}`,
    [theme.breakpoints.md]: {
      margin: [0, 0, theme.spacing.medium],
    }
  },
  year: {
    fontFamily: theme.fonts.heading,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ({ size }: stylesProps) => size === PreviewSize.small ? 24 : 34,
    color: theme.color.grayText,
    marginBottom: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.medium : theme.spacing.tiny
  },
  no_year: {
    fontFamily: theme.fonts.heading,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ({ size }: stylesProps) => size === PreviewSize.small ? 20 : 30,
    color: theme.color.grayText,
    marginBottom: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.medium : theme.spacing.tiny
  },
  terms: {
    color: theme.color.gray,
    fontWeight: 700,
    fontSize: 13,
    textTransform: 'capitalize',
    marginBottom: theme.spacing.medium,
    [theme.breakpoints.md]: {
      marginBottom: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.medium : theme.spacing.large,
    }
  },
  text: {
    fontSize: 12,
  },
  link: {
    all: 'unset',
    display: 'block',
    color: theme.color.grayText,
    marginTop: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.large : theme.spacing.medium,
    cursor: 'pointer',
    [theme.breakpoints.md]: {
      marginTop: theme.spacing.large,
      '&.not-rotated': {
        position: 'absolute',
        bottom: 0
      }
    },
    [theme.breakpoints.xs]: {
      float: 'left',
    }
  },
  img: {
    // GOD DAMN DO NO TRY THIS AT HOME
    width: ({ size }: stylesProps) => size === PreviewSize.small ? 'auto' : (size === PreviewSize.rotated ? 'calc(0.26 * (100vw - 100px))' : 90),
    flex: ({ size }: stylesProps) => size === PreviewSize.small ? '0 0 auto' : (size === PreviewSize.rotated ? null : '0 0 90px'),
    paddingTop: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.xlarge : 0,
    marginBottom: ({ size }: stylesProps) => size === PreviewSize.rotated && 24 as any,
    marginTop: ({ size }: stylesProps) => size === PreviewSize.rotated && 24 as any,
    transform: ({ size }: stylesProps) => size === PreviewSize.rotated ? 'rotate(90deg) translateY(calc(-0.74 * (100vw - 100px)))' : undefined,
    height: ({ size }: stylesProps) => size === PreviewSize.rotated && 'calc(0.26 * (100vw - 100px))' as any,
    '& picture': {
      height: ({ size }: stylesProps) => size === PreviewSize.rotated && 'calc(100vw - 100px)' as any,
    },
    '& img': {
      height: ({ size }: stylesProps) => size === PreviewSize.small ? '245px' : PreviewSize.rotated && '100%' as any,
      width: ({ size }: stylesProps) => size === PreviewSize.small ? 'auto' : undefined,
      objectFit: ({ size }: stylesProps) => size === PreviewSize.rotated && 'contain' as any,
    },
    [theme.breakpoints.md]: {
      paddingTop: ({ size }: stylesProps) => size === PreviewSize.rotated ? 0 : theme.spacing.xlarge,
      width: ({ size }: stylesProps) => size === PreviewSize.small ? 'auto' : 103,
      flex: ({ size }: stylesProps) => size === PreviewSize.small ? '0 0 auto' : '0 0 103px',
      transform: ({ size }: stylesProps) => size === PreviewSize.rotated && `rotate(90deg) translateY(-${390 - 103}px)` as any,
      height: ({ size }: stylesProps) => size === PreviewSize.rotated && 103 as any,
      marginBottom: ({ size }: stylesProps) => size === PreviewSize.rotated && theme.spacing.large as any,
      marginTop: ({ size }: stylesProps) => size === PreviewSize.rotated && theme.spacing.large as any,
      '& picture': {
        height: ({ size }: stylesProps) => size === PreviewSize.rotated && 390 as any,
      },
      '& img': {
        height: ({ size }: stylesProps) => size === PreviewSize.rotated && '100%' as any,
        objectFit: ({ size }: stylesProps) => size === PreviewSize.rotated && 'contain' as any,
      },
    }
  },
  content: {
    paddingRight: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.large : theme.spacing.medium,
    flexGrow: 1,
    [theme.breakpoints.md]: {
      position: 'relative',
      paddingRight: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.large : theme.spacing.xlarge,
    }
  },
  contentRotatedHeading: {
    [theme.breakpoints.md]: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing.medium,
    }
  },
  contentRotatedYears: {
    [theme.breakpoints.md]: {
      marginTop: theme.spacing.large,
      // strange fix for font numbers
      transform: 'translateY(-10px)',
    }
  },
  contentRotatedMain: {
    [theme.breakpoints.md]: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing.medium,
    }
  },
  linkBag: {
    backgroundColor: 'rgb(232, 75, 49)',
    color: 'white',
    minWidth: 'auto',
    [theme.breakpoints.md]: {
      position: 'absolute',
      right: ({ size }: stylesProps) => size === PreviewSize.small ? -64 : 20,
      bottom: 6,
    },
    [theme.breakpoints.xs]: {
      float: 'right',
      marginTop: ({ size }: stylesProps) => size === PreviewSize.small ? theme.spacing.large : theme.spacing.medium,
    }
  },
  contentRotatedLink: {
    [theme.breakpoints.md]: {
      display: 'flex',
    }
  },
  linkBagRotated: {
    backgroundColor: 'rgb(232, 75, 49)',
    color: 'white',
    minWidth: 'auto',
    maxHeight: 42,
    marginTop: theme.spacing.medium,
    marginLeft: 15,
    [theme.breakpoints.xs]: {
      float: 'right',
    }
  }
}));

const renderProductRotated = (
  product: ProductPreviewType,
  styles: ReturnType<typeof useStyles>,
  isAssemblage: boolean,
  dictionary: ReturnType<typeof useLanguageStrings>
) => (
  <div className={styles.contentRotated}>
    <div className={styles.contentRotatedHeading}>
      <div className={styles.contentRotatedTitle}>
        <HeadingWithLine className={styles.title}>
          {product.title}
        </HeadingWithLine>
        <Heading tag="p" className={styles.subtitle}>{product.field_sub_title_product}</Heading>
      </div>
      {product.field_millesimes_year ?
        <div className={styles.contentRotatedYears}>
          <div className={(!isNaN(+product.field_millesimes_year)) ? styles.year : styles.no_year}>{product.field_millesimes_year}</div>
        </div> : null}
    </div>
    <div className={styles.img}>
      <Image src={product.field_millesimes_image} alt={product.field_millesimes_image_alt}/>
    </div>
    <div className={styles.contentRotatedMain}>
      <div className={styles.contentRotatedText}>
        <div className={styles.terms}>{isAssemblage && dictionary['assemblage']}</div>
        {product.field_content ?
          <Markup className={styles.text} html={product.field_content.slice(0, 150) + '...'}/> : null}
      </div>
      <div className={styles.contentRotatedLink}>
        <Link className={styles.link} to={product.url ?? '/'}>
          <BsPlusCircle size={42}/>
        </Link>

        {product.field_lien_pour_achat?.length ?
          <Button className={styles.linkBagRotated} icon={<IoBagOutline/>} as="a" target='_blank'
                  href={product.field_lien_pour_achat}>{dictionary["lien.achat"]}</Button>: <></>
        }
      </div>
    </div>
  </div>
)

const ProductPreview: React.FC<Props> = ({ className, product, size = PreviewSize.small, bgColor = 'grayBg' }) => {
  const styles = useStyles({ size, bgColor });
  const dictionary = useLanguageStrings();
  const isAssemblage = !!product?.field_cepages?.length;
  const isRotated = size === PreviewSize.rotated;
  return (
    <article className={cn(styles.product, className, isRotated && styles.productRotated)}>
      {isRotated ? renderProductRotated(product, styles, isAssemblage, dictionary) : (
        <>
          <div className={styles.content}>
            <HeadingWithLine className={styles.title}>
              {product.title}
            </HeadingWithLine>
            <Heading tag="p" className={styles.subtitle}>{product.field_sub_title_product}</Heading>
            {product.field_millesimes_year ?
              <div className={(!isNaN(+product.field_millesimes_year)) ? styles.year : styles.no_year}>{product.field_millesimes_year}</div>: null}
            <div className={styles.terms}>{isAssemblage && dictionary['assemblage']}</div>
            {product.field_content ?
              <Markup className={styles.text} html={product.field_content.split('<p>&nbsp;</p>').join('').slice(0, 90) + '...'}/> : null}
            <Link className={styles.link + ' not-rotated'} to={product.url ?? '/'}>
              <BsPlusCircle size={42}/>
            </Link>

            {product.field_lien_pour_achat?.length ?
              <Button className={styles.linkBag} icon={<IoBagOutline/>} as="a" target='_blank'
                      href={product.field_lien_pour_achat}>{dictionary["lien.achat"]}</Button>: <></>
            }
          </div>
          <div className={styles.img}>
            <Image
              src={product.field_millesimes_image}
              alt={product.field_millesimes_image_alt}
              height={size === PreviewSize.small ? 245 : undefined}
            />
          </div>
        </>
      )}
    </article>
  )
}

export default ProductPreview;
