import React, { useEffect, useMemo, useState } from 'react';
import { ArticleCategory, ArticlePreviewResponse } from "../../shared/article";
import ArticlePreview from "./ArticlePreview";
import ArticleCategroies from "./ArticleCategroies";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import Button from "../Button";
import { AiOutlinePlus } from 'react-icons/ai';
import { useGroupedArticles } from "../../hooks/useGroupedArticles";
import ArticleMonthPreview from "./ArticleMonthPreview";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import { useLocation } from "react-router-dom";

type Props = {
  articles?: ArticlePreviewResponse[]
}

const defaultMaxGroupItems = 12;

const useStyles = createUseThemedStyles((theme) => ({
  content: {
    paddingTop: theme.spacing.xlarge,
    paddingBottom: theme.spacing.xlarge,
    background: theme.color.white,
  },
  container: {
    ...theme.container.base,
  },
  articles: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    gap: 40,
    margin: [theme.spacing.xlarge, 'auto', theme.spacing.xlarge],
    padding: 0,
    listStyle: 'none',
    maxWidth: 600,
    [theme.breakpoints.md]: {
      maxWidth: 'none',
    }
  },
  loadMore: {
    margin: [theme.spacing.xlarge, 'auto', 0],
  }
}));

const ArticlesPreview: React.FC<Props> = ({ articles = []}) => {
  const styles = useStyles();
  const dictionary = useLanguageStrings();
  const [maxGroupItems, setMaxGroupItems] = useState<number>(defaultMaxGroupItems);
  const location = useLocation();
  const { search } = location;

  const categories = useMemo<ArticleCategory[]>(
    () => articles
      .reduce(
        (acc, article) => acc.find(category => category.field_category_id === article.field_category_id) ?
          acc :
          [...acc, { field_category: article.field_category, field_category_id: article.field_category_id }],
        [] as ArticleCategory[]),
    [articles]
  );

  const [activeCategoriesId, setActiveCategoriesId] = useState<string[]>([].filter(Boolean) as string[]);

  useEffect(() => {
    const categoryName = new URLSearchParams(search).get('category');
    const activeCategory = categories.find(category => category.field_category === categoryName);
    if (activeCategory?.field_category_id) {
      setActiveCategoriesId(
        ids => [...ids, activeCategory.field_category_id]
          // only unique
          .filter((value, index, self) => self.indexOf(value) === index)
      );
    }
  }, [search, categories])

  const filteredArticles = useMemo<ArticlePreviewResponse[]>(
    () => (activeCategoriesId.length ?
      articles.filter(article => activeCategoriesId.includes(article.field_category_id)) :
      articles).sort((b, a) => Number(a.created) < Number(b.created) ? -1 : 1),
    [activeCategoriesId, articles]);

  const visibleArticles = useMemo(
    () => filteredArticles.filter((a, index) => index < maxGroupItems),
    [filteredArticles, maxGroupItems]
  );

  const groupedArticles = useGroupedArticles(visibleArticles);

  return (
    <section className={styles.content}>
      <ArticleCategroies
        categories={categories}
        setActiveCategoriesId={setActiveCategoriesId}
        activeCategoriesId={activeCategoriesId}
      />
      <div className={styles.container}>
        <ul className={styles.articles}>{
          groupedArticles.map(([month, articles], index) => [
            <ArticleMonthPreview month={month} articlesQuantity={articles.length} key={`month-${month}`} articles={articles}/>,
            ...articles.map((article) => <ArticlePreview article={article} key={`${index}-${article.nid}`} />)
          ]).flat()
        }
        </ul>
        {filteredArticles.length > maxGroupItems ?
          <Button
            className={styles.loadMore}
            color="primary"
            onClick={() => setMaxGroupItems(maxGroupItems => maxGroupItems + defaultMaxGroupItems)}
            icon={<AiOutlinePlus size={11} />}
          >
            {dictionary["load.more"]}
          </Button> :
          null
        }
      </div>
    </section>
  )
}

export default ArticlesPreview;
