import React, { useEffect, useState } from 'react';
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import 'leaflet/dist/leaflet.css';
import barrelsSmall from '../../assets/barrels-3.png';
import { defaultLocation } from '../../shared/location';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import ReservationForm from './ReservationForm';
import { SubmitFormSuccessResponse, WebForm } from '../../shared/form';
import Image from '../Image';
import { MapDotIcon } from '../icons';
import { AiFillPhone } from 'react-icons/ai';
import { Markup } from '../Typography';
import type { Map, Marker as MarkerType } from 'leaflet';
import useLanguageStrings from '../../hooks/useLanguageStrings';
import { buildFullAssetUrl } from '../../utils';
import popupBanner from '../../assets/popup-banner.png';

type Props = {
  form: WebForm | null,
  onSubmitSuccess: (response: SubmitFormSuccessResponse) => void;
};

const useStyles = createUseThemedStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.md]: {
      display: 'flex',
      alignItems: 'stretch',
    },
  },
  formImage: {
    display: 'none',
    [theme.breakpoints.md]: {
      display: 'block',
      flexShrink: 0,
      width: 300,
      '& img': {
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
  map: {
    width: '100%',
    flexGrow: 1,
    minHeight: 500,
    [theme.breakpoints.md]: {
      height: 330,
    },
    '& .leaflet-popup-content': {
      margin: '10px 18px 7px 13px',
    },
    '& .leaflet-tile-pane, & .leaflet-marker-pane': {
      filter: 'grayscale(1)',
    },
  },
  popupWrapper: {
    display: 'flex',
    gap: 16,
    filter: 'grayscale(0)',
  },
  popupContent: {
    width: '100%',
  },
  popupImage: {
    width: 180,
  },
  popupTitle: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontStyle: 'italic',
    fontSize: 24,
    margin: 0,
    fontWeight: 400,
  },
  popupCategory: {
    color: theme.color.primary,
    fontSize: 9,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: [0, 0, 12],
  },
  popupLine: {
    color: theme.color.grayText,
    fontSize: 12,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:first-child': {
      color: theme.color.grayText,
      textDecoration: 'none',
      width: '70%',
    },
    '& svg': {
      marginLeft: theme.spacing.medium,
      width: 12,
      minWidth: 12,
      '& path': {
        fill: theme.color.grayText,
      }
    }
  },
}));

const ReservationFormPage: React.FC<Props> = ({ form, onSubmitSuccess }) => {
  const dictionary = useLanguageStrings();
  const styles = useStyles();
  const [map, setMap] = useState<Map | null>();
  const [marker, setMarker] = useState<MarkerType | null>();

  useEffect(() => {
    if (map && marker) {
      marker.openPopup();
    }
  }, [map, marker])

  return (
    <>
      <div className={styles.formContainer}>
        <Image
          className={styles.formImage}
          src={barrelsSmall}
          alt='background'
        />
        <ReservationForm webForm={form} onSubmitSuccess={onSubmitSuccess} />
      </div>
      <MapContainer ref={(el) => setMap(el)} className={styles.map} center={defaultLocation} zoom={9}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker ref={(el) => setMarker(el)} position={defaultLocation}>
          <Popup minWidth={400} maxWidth={410}>
            <div className={styles.popupWrapper}>
              <div className={styles.popupContent}>
                <h3 className={styles.popupTitle}>La boutique Xavier Vignon</h3>
                <Markup className={styles.popupCategory} html={dictionary.popup_category} />
                <div className={styles.popupLine}>
                  <span>Place du 8 mai 1945<br/> 84190 Beaumes de Venise</span>
                  <MapDotIcon className={styles.addressIcon} />
                </div>
                <div className={styles.popupLine}>
                  <a href={`tel:+33(0)4 90 37 19 79`} className={styles.phoneLink}>+33(0)4 90 37 19 79</a>
                  <AiFillPhone size={26} />
                </div>
              </div>
              <div className={styles.popupImage}>
                <Image src={popupBanner} alt={'alt'} aspectRatio={1}/>
              </div>
            </div>
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
};

export default ReservationFormPage;
