import React from 'react'
import { useWebformElement } from '../utils'

import WebformElementWrapper from './WebformElementWrapper'
import { FormComponentType } from "../types";
import { Markup } from "../../../Typography";

export const WebformText: FormComponentType = ({ element, error, color, columns }) => {
	const [, settings] = useWebformElement(element, {});

	return (
		<WebformElementWrapper settings={settings} error={error} color={color} columns={columns}>
			<Markup html={settings.attributes.text || ''} />
		</WebformElementWrapper>
	)
};

export default WebformText
