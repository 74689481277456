import React, { HTMLAttributes } from 'react';
import { createUseStyles } from "react-jss";
import { buildFullAssetUrl } from "../utils";
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

type Props = {
  alt?: string;
  src?: string;
  aspectRatio?: number,
  height?: number,
} &  HTMLAttributes<HTMLPictureElement>;

const useStyles = createUseStyles({
  picture: {
    display: 'block',
    position: 'relative',
    width: '100%',
    paddingTop: ({ aspectRatio }: { aspectRatio?: number }) =>  aspectRatio ? `${(aspectRatio * 100).toFixed(0)}%` : 0,
    '& span': {
      display: 'block !important',
      height: '100%',
      width: '100%',
    },
  },
  img: ({ aspectRatio }: { aspectRatio?: number }) => ({
    display: 'block',
    width: '100%',
    ...(aspectRatio ? {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      objectFit: 'cover',
    } : {})
  })
})

const Image: React.FC<Props> = ({  aspectRatio, src, alt, className, height, ...props }) => {
  const styles = useStyles({ aspectRatio });
  return (
    <picture className={cn(styles.picture, className)} {...props}>
      <LazyLoadImage
        effect="opacity"
        height={height}
        className={styles.img}
        src={src && src.startsWith && (src.startsWith('/static/media') || /^https?:\/\//.test(src)) ? src : buildFullAssetUrl(src)}
        alt={alt}
      />
    </picture>
  );
};

export default Image;
