import { spacing } from "./spacing";
import { breakpoints } from "./breakpoints";

const defaultContainer = {
  width: '100%',
  maxWidth: 1384,
  margin: 'auto',
  padding: [0, 20],
  [breakpoints.md]: {
    padding: [0, spacing.large],
  }
}

export const container = {
  base: {
    ...defaultContainer
  },
  small: {
    ...defaultContainer,
    padding: [0, spacing.xlarge],
    maxWidth: 1100,
  }
}

