import React, { createContext, useContext, useEffect, useState } from 'react';
import {api, getMenu, getSiteSettings, jsonApi} from "../api";
import { MenuResponse, SocialLink, SocialLinkType } from "../shared/settings";
import { getFile } from "../api/file";
import { DrupalFile } from "../shared/file";
import { useLanguageContext } from "../i18n/components/LanguageProvider";
import useLanguageStrings from "../hooks/useLanguageStrings";

type SiteSettings = {
  burgerMenu: MenuResponse["data"];
  homeLink: string;
  footer: {
    text?: string;
    copyright?: string;
    menu: MenuResponse["data"];
    mentionLegales?: {
      uri?: string;
      full_url?: string;
      title?: string;
      options?: any[];
    };
    subscribeForm?: {
      button?: string;
      description?: string;
      placeholder?: string;
    }
  }
  logo: {
    file?: DrupalFile,
    alt?: string;
  },
  loading: boolean;
  socials: SocialLink[],
  natureReservation?: [{
    field_text?: string,
    field_title?: string,
    field_image?: DrupalFile,
  }]
};

const defaultSiteSettings = {
  burgerMenu: [],
  homeLink: '',
  footer: {
    menu: [],
  },
  logo: {},
  loading: true,
  socials: [],
};

const SiteSettingsContext = createContext<SiteSettings>(defaultSiteSettings);

const { Provider } = SiteSettingsContext;

type Props = {
  children: React.ReactNode;
}

const SiteSettingsProvider: React.FC<Props> = ({ children }) => {
  const [settings, setSettings] = useState<SiteSettings>(defaultSiteSettings);
  const dictionary = useLanguageStrings();
  const lang = useLanguageContext();

  useEffect(() => {
    let aborted = false;
    async function fetchSiteSettings() {
      let id = 'd9c5d3da-a376-4c26-b59a-c38906a21cbb';
      if (lang === 'fr') {
        id = '083e3c6b-81f6-49d7-a292-3fa42a4352fa';
      }

      const baseSettings = await getSiteSettings(id);
      const {
        field_burger_menu,
        field_footer_menu,
        field_logo,
        field_contact_form,
        field_reservation_webform_settin,
      } = baseSettings.data.relationships ?? {};
      const {
        field_footer_text,
        field_burger_menu_link_text,
        field_mention_legales,
        field_subscribe_form_button,
        field_subscribe_form_description,
        field_subscribe_form_placeholder,
        field_copyright,
        field_facebook_link,
        field_linkedin_link,
        field_instagram_link,
        field_youtube_link,
      } = baseSettings.data.attributes ?? {};

      if (aborted) return;

      const menus = await Promise.all([field_burger_menu, field_footer_menu]
        .map(menu => menu?.data?.meta?.drupal_internal__target_id)
        .filter(Boolean)
        .map(menuName => getMenu(menuName))
      );

      if (aborted) return;

      const logo = await getFile(field_logo.data.id);
      const socials = [{
        type: SocialLinkType.FACEBOOK,
        ...field_facebook_link
      }, {
        type: SocialLinkType.LINKEDIN,
        ...field_linkedin_link
      }, {
        type: SocialLinkType.INSTAGRAM,
        ...field_instagram_link
      }, {
        type: SocialLinkType.YOUTUBE,
        ...field_youtube_link
      }].filter(item => item.uri) as SocialLink[];

      const reservationWebformSettings: Array<any> = await Promise.all([field_reservation_webform_settin]
        .map(settings => settings?.links?.related?.href)
        .filter(Boolean)
        .map(relationship => api(relationship))
      ) ?? {};

      const reservationWebformSettingsData = reservationWebformSettings.shift()?.data;

      const natureReservation: any = [{}];
      for (const naturReservationIndex in reservationWebformSettingsData) {
        const refImage: Array<any> = await Promise.all([reservationWebformSettingsData[naturReservationIndex]?.relationships?.field_image]
          .map(link => link?.links?.related?.href)
          .filter(Boolean)
          .map(relationship => api(relationship))
        );

        natureReservation[Number(naturReservationIndex)] = {
          field_text: reservationWebformSettingsData[naturReservationIndex].attributes.field_text,
          field_title: reservationWebformSettingsData[naturReservationIndex].attributes.field_title,
          field_image: await getFile(refImage?.shift()?.data?.relationships?.field_media_image?.data?.id),
        };
      }

      if (aborted) return;

      setSettings({
        homeLink: field_burger_menu_link_text ?? dictionary['homepage'],
        burgerMenu: menus[0] ? menus[0].data ?? [] : [],
        footer: {
          menu: menus[1] ? menus[1].data ?? [] : [],
          text: field_footer_text?.processed,
          copyright: field_copyright,
          mentionLegales: field_mention_legales,
          subscribeForm: {
            button: field_subscribe_form_button,
            description: field_subscribe_form_description,
            placeholder: field_subscribe_form_placeholder
          }
        },
        loading: false,
        logo: {file: logo, alt: field_logo.data?.meta?.alt},
        socials,
        natureReservation,
      })
    }

    fetchSiteSettings().catch(() => {
      setSettings(settings => ({...settings, loading: false}))
    });

    return () => { aborted = true; }
  }, [lang])
  return (
    <Provider value={settings}>{children}</Provider>
  )
}

export const useSiteSettingsContext = () => {
  return useContext(SiteSettingsContext);
}

export default SiteSettingsProvider;
