import React, { useState } from 'react';
import WebformSelect from './WebformSelect';
import WebformInput from "./WebformInput";
import WebformElementWrapper from "./WebformElementWrapper";
import { MdClose } from 'react-icons/md';
import { getElementId, useWebformElement } from "../utils";
import { FormComponentType } from "../types";

export const WebformSelectOther: FormComponentType = ({ element, error, color, columns }) => {
	const [isInput, setIsInput] = useState(false);

	const id = getElementId(element.name);
	// eslint-disable-next-line
	const [_, settings] = useWebformElement(element, {
		className: 'form-control',
		name: element.name,
		type: 'text',
		id
	});

	return (
		<WebformElementWrapper settings={settings} error={error} id={id} color={color} columns={columns}>
			{!isInput ? <WebformSelect
				element={{
					...element,
					pure: true,
					options: [...(element.options ?? []), { label: 'Other...', value: '___other___' }]
				}}
				error={error}
				onChange={({ value }) => { if (value === '___other___') setIsInput(true); }}
			/> : (
				<div>
					<WebformInput element={{ ...element, pure: true, }} error={error} />
					<div
						// sx={{
						// 	position: 'absolute', right: 1, top: '50%', width: 12, height: 12, transform: 'translateY(-50%)',
						// 	svg: { width: '100%', height: '100%' },
						// 	zIndex: 1,
						// }}
						onClick={() => setIsInput(false)}
					>
						<MdClose />
					</div>
				</div>
			)}
		</WebformElementWrapper>
	)
};

export default WebformSelectOther;
