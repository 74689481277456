import React, { useState } from 'react';
import { ArticlePreviewResponse } from "../../shared/article";
import { Heading } from "../Typography";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import PrimaryHover from "../PrimaryHover";
import Image from "../Image";
import {buildFullAssetUrl} from "../../utils";

type Props = {
  article?: ArticlePreviewResponse
}

const useStyles = createUseThemedStyles(theme => ({
  article: {
    textAlign: 'center',
    display: 'flex',
    alignItems: "stretch",
    justifyContent: "stretch",
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1
  },
  category: {
    color: theme.color.primary,
    fontSize: 17,
    lineHeight: '20px',
  },
  title: {
    fontFamily: theme.fonts.base,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    padding: 0,
    flexGrow: 1,
    color: theme.color.grayMask,
    textTransform: 'uppercase',
    margin: [theme.spacing.small, 0, 20],
  },
  img: {
    position: 'relative',
    paddingTop: '80%',
    width: '100%',
    marginBottom: theme.spacing.medium,
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      objectFit: 'cover',
    },
    '& picture': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      objectFit: 'cover',
    }
  },
  container: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.md]: {
      width: `calc((100% - ${40 * 2}px) / 3)`,
      flex: `0 0 calc((100% - ${40 * 2}px) / 3)`,
    }
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'block',
    position: 'relative',
  },
  icon: {
    transition: 'transform .3s ease',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
    }
  },
}))

const ArticlePreview: React.FC<Props> = ({ article = {}}) => {
  const styles = useStyles();
  const [hovered, setHovered] = useState(false);
  return (
    <li
      className={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link to={article.view_node as any} className={styles.link}>
        <article className={styles.article}>
          <div className={styles.img}>
            <Image src={buildFullAssetUrl(article?.field_image)} alt={article?.title} />
          </div>
          <Heading className={styles.category} tag="div">{article?.field_category}</Heading>
          <h3 className={styles.title}>{article?.title}</h3>
        </article>
        <PrimaryHover hovered={hovered}>
          <AiOutlinePlusCircle size={42} className={styles.icon} />
        </PrimaryHover>
      </Link>
    </li>
  )
}

export default ArticlePreview;
