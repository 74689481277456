import React from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import hexToRgba from "hex-to-rgba";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
	isOpen: {
		opacity: 1,
	},
	isHidden: {
		opacity: 0,
	}
};

const useStyles = createUseThemedStyles(theme => ({
  overlay: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 24,
    backgroundColor: hexToRgba(theme.color.gray, 0.8),
  }
}))

type Props = {
  children: React.ReactNode;
  active: boolean;
  onClick: (e: React.SyntheticEvent) => void;
}

const Overlay: React.FC<Props> = ({ children, active, onClick }) => {
  const styles = useStyles();
	return (
		<AnimatePresence>
      {active && (
        <motion.div
          className={styles.overlay}
          variants={variants}
          animate={active ? 'isOpen' : 'isHidden'}
          exit={'isHidden'}
          initial={'isHidden'}
          onClick={onClick}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
	);
};

export default Overlay
