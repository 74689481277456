import React, {useCallback, useState} from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { Heading, HeadingWithLine, Markup, TitleWithLine } from "../components/Typography";
import useLanguageStrings from "../hooks/useLanguageStrings";
import { DefaultViewProps } from "../shared/views";
import { PageProductData } from "../router/pageData";
import Meta from "../components/Meta";
import Image from "../components/Image";
import ImageNoLazy from "../components/ImageNoLazy";
import MillesimesSelect from "../components/Millesimes/MillesimesSelect";
import { headerHeightDesktop, headerHeightMobile } from "../shared/styles";
import hexToRgba from "hex-to-rgba";
import MillesimesAccordion from "../components/Millesimes/MillesimesAccordion";
import * as icons from '../components/icons';
import bottleBg from '../assets/bottles.png';
import SliderGallery from "../components/SliderGallery";
import ProductPreview from "../components/Product/ProductPreview";
import { useIsDesktop } from "../context/ScreenSize";
import useAnchorLink from "../hooks/useAnchorLink";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper';
import {UpArrow} from "../components/icons";
import {PaginationOptions, Swiper as SwiperClass} from "swiper/types";
import Button from "../components/Button";
import {
  IoBagOutline,
  IoCloudDownloadOutline,
} from "react-icons/io5";

const useStyles = createUseThemedStyles(theme => ({
  product: {
    minHeight: '100vh',
    width: '100%',
    background: theme.color.white,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '30px',
    [theme.breakpoints.md]: {
      paddingTop: '0px',
    }
  },
  productContainer: {
    ...theme.container.base,
    [theme.breakpoints.md]: {
      ...theme.container.small,
      ...(theme.container.small as any)[theme.breakpoints.md],
      borderRight: `1px solid ${theme.color.grayLine}`,
      borderLeft: `1px solid ${theme.color.grayLine}`,
    }
  },
  millesimes: {
    background: theme.color.grayBg,
    width: '100%',
    paddingTop: headerHeightMobile + 80,
    position: 'relative',
    backgroundImage: `url("${bottleBg}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.md]: {
      backgroundSize: 'cover',
      paddingTop: headerHeightDesktop + 80,
    }
  },
  millesimesContainer: {
    padding: [0, 35],
    position: 'relative',
    zIndex: 5,
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateAreas: `"A A"
                        "B B"
                        "C D"`,
    alignItems: 'center',
    [theme.breakpoints.md]: {
      ...theme.container.small,
      ...(theme.container.small as any)[theme.breakpoints.md],
      gridTemplateColumns: '25% 25% 50%',
      gridTemplateRows: '200px auto auto',
      alignItems: 'start',
      gridTemplateAreas: `"A B C"
                        "A D E"
                        "A F E"`,
    }
  },
  millesimesBlockTitle: {
    gridArea: 'A',
    margin: '0 auto',
    maxWidth: 250,
    [theme.breakpoints.md]: {
      maxWidth: 'none',
      width: '100%',
      gridArea: 'C',
      paddingLeft: '4rem',
      marginTop: '5.5em'
    }
  },
  millesimesImage: {
    gridArea: 'C',
    '& img': {
      filter: `drop-shadow(0px 20px 15px ${hexToRgba(theme.color.gray, 0.4)})`,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '& > picture': {
      height: '100%',
    },
    width: 118,
    height: 451,
    margin: '0 0 -80px',
    [theme.breakpoints.md]: {
      padding: [0, 0, 0, 80],
      margin: [0, 0, -120],
      height: 670,
      width: 255,
      gridArea: 'A',
    }
  },
  millesimesCepages: {
    gridArea: 'D',
    [theme.breakpoints.md]: {
      paddingLeft: '5rem',
      maxWidth: 'none',
    }
  },
  millesimesContent: {
    gridArea: 'B',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.md]: {
      textAlign: 'left',
      display: 'block',
      gridArea: 'E',
      paddingLeft: theme.spacing.xlarge,
      paddingBottom: theme.spacing.xlarge,
      width: '100%'
    }
  },
  millesimesTerm: {
    fontSize: 14,
    color: theme.color.gray,
    margin: [0, 0, theme.spacing.small],
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.md]: {
      fontSize: 24,
      textAlign: 'left',
      margin: [0, 0, 4],
    }
  },
  millesimesTitle: {
    color: theme.color.primary,
    fontSize: 23,
    lineHeight: 1,
    textAlign: 'center',
    margin: [0, 0, theme.spacing.medium],
    [theme.breakpoints.md]: {
      margin: [0, 0, theme.spacing.large],
      fontSize: 23,
      lineHeight: 1.1,
      textAlign: 'left',
    }
  },
  millesimesSmallTitle: {
    fontSize: 16,
    color: theme.color.gray,
    margin: 0,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  millesimesCepagesList: {
    padding: 0,
    margin: [theme.spacing.large, 0, 0],
    listStyle: 'none',
    '& .swiper-wrapper': {
      height: 155,
    }
  },
  millesimesUpArrow: {
    width: 20,
    '&.disable-arrow': {
      opacity: 0.3
    }
  },
  millesimesDownArrow: {
    width: 20,
    transform: 'rotate(180deg)',
    '&.disable-arrow': {
      opacity: 0.3
    }
  },
  millesimesCepagesPercentage: {
    color: theme.color.primary,
    fontSize: 28,
    marginRight: '11px',
    display: 'inline-block',
    transform: 'translateY(-0.2em)',
  },
  millesimesCepagesVariety: {
    fontSize: 14,
  },
  millesimesCepagesItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    borderBottom: `0.5px solid ${theme.color.grayLine}`,
  },
  millesimesYear: {
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.medium,
    '& .css-cb1mhh-singleValue': {
      fontSize: 30,
    },
    [theme.breakpoints.md]: {
      marginTop: 14,
      marginBottom: theme.spacing.medium,
    }
  },
  millesimesYearNumeric: {
    '& .css-cb1mhh-singleValue': {
      fontSize: '34px !important',
    },
  },
  millesimesBody: {
    marginBottom: theme.spacing.medium,
    '& .wysiwyg': {
      fontSize: 12,
    },
    [theme.breakpoints.md]: {
      marginBottom: theme.spacing.xlarge,
    }
  },
  note: {
    width: '100%',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      alignItems: 'center',
    }
  },
  noteContent: {
    paddingTop: 100,
    paddingBottom: theme.spacing.xlarge,
    [theme.breakpoints.md]: {
      width: '50%',
      flex: '0 0 50%',
      padding: [200, 80, 150],
    }
  },
  noteImage: {
    marginBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      marginBottom: 0,
      '& img': {
        marginTop: '-4rem',
        zIndex: '4',
      },
      width: '50%',
      flex: '0 0 50%',
      borderLeft: `1px solid ${theme.color.grayLine}`,
      alignSelf: 'stretch',
      padding: [0, 100, 80, 80],
    },
    zIndex: 4,
    '& img': {
      marginTop: '-4em'
    }
  },
  notePicture: {
    [theme.breakpoints.md]: {
      margin: [`-${theme.spacing.xlarge}`, 0, 0],
    }
  },
  noteBlockTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      marginBottom: 23,
    }
  },
  noteTitle: {
    color: theme.color.gray,
  },
  noteSubTitle: {
    fontSize: 16,
    color: theme.color.primary,
    lineHeight: '29px',
  },
  noteWysiwyg: {
    fontSize: 12,
  },
  terroir: {
    backgroundSize: 'cover',
    width: '100%',
  },
  terroirContainer: {
    padding: [300, 20, 20],
    borderLeft: 'none',
    borderRight: 'none',
    [theme.breakpoints.md]: {
      padding: [340, 60, 110],
    }
  },
  terroirContent: {
    background: hexToRgba(theme.color.white, 0.8),
    padding: [40, 20],
    [theme.breakpoints.md]: {
      width: 'calc(50% - 60px)',
    }
  },
  terroirTitle: {
    color: theme.color.gray,
    margin: [0, 0, 20],
    '&:before': {
      background: theme.color.primary,
    }
  },
  terroirText: {
    fontSize: 12,
  },
  vinification: {
    width: '100%',
    [theme.breakpoints.md]: {
      borderBottom: `0.5px solid ${theme.color.grayLine}`,
    }
  },
  vinificationTitle: {
    color: theme.color.gray,
    '&:before': {
      backgroundColor: theme.color.grayMask,
    }
  },
  vinificationAccordsTitle: {
    color: theme.color.gray,
    margin: [0, 0, theme.spacing.large],
    '&:before': {
      backgroundColor: theme.color.grayMask,
    }
  },
  vinificationTitleContainer: {
    padding: [80, 80, theme.spacing.large],
  },
  vinificationContent: {
    [theme.breakpoints.md]: {
      backgroundImage: `linear-gradient(90deg, ${theme.color.white} 0%, ${theme.color.white} 50%, ${theme.color.grayBg} 50%, ${theme.color.grayBg} 100%)`
    }
  },
  vinificationContentContainer: {
    padding: 0,
    [theme.breakpoints.md]: {
      display: 'flex',
      alignItems: 'stretch',
      padding: [0, 80]
    }
  },
  vinificationContentLeft: {
    ...theme.container.base,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column-reverse',
    paddingBottom: theme.spacing.xlarge,
    [theme.breakpoints.md]: {
      flexDirection: 'column',
      width: '50%',
      flex: '0 0 50%',
      padding: [0, 80, 0, 0],
    }
  },
  vinificationContentRight: {
    ...theme.container.base,
    paddingTop: 45,
    paddingBottom: 45,
    background: theme.color.grayBg,
    [theme.breakpoints.md]: {
      background: 'none',
      width: '50%',
      flex: '0 0 50%',
      padding: [80, 100, 80, 80],
    }
  },
  vinificationText: {
    marginTop: theme.spacing.xlarge,
    fontSize: 12,
    [theme.breakpoints.md]: {
      margin: 0,
    }
  },
  vinificationAccordsText: {
    fontSize: 12,
    paddingBottom: 48,
    borderBottom: `0.5px solid ${theme.color.grayLine}`,
  },
  vinificationImage1: {
    margin: ['50%', 0, 0],
    width: '80%',
    alignSelf: 'flex-end',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.md]: {
      margin: ['65%', 0, 0],
    }
  },
  vinificationImage2: {
    position: 'absolute',
    bottom: theme.spacing.xlarge,
    right: '40%',
    width: '150%',
  },
  vinificationService: {
    padding: [48, 0, 0],
    margin: 0,
    listStyle: 'none',
  },
  vinificationServiceItem: {
    display: 'flex',
    alignItems: 'stretch',
    '&:not(:last-child)': {
      '& $vinificationServiceDescription': {
        borderBottom: `0.5px solid ${theme.color.grayLine}`,
      }
    }
  },
  vinificationServiceIcon: {
    width: 90,
    height: 90,
    flex: '0 0 90px',
    display: 'flex',
    alignItems: 'center',
    '& svg path': {
      fill: theme.color.grayMask,
    }
  },
  vinificationServiceDescription: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.color.gray,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: [theme.spacing.medium, 0]
  },
  vinificationServiceInfo: {
    color: theme.color.primary
  },
  suggestions: {
    width: '100%',
    overflow: 'hidden',
    '& .swiper.swiper-horizontal': {
      width: 'calc(100% - 20px)',
      [theme.breakpoints.md]: {
        width: 'calc(100vw - ((100vw - 1100px + (4rem * 2)) / 2))',
      },
      [theme.breakpoints.lg]: {
        width: 350 * 3,
      },
      [theme.breakpoints.xl]: {
        width: 350 * 4,
      },
    },
  },
  suggestionsRelated: {
    position: 'relative',
    width: '100%',
    minWidth: '100%',
    zIndex: 2,
    height: 379,
    boxSizing: 'border-box',
    '&:before': {
      content: "''",
      position: 'absolute',
      zIndex: 2,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: `linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 71.2%)`,
    },
    [theme.breakpoints.md]: {
      width: 350,
      minWidth: 350,
      height: 410,
    }
  },
  suggestionsRelatedContent: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: [0, theme.spacing.medium, theme.spacing.large],
    zIndex: 3,
    textAlign: 'center',
    color: theme.color.white,
    '& .wysiwyg': {
      '& p': {
        fontWeight: 700,
        margin: 0,
        textTransform: 'uppercase',
        fontSize: 16,
        lineHeight: '18px',
      },
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        margin: 0,
        fontWeight: 400,
        fontStyle: 'italic',
      },
      '& h4': {
        fontSize: 20,
        lineHeight: '27px',
      }
    }
  },
  suggestionsRelatedImage: {
    position: 'relative',
    zIndex: 1,
  },
  suggestionsRelatedTitle: {
    fontSize: 18,
  },
  suggestionsRelatedSubtitle: {
    fontSize: 16,
    marginTop: 6,
  },
  suggestionsRelatedItemActive: {},
  suggestionsProduct: {
    '&:not(:last-child)': {
      borderRight: `0.5px solid ${theme.color.grayLine}`
    }
  },
  anchorLinks: {
    position: 'fixed',
    top: headerHeightMobile,
    left: 0,
    zIndex: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '1em',
    backgroundColor: theme.color.grayBg,
    [theme.breakpoints.md]: {
      top: headerHeightDesktop,
    },
  },
  anchorLinksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing.medium,
    width: '100%',
    [theme.breakpoints.md]: {
      ...theme.container.small,
      ...(theme.container.small as any)[theme.breakpoints.md],
    }
  },
  anchorLink: {
    ...theme.link.primary,
    fontSize: 12,
    color: theme.color.black,
    paddingTop: '1em',
    '&$anchorLinkActive': {
      color: theme.color.primary,
    }
  },
  anchorLinkActive: {},
  anchorLinkSwiper: {
    paddingBottom: 30,
    '& .swiper-pagination-bullet': {
      backgroundColor: theme.color.grayMask,
      transformOrigin: 'center',
      transition: 'all .3s ease',
      '&.swiper-pagination-bullet-active': {
        backgroundColor: theme.color.primary,
        transform: 'scale(1.5)'
      }
    },
    '& .swiper-pagination-custom': {
      borderTop: '0.5px solid #CDCDCD',
    }
  },
  paginationAnchor: {
    height: 6,
    width: 45,
    backgroundColor: '#E84B31',
    borderRadius: 10,
    position: 'relative',
    top: '16px',
    zIndex: 10,
    left: 20,
  },
  anchorLinkSlide: {
    display: 'flex',
    justifyContent: 'center'
  },
  contentLinkLock: {
    minHeight: 10,
    height: 25,
    padding: 5,
    minWidth: 40,
    backgroundColor: 'rgb(232, 75, 49)',
    color: 'white',
    [theme.breakpoints.md]: {
      fontSize: '12px',
      minWidth: '110px',
      minHeight: '36px',
      padding: '10px 10px 10px 15px',
      marginRight: 0,
    },
    '& svg': {
      height: '100%',
      width: '100%',
      marginLeft: 0,
      [theme.breakpoints.md]: {
        height: 18,
        marginRight: '0.5em',
        marginLeft: '1rem',
      },
    },
  },
  contentLink: {
    minHeight: 10,
    height: 25,
    padding: 5,
    minWidth: 40,
    marginRight: 25,
    [theme.breakpoints.md]: {
      fontSize: '12px',
      minWidth: '110px',
      minHeight: '36px',
      padding: '10px 10px 10px 15px',
      marginRight: 0,
    },
    '& svg': {
      height: '100%',
      width: '100%',
      marginLeft: 0,
      [theme.breakpoints.md]: {
        height: 18,
        marginRight: '0.5em',
        marginLeft: '1rem',
      },
    },
  },
}));

const Product: React.FC<DefaultViewProps & { data: PageProductData }> = ({ data: pageData }) => {
  const styles = useStyles();
  const data = pageData.data;
  const dictionary = useLanguageStrings();
  const vinification = data.field_vinification_title;
  const isDesktop = useIsDesktop();

  const assemblageLink = useAnchorLink(dictionary.assemblage, 'assemblage');
  const terroirLink = useAnchorLink(dictionary.terroir, 'terroir');
  const vinifiactionLink = useAnchorLink(dictionary["vinification.aging"], 'vinification');
  const foodPairingsLink = useAnchorLink(dictionary["food.pairings"], 'food-parings');
  const suggestionsLink = useAnchorLink(dictionary["suggestions"], 'suggestions');
  const [millesimesSwiper, setMillesimesSwiper] = useState<SwiperClass | undefined>();
  const [millesimesSwiperBegin, setMillesimesSwiperBegin] = useState<boolean | undefined>(true);
  const [millesimesSwiperEnd, setMillesimesSwiperEnd] = useState<boolean | undefined>(false);

  const links = [assemblageLink, terroirLink, vinifiactionLink, foodPairingsLink, suggestionsLink]

  const scrollMillesimeSwiper = useCallback((way: string) => {
    switch (way) {
      case 'up':
        millesimesSwiper?.slidePrev()
        break;
      case 'down':
        millesimesSwiper?.slideNext()
        break;
    }

    setMillesimesSwiperBegin(millesimesSwiper?.isBeginning);
    setMillesimesSwiperEnd(millesimesSwiper?.isEnd);
  }, [setMillesimesSwiperBegin, setMillesimesSwiperEnd, millesimesSwiper]);

  const paginationAnchorElement: any = document.getElementsByClassName('pagination-anchor-custom');
  const paginationAnchor = useCallback((swiper: SwiperClass, current: number, total: number) => {
    swiper.on('setTranslate', function onSliderMove(this: SwiperClass) {
      const position = (window.innerWidth - 85) * this?.progress;

      const firstElement = paginationAnchorElement[0];
      firstElement.style.transform = 'translate3d(' + position + 'px, 0, 0)';
    });
  }, [paginationAnchorElement]);

  const paginationOptions: PaginationOptions = {
    type: 'custom',
    renderCustom: (swiper, current, total) => paginationAnchor(swiper, current, total),
  };

  const millesimesYearIsNumeric = () => {
    const value = Object.values(data.field_millesimes_years)[0]
    console.log({ value, p: !!parseInt(value) })
    return !!parseInt(value)
  }

  return (
    <>
      <Meta tags={pageData.meta}/>
      <div className={styles.anchorLinks}>
        <nav className={styles.anchorLinksContainer}>{
          isDesktop ? links.map(({ onClick, isInView, hash, label }) => (
                <a
                  key={hash}
                  href={`#${hash}`}
                  className={cn(styles.anchorLink, isInView && styles.anchorLinkActive)}
                  onClick={onClick}>
                  {label}
                </a>
              ))
          : (
              <Swiper
                pagination={paginationOptions}
                slidesPerView={3}
                spaceBetween={20}
                modules={[Pagination]}
                className={styles.anchorLinkSwiper}
              >
                {links.map(({ onClick, isInView, hash, label }) => (
                   <SwiperSlide key={hash} className={styles.anchorLinkSlide}>
                     <a
                       key={hash}
                       href={`#${hash}`}
                       className={cn(styles.anchorLink, isInView && styles.anchorLinkActive)}
                       onClick={onClick}>
                       {label}
                     </a>
                   </SwiperSlide>
                  ))}

                  { data.field_pdf.length || data.field_lien_pour_achat.length ?
                    <SwiperSlide key="button_header" className={styles.anchorLinkSlide}>
                      { data.field_pdf.length ?
                          <Button className={styles.contentLink} icon={<IoCloudDownloadOutline/>} as="a" target='_blank'
                                  href={data.field_pdf}></Button>: <></>
                      }

                      { data.field_lien_pour_achat.length ?
                          <Button className={styles.contentLinkLock} icon={<IoBagOutline/>} as="a" target='_blank'
                                  href={data.field_lien_pour_achat}></Button>: <></>
                      }
                    </SwiperSlide>: <></>
                  }
                 <div className={styles.paginationAnchor + ' pagination-anchor-custom'}></div>
              </Swiper>
          )
        }
        {(isDesktop && data.field_pdf.length) ?
                <Button className={styles.contentLink} icon={<IoCloudDownloadOutline/>} as="a" target='_blank'
                        href={data.field_pdf}>{dictionary["download"]}</Button> : <></>
        }
        {(isDesktop && data.field_lien_pour_achat.length) ?
          <Button className={styles.contentLinkLock} icon={<IoBagOutline/>} as="a" target='_blank'
                  href={data.field_lien_pour_achat}>{dictionary["lien.achat"]}</Button> : <></>
        }
        </nav>
      </div>
      <article className={styles.product}>
        <section className={styles.millesimes}>
          {/*<div className={styles.millesimesBg}>*/}
          {/*  <icons.BottleBig className={styles.millesimesBottleBig} />*/}
          {/*  <div className={styles.millesimesBottleContainer}>*/}
          {/*    <icons.Bottle className={styles.millesimesBottle} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.millesimesContainer}>
            <div className={styles.millesimesImage}>
              <Image
                // aspectRatio={3.85}
                src={data.field_millesimes_image}
                alt={data.field_millesimes_image_alt}
              />
            </div>
            <div className={styles.millesimesBlockTitle}>
              <div className={styles.millesimesTerm}>
                {data.title}
              </div>
              <Heading className={styles.millesimesTitle}>
                {data.field_sub_title}
              </Heading>
            </div>
            <div className={styles.millesimesCepages}>
              <div className={styles.millesimesSmallTitle}>{dictionary["grape.varieties"]}</div>
              <ul className={styles.millesimesCepagesList}>
                {data.field_cepages && data.field_cepages.length > 3 &&
                  <UpArrow className={
                    styles.millesimesUpArrow +
                    (millesimesSwiperBegin ? ' disable-arrow' : '')
                  } onClick={() => scrollMillesimeSwiper('up')}/>
                }
                <Swiper
                  slidesPerView={3}
                  direction='vertical'
                  height={150}
                  onSwiper={s => setMillesimesSwiper(s)}
                >
                  {(data.field_cepages ?? []).map((cepages, index) => (
                    <SwiperSlide key={index}>
                      <li className={styles.millesimesCepagesItem} key={index}>
                        <Heading tag="span"
                                 className={styles.millesimesCepagesPercentage}>{cepages.field_percentage}%</Heading>
                        <span className={styles.millesimesCepagesVariety}>{cepages.field_grape_variety}</span>
                      </li>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {data.field_cepages && data.field_cepages.length > 3 &&
                  <UpArrow className={
                    styles.millesimesDownArrow +
                    (millesimesSwiperEnd ? ' disable-arrow' : '')
                  } onClick={() => scrollMillesimeSwiper('down')}/>
                }
              </ul>
            </div>
            <div className={styles.millesimesContent}>
              <div className={styles.millesimesSmallTitle}>{dictionary["vintages"]}</div>
              <MillesimesSelect
                className={`${styles.millesimesYear} ${millesimesYearIsNumeric() ? styles.millesimesYearNumeric : ''}`}
                items={data.field_millesimes_years}
              />
              <MillesimesAccordion className={styles.millesimesBody} html={data.field_content} height={55}/>
            </div>
          </div>
        </section>
        <section className={styles.note} ref={assemblageLink.ref}>
          <div className={`${styles.productContainer} ${styles.noteContainer}`}>
            <div className={styles.noteContent}>
              <div className={styles.noteBlockTitle}>
                <HeadingWithLine className={styles.noteTitle}>
                  {dictionary["note"]}
                </HeadingWithLine>
                <div className={styles.noteSubTitle}>{data.field_note_text}</div>
              </div>
              {!isDesktop && (
                <div className={styles.noteImage}>
                  <Image className={styles.notePicture} src={data.field_note_image} alt={data.field_note_image_alt}/>
                </div>
              )}
              <MillesimesAccordion className={styles.millesimesBody} html={data.field_note_description} height={170}/>
            </div>
            {isDesktop && (
              <div className={styles.noteImage}>
                <ImageNoLazy className={styles.notePicture} src={data.field_note_image} alt={data.field_note_image_alt}/>
              </div>
            )}
          </div>
        </section>
        <section className={styles.terroir} ref={terroirLink.ref} style={{
          backgroundImage: `url(${data.field_terroir_image})`
        }}>
          <div className={`${styles.productContainer} ${styles.terroirContainer}`}>
            <div className={styles.terroirContent}>
              <HeadingWithLine className={styles.terroirTitle}>
                {data.field_terroir_titre}
              </HeadingWithLine>
              <Markup className={styles.terroirText} html={data.field_terroir_texte}/>
            </div>
          </div>
        </section>
        { vinification ? (
          <section className={styles.vinification}>
          <div className={`${styles.productContainer} ${styles.vinificationTitleContainer}`}>
            <HeadingWithLine className={styles.vinificationTitle}>
              {data.field_vinification_title}
            </HeadingWithLine>
          </div>
          <div className={styles.vinificationContent} ref={vinifiactionLink.ref}>
            <div className={`${styles.productContainer} ${styles.vinificationContentContainer}`}>
              <div className={styles.vinificationContentLeft}>
                <Markup className={styles.vinificationText} html={data.field_vinification_description}/>
                { data.field_vinification_image_1 ? (
                  <div className={styles.vinificationImage1}>
                  <div className={styles.vinificationImage2}>
                    <Image aspectRatio={0.75} src={data.field_vinification_image_2}
                           alt={data.field_vinification_image_2_alt}/>
                  </div>
                  <Image aspectRatio={0.75} src={data.field_vinification_image_1}
                         alt={data.field_vinification_image_1_alt}/>
                </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.vinificationContentRight} ref={foodPairingsLink.ref}>
                <HeadingWithLine className={styles.vinificationAccordsTitle}>
                  {data.field_vinification_accords_title}
                </HeadingWithLine>
                <Markup className={styles.vinificationAccordsText} html={data.field_vinification_accords_desc}/>
                <ul className={styles.vinificationService}>
                  <li className={styles.vinificationServiceItem}>
                    <div className={styles.vinificationServiceIcon}>
                      <icons.BottleHorizontal/>
                    </div>
                    <div className={styles.vinificationServiceDescription}>
                      <span
                        className={styles.vinificationServiceTitle}>{dictionary["aging_potential"]}</span>
                      <span className={styles.vinificationServiceInfo}>{data.field_vinification_potentiel_gar}</span>
                    </div>
                  </li>
                  <li className={styles.vinificationServiceItem}>
                    <div className={styles.vinificationServiceIcon}>
                      <icons.Barometer/>
                    </div>
                    <div className={styles.vinificationServiceDescription}>
                      <span className={styles.vinificationServiceTitle}>{data.field_vinification_service_title}</span>
                      <span className={styles.vinificationServiceInfo}>{data.field_vinification_service}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        ) : null}
        <section className={styles.suggestions} ref={suggestionsLink.ref}>
          <SliderGallery
            className={styles.suggestionsSlider}
            title={dictionary["suggestions"]}
            subtitle={dictionary["from.your.wine.merchant"]}
            staticItem={data.slider_main && data.slider_main?.field_slider_main ? (
              <div key={-1} className={styles.suggestionsRelated}>
                <div className={styles.suggestionsRelatedImage}>
                  <Image src={data.slider_main?.field_slider_main} alt={data.slider_main?.field_slider_title} aspectRatio={isDesktop ? 1.1 : 0.9}/>
                </div>
                <div className={styles.suggestionsRelatedContent}>
                  <TitleWithLine className={styles.suggestionsRelatedTitle}>{data.slider_main?.field_slider_title}</TitleWithLine>
                  <Heading className={styles.suggestionsRelatedSubtitle} tag="h3"><Markup html={data.slider_main?.field_slider_subtitle} /></Heading>
                </div>
              </div>
            ) : null}
            items={(pageData.data.field_products ?? []).map((product, index) => (
              <ProductPreview product={product} key={index} className={styles.suggestionsProduct} />
            )).filter(Boolean)}
            pagination
            options={{
              loop: true,
              autoplay: {
                delay: 2000,
                disableOnInteraction: false,
              },
              modules: [Autoplay],
              slidesPerView: 1,
              centeredSlides: true,
              breakpoints: {
                640: {
                  slidesPerView: 1.5,
                },
                764: {
                  slidesPerView: 1.8,
                },
                859: {
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                1023: {
                  slidesPerView: 3,
                  centeredSlides: false,
                },
                1365: {
                  slidesPerView: 3.2,
                  centeredSlides: false,
                }
              }
            }}
          />
        </section>
      </article>
    </>
  )
}

export default Product;
