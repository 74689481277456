import React from 'react'
import { getElementId, useWebformElement } from '../utils'

import WebformElementWrapper from './WebformElementWrapper'
import { FormComponentType } from "../types";

export const WebformCheckbox: FormComponentType = ({ element, error, columns, color }) => {
	const id = getElementId(element.name);
	const [{ defaultValue, ...inputProps }, settings] = useWebformElement(element, {
		className: 'form-check-input',
		name: element.name,
		type: element.type,
		id
	});

	/**
	 * For checkboxes title should be after the checkbox.
	 * This how I like to make custom checkboxes. :)
	 * @see https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
	 */
	settings.attributes.title_display = 'after';

	return (
		<WebformElementWrapper color={color} columns={columns} settings={settings} error={error} className="form-check" labelClassName="form-check-label" labelFor={id}>
			<input type="checkbox" defaultChecked={!!defaultValue} {...inputProps} />
		</WebformElementWrapper>
	)
};

export default WebformCheckbox
