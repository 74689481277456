import {
  DrupalLink,
  DrupalResponseAttribute,
  JsonApiDefaultResponse,
  Links,
} from "./index";

export enum SocialLinkType {
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  LINKEDIN,
}

export type SocialLink = {
  uri: string;
  title: string;
  options: any[];
  type: SocialLinkType;
};

export type SiteSettingsResponse = {
  jsonapi: JsonApiDefaultResponse;
  data: {
    type: string;
    id: string;
    links: Links;
    attributes: DrupalResponseAttribute & {
      field_burger_menu_link_text: string;
      field_facebook_link?: SocialLink;
      field_linkedin_link?: SocialLink;
      field_footer_text?: {
        value: string;
        format: string;
        processed: string;
      };
      field_instagram_link?: SocialLink;
      field_mention_legales?: {
        uri?: string;
        full_url?: string;
        title?: string;
        options?: any[];
      };
      field_subscribe_form_button?: string;
      field_subscribe_form_description?: string;
      field_subscribe_form_placeholder?: string;
      field_copyright?: string;
      field_youtube_link?: SocialLink;
    };
    relationships: {
      config_pages_type: any;
      field_burger_menu: {
        data: {
          type: string;
          id: string;
          meta: {
            drupal_internal__target_id: string;
          };
        };
        links: Links;
      };
      field_contact_form: {
        data: {
          type: string;
          id: string;
          meta: {
            drupal_internal__target_id: string;
          };
        };
        links: Links;
      };
      field_footer_menu: {
        data: {
          type: string;
          id: string;
          meta: {
            drupal_internal__target_id: string;
          };
        };
        links: Links;
      };
      field_logo: {
        data: {
          type: string;
          id: string;
          meta: {
            alt: string;
            drupal_internal__target_id: string;
          };
        };
        links: Links;
      };
      field_reservation_webform_settin: any;
    };
  };
  links: DrupalLink;
};

export type MenuResponse = {
  jsonapi: JsonApiDefaultResponse;
  data: Array<{
    attributes: DrupalResponseAttribute & {
      title: string;
      url: string;
      weight: string;
      description: string;
      enabled: boolean;
      expanded: boolean;
      parent: string;
    };
    id: string;
    type: string;
  }>;
  links: DrupalLink;
};

export const HOMEPAGE_DEFAULT_ID = "standard.front_page";
