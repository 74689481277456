export const color = {
  primary: '#E84B31',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#25282A',
  grayText: '#5F5C5B',
  grayMask: '#7C7E7F',
  grayLine: '#CDCDCD',
  grayBg: '#F5F5F5',
  whiteText: '#EDEDED',
}
