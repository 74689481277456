import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useLanguageContext } from "../i18n/components/LanguageProvider";
import { ViewType } from "../shared/views";
import { translatePath } from "../api";
import PagePreloader from "../components/PagePreloader";
import * as views from '../views';
import {
  ArticlePageData,
  BlogPageData,
  PageData,
  PageGammeData,
  PageHomeData,
  PagePageData,
  PageProductData,
  TermGammeData
} from "./pageData";
import { TranslatePathResponse } from "../shared/router";
import { createGetData } from "./createGetData";
import { useSetBreadcrumbs } from "../context/Breadcrumbs";
import { useSetAlternateLinks } from "../context/AlternateLinks";
import { useHeaderThemeContext } from "../context/HeaderThemeContext";

type ViewProps = {
  type: ViewType,
  individual: string,
  entity: TranslatePathResponse["entity"],
  data?: PageData,
}

const DecoupledSwitch: React.FC = () => {
  const location = useLocation();
  const language = useLanguageContext();
  const [viewType, setViewType] = useState<ViewProps>({ type: ViewType.EMPTY, individual: '', entity: {} });
  const [loading, setLoading] = useState<number>(100);
  const setBreadcrumbs = useSetBreadcrumbs();
  const setAlternateLinks = useSetAlternateLinks();
  const [, setHeaderTheme] = useHeaderThemeContext();

  useEffect(() => {
    let aborted = false;
    async function getTranslatedPath() {
      const { pathname, search } = location;
      const path = pathname.replace(`/${language}`, '');
      const response = await translatePath(path);
      const { jsonapi: { individual, resourceName }, entity } = response;

      setLoading(40);

      const getData = createGetData(resourceName);
      if (entity && getData && !aborted) {
        const data = await getData(entity, search);
        if (!aborted) {
          setViewType({
            type: resourceName,
            individual,
            entity,
            data,
          });

          setHeaderTheme(resourceName === ViewType.PAGE_PRODUCT ? 'light' : 'dark');
          setBreadcrumbs(data?.breadcrumbs ?? []);
          setAlternateLinks(data?.alternateLinks ?? []);
        }
      }
    }

    setLoading(10);
    getTranslatedPath()
      .catch(() => setViewType({ type: ViewType.PAGE_404, individual: '', entity: { } }))
      .finally(() => setLoading(100));
    return () => {
      aborted = true;
    }
  }, [location, language, setBreadcrumbs]);

  const renderPage = () => {
    switch (viewType.type) {
      case ViewType.PAGE_BLOG: {
        return <views.Blog {...viewType} data={viewType.data as BlogPageData} />
      }
      case ViewType.PAGE_ARTICLE: {
        return <views.Article {...viewType} data={viewType.data as ArticlePageData} />
      }
      case ViewType.PAGE_GAMME: {
        return <views.Gamme {...viewType} data={viewType.data as PageGammeData} />
      }
      case ViewType.PAGE_404: {
        return <views.Page404 />
      }
      case ViewType.PAGE_HOME: {
        return <views.Home {...viewType} data={viewType.data as PageHomeData} />
      }
      case ViewType.PAGE_PRODUCT: {
        return <views.Product {...viewType} data={viewType.data as PageProductData} />
      }
      case ViewType.TERM_GAMME: {
        return <views.TermGamme {...viewType} data={viewType.data as TermGammeData} />
      }
      case ViewType.PAGE_PAGE: {
        return <views.Page {...viewType} data={viewType.data as PagePageData} />
      }
      case ViewType.EMPTY:
      default: {
        return null;
      }
    }
  }

  return (
    <PagePreloader
      empty={viewType.type === ViewType.EMPTY}
      loading={loading}>
      {renderPage()}
    </PagePreloader
    >
  )

}

export default DecoupledSwitch;
