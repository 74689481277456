import React, { useState } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { NestedMenu, withActiveClassName } from "../../utils";
import { ArrayElement } from "../../shared";
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { useIsDesktop } from "../../context/ScreenSize";

type Props = {
  menuItem: ArrayElement<NestedMenu>,
  index: number,
}

const numberWidth = 60;

const useStyles = createUseThemedStyles(theme => ({
  menuItem: {
    padding: [0, 0, 28],
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    width: 35,
    marginRight: numberWidth - 35,
    flexShrink: 0,
    color: theme.color.grayMask,
    paddingTop: 28,
    paddingBottom: 8,
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500,
  },
  numberActive: {
    color: theme.color.white,
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.color.primary,
    transition: 'all .3s ease',
    transformOrigin: 'center center',
    fontSize: 0,
    lineHeight: 0,
    width: 40,
    height: 40,
    [theme.breakpoints.md]: {
      display: 'block',
      width: 'auto',
      height: 'auto',
      color: theme.color.grayMask,
    }
  },
  arrowActive: {
    color: theme.color.primary,
    transform: 'rotate(90deg)',
    [theme.breakpoints.md]: {
      color: theme.color.white,
    },
  },
  title: {
    ...theme.link.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    color: theme.color.grayMask,
    fontSize: 35,
    lineHeight: '50px',
    paddingTop: 28,
    paddingBottom: 8,
    borderTop: '1px solid transparent',
    transition: 'all .3s ease',
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
    fontWeight: 400,
    [theme.breakpoints.md]: {
      fontSize: 40,
    }
  },
  titleActive: {
    color: theme.color.primary,
    borderColor: theme.color.grayMask,
  },
  children: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: 60,
    [theme.breakpoints.md]: {
      paddingLeft: 0,
    }
  },
  subMenu: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 0,
    margin: 0,
    discStyle: 'none',
    opacity: 0,
    transition: 'all .3s ease',
    maxHeight: 0,
    overflow: 'hidden',
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      maxHeight: 'none',
    },
    '& > *:first-child': {
      marginTop: theme.spacing.large,
      [theme.breakpoints.md]: {
        marginTop: 0,
      }
    }
  },
  subMenuActive: {
    opacity: 1,
    maxHeight: 500,
  },
  subItem: {
    ...theme.link.primary,
    margin: [theme.spacing.tiny, 0],
    fontSize: 11,
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.md]: {
      margin: [0, theme.spacing.large, 0, 0]
    }
  },
  dash: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 35,
    marginRight: numberWidth - 35,
    flexShrink: 0,
    color: theme.color.grayMask,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      content: "''",
      display: 'block',
      width: 9,
      height: 2,
      background: 'currentColor',
    },
    [theme.breakpoints.md]: {
      position: 'static',
    }
  },
  dashActive: {
    color: theme.color.white,
  }
}))

const MenuItem: React.FC<Props> = ({ index, menuItem }) => {
  const styles = useStyles();
  const [active, setActive] = useState(false);
  const LinkElement = menuItem.item.attributes.url ? Link : React.Fragment;
  const withActive = withActiveClassName(active, styles);
  const isDesktop = useIsDesktop();
  const noLink = !menuItem.item.attributes.url;

  return (
    <li
      className={styles.menuItem}
      onMouseEnter={() => isDesktop ? setActive(true) : null}
      onMouseLeave={() => isDesktop ? setActive(false) : null}
    >
      <div className={styles.content}>
        <div className={withActive('number')}>{index + 1}</div>
        {noLink ?
           (
             <span className={withActive('title')}>
               {menuItem.item.attributes.title}
               <div
                 className={withActive('arrow')}
                 onClick={(e) => {
                   if (!isDesktop) {
                     e.stopPropagation();
                     e.preventDefault();
                     setActive(active => !active)
                   }
                 }}
               >
                 <BsChevronRight size={16}/>
               </div>
             </span>
           ) : (
          <LinkElement
            to={menuItem.item.attributes.url}
            className={withActive('title')}
          >
          {menuItem.item.attributes.title}
          <div
            className={withActive('arrow')}
            onClick={(e) => {
              if (!isDesktop) {
                e.stopPropagation();
                e.preventDefault();
                setActive(active => !active)
              }
            }}
          >
            <BsChevronRight size={16}/>
          </div>
          </LinkElement>
        )}
          </div>
      <div className={styles.children}>
        <div className={withActive('dash')} />
        <ul className={withActive('subMenu')} >
          {menuItem.children.map((item, index) => (
            <Link
              key={index}
              to={item.attributes.url}
              className={styles.subItem}
            >
              {item.attributes.title}
            </Link>
          ))}
        </ul>
      </div>
    </li>
  );
}

export default MenuItem;
