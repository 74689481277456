import React from 'react';
import { Heading, Markup, TitleWithLine } from "../Typography";
import { navHeight } from "../SliderNavigation";
import Slider from "../Slider/Slider";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { headerHeightDesktop } from "../../shared/styles";
import Image from '../Image';

type Props = {
  title?: string;
  subtitle?: string;
  img: {
    url?: string;
    alt?: string;
  };
  description?: string;
}

const useStyles = createUseThemedStyles((theme) => ({
  img: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: 1,
    '& img': {
      height: '100%',
      objectFit: 'cover',
    }
  },
  subtitle: {
    marginBottom: theme.spacing.large
  },
  title: {
    fontSize: 54,
    lineHeight: '64px',
    margin: 0,
    '& .wysiwyg': {
      lineHeight: 'inherit',
      fontSize: 54,
    }
  },
  content: {
    ...theme.container.base,
    maxWidth: 1224,
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing.xxlarge,
    paddingTop: `${navHeight + 10 + headerHeightDesktop}px`,
    color: '#fff',
    height: '100%',
    boxSizing: 'border-box',
  },
  description: {
    fontSize: 20,
    fontFamily: theme.fonts.heading,
    fontStyle: 'italic',
  }
}));


const TermGammeSlider: React.FC<Props> = ({ title, subtitle, img: { url, alt }, description  }) => {
  const styles = useStyles();

  return (
    <Slider
      items={[
        (
          <React.Fragment key={0}>
            <Image
              className={styles.img}
              src={url}
              alt={alt}
            />
            <div className={styles.content}>
              <TitleWithLine className={styles.subtitle}>{subtitle}</TitleWithLine>
              <Heading className={styles.title} tag="h3"><Markup html={title} /></Heading>
              <Markup className={styles.description} html={description} />
            </div>
          </React.Fragment>
        )
      ]}
    />
  )
}

export default TermGammeSlider;
