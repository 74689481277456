import React, { useEffect, useState } from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import hexToRgba from "hex-to-rgba";
import { useSwiper } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";

type Props = {
  slides?: number;
  className?: string;
  color?: string;
  swiper?: SwiperClass
}

const useStyles = createUseThemedStyles((theme) => ({
  container: {

  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.medium,
  },
  bullet: {
    cursor: 'pointer',
    width: 7,
    height: 7,
    borderRadius: '50%',
    background: ({ color }: { color: string }) => color ?? hexToRgba(theme.color.white, 0.4),
    transition: 'transform .3s ease, background .3s ease',
    '&$bulletActive': {
      background: theme.color.primary,
    }
  },
  bulletActive: {
    transform: 'scale(1.42)',
  },
}));

const SliderPagination: React.FC<Props> = ({ slides = 0, className, color, swiper: swiperProp, }) => {
  const styles = useStyles({ color });
  const [activeSlide, setActiveSlide] = useState<number>(0);
  let swiper = useSwiper();

  if (swiperProp) swiper = swiperProp;


  useEffect(() => {
    if (swiper) {
      const slideChange = (s: SwiperClass) => setActiveSlide(s.realIndex)
      swiper.on('slideChange', slideChange);

      return () => swiper.off('slideChange', slideChange);
    }
  }, [swiper])

  return (
    <div className={`${styles.pagination}${className ? ` ${className}` : ''}`}>
      {Array.from(Array(slides).keys()).map(index => (
        <span
          key={index}
          onClick={() => swiper?.slideTo(index)}
          className={`${styles.bullet}${index === activeSlide ? ` ${styles.bulletActive}`: ''}`}
        />
      ))}
    </div>
  )
};

export default SliderPagination;
