import React, { useMemo } from 'react';
import { DefaultViewProps } from "../shared/views";
import Meta from "../components/Meta";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { PagePageData } from "../router/pageData";
import {Heading, Markup, TitleWithLine} from "../components/Typography";
import {navHeight} from "../components/SliderNavigation";
import {headerHeightDesktop} from "../shared/styles";
import Image from "../components/Image";
import Slider from "../components/Slider/Slider";
import Mask from "../components/Mask";
import PageHomeContentBlock from "../components/Content/PageHomeContentBlock";
import cn from "classnames";
import { useIsDesktop } from "../context/ScreenSize";


const useStyles = createUseThemedStyles(theme => ({
  topBanner: {
    '& > div': {
      paddingTop: '120%',
      [theme.breakpoints.sm]: {
        paddingTop: '64%',
      },
      [theme.breakpoints.md]: {
        paddingTop: '42%',
      }
    },
  },
  topImg: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: 1,
    '& img': {
      height: '100%',
      objectFit: 'cover',
    }
  },
  imageTitle: {
    textTransform: 'uppercase',
    marginTop: theme.spacing.large,
    marginLeft: 40,
    [theme.breakpoints.md]: {
      marginLeft: 50,
    }
  },
  imageDescription: {
    fontSize: 32,
    lineHeight: 1.4,
    margin: 0,
    [theme.breakpoints.md]: {
      fontSize: 50,
    }
  },
  imageContent: {
    ...theme.container.small,
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: '#fff',
    height: '100%',
    boxSizing: 'border-box',
    padding: [100, 50, 80],
    [theme.breakpoints.md]: {
      paddingBottom: `calc(${theme.spacing.xlarge} + ${navHeight + 10}px)`,
      paddingTop: `calc(${theme.spacing.xlarge} + ${navHeight + 10 + headerHeightDesktop}px)`,
      justifyContent: 'center',
    }
  },
  content: {
    background: theme.color.white,
    display: 'flex',
    overflow: 'hidden',
  },
  imageBottom: {
    position: 'relative',
  },
  imageBottomContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 3,
    '& h3 .wysiwyg': {
      fontSize: '50px !important',
      lineHeight: '1.6 !important',
    }
  },
  contentBottomContainer: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontSize: 14,
    ...theme.container.small,
    [theme.breakpoints.md]: {
      ...(theme.container.small as any)[theme.breakpoints.md],
      display: 'flex',
      alignItems: 'stretch',
      gap: 60,
    },
    [theme.breakpoints.lg]: {
      gap: 100,
    }
  },
  contentBottomContent: {
    margin: [theme.spacing.medium, 0],
    [theme.breakpoints.md]: {
      flex: '1 1 0px',
      margin: [90, 0],
    }
  }
}))

const Page: React.FC<DefaultViewProps & { data: PagePageData }> = ({ data: pageData }) => {
  const styles = useStyles();
  const data = pageData.data;
  const isDesktop = useIsDesktop();

  return (
    <>
      <Meta tags={pageData.meta}/>
      <section className={styles.topBanner}>
      {data.field_page_top_image ? (
        <Slider
          items={[
            (
              <>
                <Image
                  className={styles.topImg}
                  src={data.field_page_top_image}
                  alt={data.field_page_top_image_alt }
                />
                <div className={styles.imageContent}>
                  {data.field_page_top_text && <Heading className={styles.imageDescription} tag="h3"><Markup html={data.field_page_top_text} /></Heading>}
                  {data.field_page_top_orange_text && <TitleWithLine linePosition="left"  className={styles.imageTitle}>{data.field_page_top_orange_text}</TitleWithLine>}
                </div>
              </>
            )
          ]}
        />
      ) : (<></>)}
      </section>
      {data.field_page_title ? (
        <section className={styles.content}>
          <PageHomeContentBlock
            title={data.field_page_title}
            subTitle={data.field_page_texte_orange}
            image1={data.field_page_image_left_second}
            image1alt={data.field_page_image_left_second_alt}
            image={data.field_page_image_left_first}
            imageAlt={data.field_page_image_left_first_alt}
            imageRightSide={data.field_page_right_image}
            imageRightSideAlt={data.field_page_right_image_alt}
            content={data.field_page_texte_top_gauche}
            contentBottom={data.field_page_texte_bas_gauche}
            contentRight={data.field_page_right_texte}
          />
        </section>
      ) : (<></>)}
      {(data.field_page_bottom_image) ? (
        <section className={styles.imageBottom}>
          <Image
            className={styles.botImg}
            src={data.field_page_bottom_image}
            alt={data.field_page_bottom_image_alt}
            aspectRatio={isDesktop ? 0.42 : 1.78}
          />
          <div className={styles.imageBottomContent}>
            <div className={styles.imageContent}>
              <Heading className={styles.imageDescription} tag="h3"><Markup html={data.field_page_bottom_text} /></Heading>
              <TitleWithLine linePosition="left" className={styles.imageTitle}>{data.field_page_bottom_orange_text}</TitleWithLine>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
      <section className={styles.contentBottom}>
        <div className={styles.contentBottomContainer}>
          {data.field_page_first_column && <Markup className={styles.contentBottomContent} html={data.field_page_first_column} />}
          {data.field_page_second_column && <Markup className={styles.contentBottomContent} html={data.field_page_second_column} />}
        </div>
      </section>
    </>
  );
}

export default Page;
