import React from "react";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { AiFillCloseCircle } from "react-icons/ai";
import IconButton from "../IconButton";

const useStyles = createUseThemedStyles(theme => ({
  button: {
    position: 'absolute',
    top: theme.spacing.medium,
    right: theme.spacing.medium,
    color: theme.color.primary,
    ...theme.button.rotating,
  }
}))

const Button: React.FC<{ onClick: (e: React.SyntheticEvent) => void; }> = ({ onClick }) => {
  const styles = useStyles();
  return (
    <IconButton onClick={onClick} className={styles.button}>
      <AiFillCloseCircle size={35}/>
    </IconButton>
  )
}

export default Button;
