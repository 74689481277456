import { color } from "./colors";

const borderBottom = '2px solid white';
export const link = {
  primary: {
    color: color.white,
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    paddingBottom: '0.5em',
    paddingTop: '0.5em',
    lineHeight: 1,
    fontWeight: 500,
    cursor: 'pointer',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 2,
      borderRadius: 1,
      transform: 'scaleX(0)',
      transformOrigin: 'left center',
      background: 'currentColor',
      transition: 'transform .3s ease',
    },
    '&:hover, &:active': {
      '&:after': {
        transform: 'scaleX(1)'
      }
    },
  },
  secondary: {
    color: color.white,
    transition: 'color .3s ease',
    textDecoration: 'none',
    '&:hover, &:active': {
      color: color.primary,
    },
  }
}
