import React from 'react';
import { PageProductData } from "../../router/pageData";
import { useHistory, useLocation } from "react-router-dom";
import Select from "../Form/Select";
import SingleSelect from "../Form/SingleSelect";

const millesemesKey = "field_millesimes_years";

type Props = {
  items: PageProductData["data"][typeof millesemesKey];
  className?: string;
}

type MillesimesOption = {
  label: string;
  value: string;
}

const MillesimesSelect: React.FC<Props> = ({
  items,
  className
}) => {
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const params = new URLSearchParams(search);

  const handleChange = (option: MillesimesOption | null) => {
    if (option?.value) {
      params.set(millesemesKey, option.value);

      history.push({
        ...location,
        search: params.toString(),
      })
    }
  };

  const options: Array<MillesimesOption> = Object.entries(items).map(([key, value]) => ({ label: value, value: key }));
  const value = options.find(option => option.value === params.get(millesemesKey)) ?? options[options.length - 1];
  const length = options.filter(function(v){ return true; }).length;

  let select = <Select<MillesimesOption> onChange={handleChange} value={value} options={options} className={className}  isSearchable={false} />;
  if (length > 1) {
    select = <Select<MillesimesOption> onChange={handleChange} value={value} options={options} className={className}  isSearchable={false} />;
  }
  if (length == 1) {
    select = <SingleSelect<MillesimesOption> onChange={handleChange} value={value} options={options} className={className}  isSearchable={false} isDisabled={true} />;
  }

  return select;
}

export default MillesimesSelect;
