import React from 'react';

import {FormComponentType} from "../Form/Webform/types";
import {getElementId, INPUT_TYPES_WHITELIST, useWebformElement} from "../Form/Webform/utils";
import {createUseThemedStyles} from "../../hooks/createUseThemedStyles";

const useStyles = createUseThemedStyles(theme => ({
  input: {
    border: '2px solid',
    color: theme.color.white,
    borderColor: theme.color.grayMask,
    borderRadius: 9999,
    background: theme.color.gray,
    height: 38,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: [0, theme.spacing.medium],
    borderRight: 'none',
    width: '50%',
    '&:placeholder': {
      color: theme.color.grayMask,
    },
    [theme.breakpoints.md]: {
      width: 'auto'
    }
  }
}))

export const WebformInputEmail: FormComponentType = ({ element, error, color, columns }) => {
	const id = getElementId(element.name);
  const styles = useStyles({ color });
	const [inputProps, settings] = useWebformElement(element, {
		name: element.name,
		type: INPUT_TYPES_WHITELIST.includes(element.type) ? element.type : 'text',
		id
	});

	if (element.type === 'hidden') {
		return <input {...inputProps} />
	}

	return (
    <input className={styles.input} {...inputProps} />
	)
};

export default WebformInputEmail;
