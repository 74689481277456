import React, { useState } from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { HeadingWithLine, Markup } from "../components/Typography";
import { DefaultViewProps } from "../shared/views";
import { TermGammeData } from "../router/pageData";
import ProductPreview, { PreviewSize } from "../components/Product/ProductPreview";
import TermGammeSlider from "../components/TermGamme/TermGammeSlider";
import { useCreateProductGroups } from "../hooks/useCreateProductGroups";
import Image from "../components/Image";
import Button from "../components/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { useLanguageContext } from "../i18n/components/LanguageProvider";
import useLanguageStrings from "../hooks/useLanguageStrings";
import cn from "classnames";

type Props = {}

const useStyles = createUseThemedStyles(theme => ({
  page: {
    minHeight: '100vh',
    width: '100%',
    background: theme.color.white,
    paddingBottom: theme.spacing.xlarge,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    /* [theme.breakpoints.md]: {
       display: 'block',
     }*/
  },
  section: {
    width: '100%',
  },
  title: {
    fontSize: 40,
  },
  suggestions: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    ...theme.container.base,
    [theme.breakpoints.md]: {
      ...theme.container.small,
      display: 'flex',
      //   display: 'block',
      //   paddingLeft: '24px',
    }
  },
  containerWithImage: {
    [theme.breakpoints.md]: {
      ...theme.container.small,
      display: 'flex',
      alignItems: 'stretch',
      //   display: 'block',
      //   paddingLeft: '24px',
    }
  },
  containerLast: {
    [theme.breakpoints.md]: {
      flexWrap: 'wrap',
    },
    '& $suggestionsProduct': {
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
      borderBottom: `0.5px solid ${theme.color.grayLine}`,
      '&:nth-child(3n)': {
        borderRight: `0.5px solid ${theme.color.grayLine}`,
      },
      '&:nth-child(4)': {
        borderTop: 'none',
      }
    }
  },
  leftPart: {
    paddingTop: 80,
    paddingBottom: 60,
    background: theme.color.white,
    borderLeft: `0.5px solid ${theme.color.grayLine}`,
    borderRight: `0.5px solid ${theme.color.grayLine}`,
    [theme.breakpoints.md]: {
      borderRight: 'none',
      width: '50%',
      // display: 'block',
      // width: '100%',
      // height: '559px',
      // borderRight: `0.5px solid ${theme.color.grayLine}`,
      // background: theme.color.grayBg,
    }
  },
  rightPart: {
    padding: [20, 30, 60],
    borderRight: `0.5px solid ${theme.color.grayLine}`,
    borderLeft: `0.5px solid ${theme.color.grayLine}`,
    [theme.breakpoints.md]: {
      padding: [0, 0, 80, 80],
      width: 'calc(50% - 0.5px)',
    }
  },
  rightImage: {
    marginBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      height: '571px',
      width: '378px',
      top: '-71px',
      zIndex: '5',
      // height: '434px',
      // width: '283px',
      // top: '-45px',
      // marginBottom: '26px',
    }
  },
  rightData: {
    fontFamily: theme.fonts.heading,
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontFamily: theme.fonts.base,
      // [theme.breakpoints.md]: {
      //   marginBottom: '25px',
      // }
    },
    [theme.breakpoints.md]: {
      width: '366px',
      fontSize: '14px',
      marginTop: '-31px',
      //   width: '100%',
      //   paddingLeft: '22px',
      //   paddingRight: '22px',
      //   lineHeight: '25px',
    }
  },
  suggestionsProduct: {
    borderLeft: `0.5px solid ${theme.color.grayLine}`,
    borderRight: `0.5px solid ${theme.color.grayLine}`,
    borderBottom: `0.5px solid ${theme.color.grayLine}`,
    position: 'relative',
    minHeight: 460,
    '&:after': {
      content: '" "',
      display: 'block',
      height: '0.5px',
      width: '90%',
      background: theme.color.grayLine,
      position: 'absolute',
      top: '100%',
      left: '5%',
      zIndex: '6',
    },
    '&:last-child:not($suggestionsProductLast)': {
      content: 'normal',
    },
    [theme.breakpoints.md]: {
      minHeight: '465px',
      width: 'calc(100% / 3)',
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
      borderRight: 'none',
      '&:after': {
        content: 'normal',
      },
      '&:last-child': {
        borderRight: `0.5px solid ${theme.color.grayLine}`,
      },
      '&:nth-child(4)': {
        borderTop: `0.5px solid ${theme.color.grayLine}`,
      },
    }
  },
  suggestionsProductLast: {
    [theme.breakpoints.md]: {
      borderBottom: `0.5px solid ${theme.color.grayLine}`,
    }
  },
  leftImageContainer: {
    [theme.breakpoints.md]: {
      display: 'flex',
      alignItems: 'stretch',
      width: 'calc((100% / 2) + 34px)',
      marginLeft: '-20%',
    }
  },
  line: {
    [theme.breakpoints.md]: {
      zIndex: '6',
      width: '0.5px',
      height: '100%',
      backgroundColor: theme.color.grayLine,
      display: 'block',
      position: 'absolute',
      left: 'calc((100% - 1036px) / 2)',
    }
  },
  leftImage: {
    left: '0px',
    width: '100%',
    display: 'block',
    '& img': {
      height: '100%',
      objectFit: 'cover',
    }
  },
  bg: {
    width: '100%',
    background: theme.color.grayBg,
  },
  productBig: {
    ...theme.container.base,
    '& article': {
      position: 'relative',
      height: '100%',
      minHeight: 460,
      borderRight: `0.5px solid ${theme.color.grayLine}`,
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
      '&:after': {
        content: '" "',
        display: 'block',
        height: '0.5px',
        width: '90%',
        background: theme.color.grayLine,
        position: 'absolute',
        top: '100%',
        left: '5%',
        zIndex: '6',
      },
    },
    [theme.breakpoints.md]: {
      '& article': {
        border: 'none',
        '&:after': {
          content: 'normal',
        },
      },
      padding: 0,
      margin: 0,
      width: 'calc((100% / 3) + (100% / 3))',
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
      borderBottom: `0.5px solid ${theme.color.grayLine}`,
    }
  },
  rotatedProductSection: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.md]: {
      flexDirection: 'row'
    }
  },
  dataRotatedLeft: {
    padding: [40, 30, 50],
    [theme.breakpoints.md]: {
      padding: '42px 62px 35px 44px',
      width: 'calc(100% / 3)',
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
      //   padding: '0px 0px 0px 0px',
      //   width: '100%',
    }
  },
  dataRotatedRight: {
    '& article': {
      position: 'relative',
      minHeight: 460,
      '&:after': {
        content: '" "',
        display: 'block',
        height: '0.5px',
        width: '90%',
        background: theme.color.grayLine,
        position: 'absolute',
        top: '100%',
        left: '5%',
        zIndex: '6',
        [theme.breakpoints.md]: {
          content: "normal",
        }
      },
    },
    [theme.breakpoints.md]: {
      '& article': {
        '&:after': {
          content: "'normal'",
        }
      },
      width: 'calc(100% - 100% / 3)',
      borderRight: `0.5px solid ${theme.color.grayLine}`,
      borderLeft: `0.5px solid ${theme.color.grayLine}`,
    }
  },
  dataRotatedLeftText2: {
    fontSize: '12px',
    marginTop: '37px',
  },
  loadMore: {
    margin: [theme.spacing.xlarge, 'auto', 0],
  }
}));

const defaultMaxGroupItems = 9;

const TermGamme: React.FC<DefaultViewProps & { data: TermGammeData }> = ({ data: pageData }) => {
  const styles = useStyles();
  const data = pageData.data;
  const [maxGroupItems, setMaxGroupItems] = useState<number>(defaultMaxGroupItems);

  const filteredProduct = data.products?.filter((_, i) => i < maxGroupItems)

  const productGroups = useCreateProductGroups(filteredProduct);
  const dictionary = useLanguageStrings();

  return (
    <article className={styles.page}>
      <TermGammeSlider img={data.field_header_image} title={data.title} subtitle={data.field_sub_title}
                       description={data.field_description}/>
      <section>
        <div className={styles.container}>
          <div className={styles.leftPart}>
            {productGroups[0] && (
              <ProductPreview product={productGroups[0]} size={PreviewSize.big} bgColor="white"/>
            )}
          </div>
          <div className={styles.rightPart}>
            <Image className={styles.rightImage} alt={data.field_gamme_image_1.alt} src={data.field_gamme_image_1.url}/>
            <Markup className={styles.rightData} html={data.field_gamme_text_1}/>
          </div>
        </div>
      </section>
      <section className={styles.bg}>
        <div className={styles.container}>
          {productGroups[1].map((product, index) => (
            <ProductPreview product={product} key={index} className={styles.suggestionsProduct}
                            size={PreviewSize.small}/>
          ))}
        </div>
      </section>
      {productGroups[2] && (
        <section>
          <div className={styles.containerWithImage}>
            <div className={styles.leftImageContainer}>
              <Image className={styles.leftImage} alt={data.field_gamme_image_2.alt} src={data.field_gamme_image_2.url}/>
            </div>
            <div className={styles.productBig}>
              {productGroups[2] && (
                <ProductPreview product={productGroups[2]} size={PreviewSize.big} bgColor="white"/>
              )}
            </div>
          </div>
        </section>
      )}
      <section className={styles.section}>
        <div className={styles.container}>
          {productGroups[3].map((product, index) => (
            <ProductPreview product={product} key={3 + index} className={styles.suggestionsProduct} bgColor="white"
                            size={PreviewSize.small}/>
          ))}
        </div>
      </section>
      {data.field_gamme_text_2 ?
      <section className={styles.bg}>
        <div className={styles.container}>
          <div className={styles.rotatedProductSection}>
            <div className={styles.dataRotatedLeft}>
              <HeadingWithLine>
                {data.field_label_gamma_2}
              </HeadingWithLine>
              <Markup className={styles.dataRotatedLeftText2} html={data.field_gamme_text_2}/>
            </div>
            <div className={styles.dataRotatedRight}>
              {productGroups[4] && (
                <ProductPreview product={productGroups[4]} size={PreviewSize.rotated}/>
              )}
            </div>
          </div>
        </div>
      </section> : null}
      {productGroups[5].length ? <section className={styles.section}>
        <div className={cn(styles.container, styles.containerLast)}>
          {productGroups[5].map((product, index) => (
            <ProductPreview product={product} key={6 + index}
                            className={cn(styles.suggestionsProduct, styles.suggestionsProductLast)} bgColor="white"
                            size={PreviewSize.small}
                            rotated={false}
            />
          ))}
        </div>
      </section> : null}
      {(data.products?.length ?? 0) > maxGroupItems ?
        <Button
          className={styles.loadMore}
          color="primary"
          onClick={() => setMaxGroupItems(maxGroupItems => maxGroupItems + defaultMaxGroupItems)}
          icon={<AiOutlinePlus size={11}/>}
        >
          {dictionary["load.more"]}
        </Button> :
        null
      }
    </article>
  )
}

export default TermGamme;
