import React from 'react';
import { Helmet } from 'react-helmet-async';
import { DrupalMetatags } from "../shared";

type Props = {
  tags?: DrupalMetatags;
}

const Meta: React.FC<Props>= ({ tags }) => {
  return (
    <Helmet>
      <title>{tags?.title ?? ''}</title>
      <meta name="description" content={tags?.description ?? ''} />
    </Helmet>
  )
}

export default Meta;
