import React from 'react';
import { getElementId, useWebformElement } from '../utils';
import { Markup } from "../../../Typography";
import { FormComponentType } from "../types";

export const WebformMarkup: FormComponentType = ({ element, error }) => {
	const id = getElementId(element.name);
	const [inputProps, settings] = useWebformElement(element, {
		className: 'form-control',
		name: element.name,
		type: element.type,
		id
	});

  const markup = element.attributes?.find(({ name }) => name === '#markup')?.value

	return (
        <Markup
          // sx={{ flexBasis: '100%', textAlign: 'center', fontSize: 6 }} px={2} mb={4}
            html={markup}
        />
	)
};

export default WebformMarkup;
