const breakpointsFactory = (bp: number) => `@media (min-width: ${bp}px)`;

export const screens = {
  xs: 500,
  sm: 768,
  md: 1024,
  lg: 1366,
  xl: 1540,
}

export const breakpoints = (Object.keys(screens) as Array<keyof typeof screens>).reduce((acc, key) => ({
  ...acc,
  [key]: breakpointsFactory(screens[key]),
}), {}) as { [key in keyof typeof screens]: string }
