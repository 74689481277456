import React, { useEffect } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import Webform from "../Form/Webform";
import { SubmitFormSuccessResponse, WebForm } from "../../shared/form";
import { Markup } from "../Typography";

type Props = {
  webForm: WebForm | null;
  onSubmitSuccess: (response: SubmitFormSuccessResponse) => void;
}

const useStyles = createUseThemedStyles(theme => ({
  form: {
    background: theme.color.whiteText,
    color: theme.color.black,
    padding: theme.spacing.large,
    [theme.breakpoints.md]: {
      flexGrow: 1,
      '& .form-group': {
        '&:first-child, &:nth-child(2)': {
          flexBasis: `calc(50% - (${theme.spacing.medium} / 2))`,
        }
      }
    }
  },
  formTitle: {
    fontSize: 25,
    fontFamily: theme.fonts.base,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    textTransform: 'uppercase',
  },
  formDescription: {
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    fontSize: 12,
    color: theme.color.grayMask,
  }
}))

const formsType: { [key: string]: any } = {
  nom: 'textfield',
  prenom: 'textfield',
  nature_de_votre_reservation: 'select',
  telephone: 'tel',
  email: 'webform_email_multiple',
  commentaire: 'textarea',
}

const ReservationForm: React.FC<Props> = ({ webForm, onSubmitSuccess }) => {
  const styles = useStyles();

  useEffect(() => {
    if (!webForm) return

    webForm.attributes.elements.forEach(el => {
      if (el.name && formsType[el.name]) {
        el.type = formsType[el.name]
      }

      if (el.name === 'email') {
        el.attributes.forEach(attr => {
          if (attr.value === 'Email' || attr.value === 'Mail') {
            attr.value = `${attr.value} *`;
          }
        });
      }

      return el;
    });
  }, [webForm]);

  return (
    <div className={styles.form}>
      <h3 className={styles.formTitle}>{webForm?.attributes.title}</h3>
      {webForm?.attributes.description && <Markup className={styles.formDescription} html={webForm?.attributes.description} />}
      {webForm && <Webform webform={webForm} onSuccess={onSubmitSuccess}  />}
    </div>
  )
}

export default ReservationForm;
