import deepMerge from "ts-deepmerge";

const headers = new Headers();
const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
const basicAuthEnabled = username && password;

headers.set('Content-Type', 'application/json');
if (basicAuthEnabled) {
  headers.set('Authorization', 'Basic ' + btoa(`${username}:${password}`));
}

export function api<T>(url: string, params: RequestInit = {}): Promise<T> {

  return fetch(
    url,
    deepMerge({
      mode: 'cors',
      headers,
    }, params))
    .then((response) => {
      // @todo: some time need to get error messag efrom BO,
      // due to throws before catch response data there is can't get message.
      // if (!response.ok) {
      //   // response.json().then(console.warn);
      //   throw new Error(response.statusText)
      // }
      return response.json()
    })
}
