import { createUseStyles, useTheme } from "react-jss";
import { VignonTheme } from "../theme";

type CreateUseStylesParameters<Props = unknown> = Parameters<typeof createUseStyles<string, Props, VignonTheme>>

export const createUseThemedStyles = <Props = unknown>(styles: CreateUseStylesParameters<Props>[0], options?: CreateUseStylesParameters<Props>[1]) => {
  const useStyles = createUseStyles(styles, options);
  return (props?: any) => {
    const theme = useTheme<VignonTheme>();
    return useStyles({ ...props, theme })
  }
}
