import React from 'react';
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import { AiOutlinePlus } from 'react-icons/ai';
import menuBg1 from '../../assets/trendy-woman-hat-blue-blouse-walking-looking-grey-haired-man-white-long-sleeve-shirt-with-camera-corgi-park 1.png';
import menuBg2 from '../../assets/Screen_Vignoble 1.png';
import menuBg3 from '../../assets/xavier-vins 1.png';
import ReservationPageFooter from './ReservationPageFooter';
import {buildFullAssetUrl} from "../../utils";
import { WebForm } from '../../shared/form';
import { useSiteSettingsContext } from '../../context/SiteSetting';
import Image from '../Image';

type Props = {
  onSelect: (index: number) => void;
};

const useStyles = createUseThemedStyles((theme) => ({
  menu: {
    fontFamily: theme.fonts.base,
    color: theme.color.white,
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    display: 'flex',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    [theme.breakpoints.md]: {
      height: '55vh',
      minHeight: 400,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '& > li': {
      flexGrow: 1,
      listStyle: 'none',
      cursor: 'pointer',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%, 33%',
      display: 'flex',
      flexDirection: 'column-reverse',
      height: 190,
      justifyContent: 'center',
      [theme.breakpoints.md]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
    },
  },
  bgImage: {
    width: '100%',
    height: '100%',
    '& img': {
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  menuItemContent: {
    width: '100%',
    textAlign: 'center',
    padding: '0 30px',
    position: 'absolute',
    top: 'auto',
    bottom: '10%',
    left: 0,
    overflow: 'hidden',
    [theme.breakpoints.md]: {
      top: '25%',
      bottom: '30%',
    },
    '& > p': {
      display: 'none',
      margin: 0,
      [theme.breakpoints.md]: {
        display: 'block',
      },
    },
  },
  heading: {
    fontFamily: theme.fonts.heading,
    color: theme.color.white,
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 32,
    margin: '0 0 20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  point: {
    position: 'absolute',
    top: '25%',
    bottom: 'auto',
    left: '50%',
    border: `2px solid white`,
    width: 46,
    height: 46,
    borderRadius: 23,
    padding: 11,
    marginLeft: -23,
    [theme.breakpoints.md]: {
      width: 75,
      height: 75,
      borderRadius: 37.5,
      padding: 25.5,
      marginLeft: -37.5,
      bottom: '10%',
      top: 'auto',
    },
  },
  icon: {
    transition: 'transform .3s ease',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}));

const ReservationSelectPage: React.FC<Props> = ({ onSelect }) => {
  const styles = useStyles();
  const { natureReservation = [] } = useSiteSettingsContext();

  const options = natureReservation?.map(item => ({
    image: buildFullAssetUrl(item.field_image?.data.attributes.uri.url),
    heading: item.field_title,
    text: item.field_text
  }));

  return (
    <>
      <menu className={styles.menu}>
        {options.map((option, index) => (
          <li onClick={() => onSelect(index)} key={option.heading}>
            <Image
              className={styles.bgImage}
              src={option.image}
              alt='background'
            />
            <div className={styles.menuItemContent}>
              <h6 className={styles.heading}>{option.heading}</h6>
              <p>{option.text}</p>
            </div>
            <div className={styles.point}>
              <AiOutlinePlus size={20} className={styles.icon} />
            </div>
          </li>
        ))}
      </menu>
      <ReservationPageFooter />
    </>
  );
};

export default ReservationSelectPage;
