import { useInView} from "framer-motion";
import { MouseEventHandler, useRef } from "react";

const useAnchorLink = (label: string, hash: string) => {
  const ref = useRef<any>(null);
  const isInView = useInView(ref, { margin: '-50% 0% -50% 0%'});
  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    ref.current?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  return {
    label,
    hash,
    ref,
    isInView,
    onClick,
  }
}

export default useAnchorLink;
