import React, { ReactEventHandler, useState } from 'react';
import { Reseller } from "../../shared/resellers";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { AnimatePresence, motion } from "framer-motion";
import { headerHeightDesktop, headerHeightMobile } from "../../shared/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "../Image";
import { Swiper as SwiperClass } from "swiper/types";
import SliderNavigation from "../SliderNavigation";
import { useTheme } from "react-jss";
import { VignonTheme } from "../../theme";
import { Markup } from "../Typography";
import { AiFillPhone } from 'react-icons/ai';
import cn from "classnames";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import IconButton from "../IconButton";
import { AiFillCloseCircle } from "react-icons/ai";
import { ClockIcon, MailIcon, MapDotIcon, MouseIcon, RouteIcon } from "../icons";

type Props = {
  reseller?: Reseller;
  onClose: ReactEventHandler<HTMLButtonElement>;
}

const useStyles = createUseThemedStyles(theme => ({
  reseller: {
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: 480,
    width: '100%',
    height: `calc(var(--app-height) - ${headerHeightMobile}px)`,
    background: theme.color.white,
    zIndex: 5,
    transformOrigin: 'top',
    color: theme.color.grayMask,
    [theme.breakpoints.md]: {
      left: 80,
      top: 20,
      height: `calc(var(--app-height) - ${headerHeightDesktop + 20}px)`,
    }
  },
  resellerContainer: {
    position: 'relative',
    padding: 20,
    height: '100%',
    overflow: 'auto',
  },
  resellerTitle: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontStyle: 'italic',
    fontSize: 27,
    margin: 0,
    fontWeight: 400,
  },
  resellerCategory: {
    color: theme.color.primary,
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
  },
  titleNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    padding: [24, 24, 40],
    background: theme.color.grayBg,
  },
  navigation: {
    flexShrink: 0,
  },
  contentBlock: {
    padding: [0, 0, 17],
    margin: [17, 0, 0],
    "&:not(:last-child)": {
      borderBottom: `0.5px solid ${theme.color.grayLine}`
    }
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 30,
    margin: 0,
    padding: [40, 0],
  },
  link: {
    ...theme.link.primary,
    color: theme.color.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 11,
    gap: 20,
    '& svg': {
      flexGrow: '1',
    }
  },
  closeButton: {
    position: 'absolute',
    right: 40,
    top: 40,
    color: theme.color.primary,
    zIndex: 5,
    borderRadius: 9999,
    background: theme.color.white,
    ...theme.button.rotating,
    '& svg': {
      margin: -5,
    }
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'space-between',
    fontFamily: "'Ubuntu', sans-serif",
    '& svg': {
      color: theme.color.grayLine,
      flexShrink: 0,
    }
  },
  addressIcon: {
    width: 20,
    height: 32,
    '& path': {
      fill: theme.color.grayLine,
    }
  },
  workingHoursContent: {
    color: theme.color.grayText,
  },
  workingHours: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 35,
    justifyContent: 'space-between',
  },
  workingHoursLabel: {
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 14,
  },
  phoneLink: {
    ...theme.link.secondary,
    color: theme.color.primary,
    fontWeight: 400,
  },
  image: {
    '& img': {
      height: 'auto',
      position: 'none'
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.color.grayMask,
    fontFamily: "'Ubuntu', sans-serif",
  },
  information: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 0,
  },
  divContents: {
    '& > *': {
      display: 'contents',
    },
  },
}))

const ResellerFull: React.FC<Props> = ({ reseller, onClose }) => {
  const styles = useStyles();
  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
  const theme = useTheme() as VignonTheme;
  const dictionary = useLanguageStrings();
  return (
    <AnimatePresence>
      {reseller && (
        <motion.article
          className={styles.reseller}
          animate={{ x: 0, opacity: 1 }}
          initial={{ x: -600, opacity: 0, }}
          exit={{ x: -600, opacity: 0 }}
        >
          <IconButton
            onClick={onClose}
            className={styles.closeButton}
          >
            <AiFillCloseCircle size={35}/>
          </IconButton>
          <div className={styles.resellerContainer}>
            {reseller.field_revender_image && (
              <Swiper
                onSwiper={setSwiper}
              >
                {reseller.field_revender_image.map((image, index) => (
                  <SwiperSlide key={index}>
                    <Image
                      className={styles.image}
                      src={image.image_url}
                      alt={image.image_alt}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <div className={styles.titleNavigation}>
              <div className={styles.resellerBlockTitle}>
                <h3 className={styles.resellerTitle}>{reseller.title}</h3>
                <Markup className={styles.resellerCategory} html={reseller.field_revender_category} />
              </div>
              <SliderNavigation
                swiper={swiper}
                slides={reseller.field_revender_image?.length}
                className={styles.navigation}
                color={theme.color.grayLine}
              />
            </div>
            <div className={cn(styles.description, styles.contentBlock, styles.divContents)}>
              <span className={styles.title}>{dictionary.services_actives} : </span>
              <Markup html={reseller.field_revender_category} />
            </div>
            <div className={cn(styles.links, styles.contentBlock)}>
              { reseller.lat !== null && reseller.lng !== null &&
                <a className={styles.link} href={`https://www.google.com/maps/dir//${reseller.lat},${reseller.lng}`} target="_blank">
                  <RouteIcon />
                  <span className={styles.linkText}>{dictionary.routes}</span>
                </a>
              }
              { reseller.field_revender_website &&
                <a className={styles.link} href={reseller.field_revender_website} target="_blank">
                  <MouseIcon />
                  <span className={styles.linkText}>{dictionary.website}</span>
                </a>
              }
              { reseller.field_revender_email &&
                <a className={styles.link} href={`mailto:${reseller.field_revender_email}`} target="_blank">
                  <MailIcon />
                  <span className={styles.linkText}>{dictionary.contact}</span>
                </a>
              }
            </div>
            {(reseller.field_address_line_1 || reseller.field_address_line_2) && (
              <div className={cn(styles.address, styles.contentBlock)}>
                <div>
                  <Markup className={styles.addressLine} html={reseller.field_address_line_1} />
                  <Markup className={styles.addressLine} html={reseller.field_address_line_2} />
                </div>
                <MapDotIcon className={styles.addressIcon} />
              </div>
            )}
            {reseller.field_revender_working_hours && (
              <div className={cn(styles.address, styles.contentBlock)}>
                <div className={styles.workingHours}>
                  <div className={styles.workingHoursLabel}>{dictionary.hours}</div>
                  <Markup className={styles.workingHoursContent} html={reseller.field_revender_working_hours} />
                </div>
                <ClockIcon />
              </div>
            )}
            { reseller.field_revender_telephone &&
              <div className={cn(styles.address, styles.contentBlock)}>
                <a href={`tel:${reseller.field_revender_telephone}`} className={styles.phoneLink}>{reseller.field_revender_telephone}</a>
                <AiFillPhone size={26} />
              </div>
            }
            <div className={cn(styles.address, styles.contentBlock, styles.information)}>
              <div className={styles.title}>{dictionary.informations}</div>
              <Markup className={cn(styles.description, styles.contentBlock)} html={reseller.field_revender_description_2} />
            </div>
          </div>
        </motion.article>
      )}
    </AnimatePresence>
  )
}

export default ResellerFull;

