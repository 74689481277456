import React, { ElementType, HTMLAttributes, ReactElement } from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { color as themeColor, VignonTheme } from '../theme';

type Color = keyof typeof themeColor;

type Props<ButtonProps> = HTMLAttributes<HTMLOrSVGElement> & ButtonProps & {
  children?: React.ReactNode;
  filled?: boolean;
  color?: Color;
  icon?: ReactElement<SVGElement>;
  as?: ElementType;
  className?: string;
}

const useStyles = createUseThemedStyles(({
  button: ({ color, filled, theme }: { color: Color, filled: boolean, theme: VignonTheme }) => ({
    color: filled ? theme.color.white : theme.color[color],
    background: filled ? theme.color[color] : 'none',
    borderColor: theme.color[color],
    borderStyle: 'solid',
    borderRadius: 9999,
    borderWidth: 1.5,
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    textDecoration: 'none',
    outline: 'none',
    minHeight: 36,
    minWidth: 170,
    padding: [10, 10 , 10, 15],
    appearance: 'none',
    cursor: 'pointer',
    '& svg': {
      marginLeft: theme.spacing.medium
    }
  }),
}))

function Button<ButtonProps = HTMLAttributes<HTMLOrSVGElement>>({
  children,
  filled = false,
  color = "primary",
  icon,
  className,
  as: Tag = 'button',
  ...props
}: Props<ButtonProps>) {
  const styles = useStyles({ filled, color });
  return (
    <Tag className={`${styles.button}${className ? ` ${className}` : ''}`} {...props}>
      {children}
      {icon}
    </Tag>
  )
}

export default Button;
