import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { DrupalBreadcrumbs } from "../shared";

type BreadcrumbsContext = [DrupalBreadcrumbs, Dispatch<SetStateAction<DrupalBreadcrumbs>>];
type Props = { children: React.ReactNode; }

const defaultBreadcrumbsValue: BreadcrumbsContext = [[], () => null]
const BreadcrumbsContext = createContext<BreadcrumbsContext>(defaultBreadcrumbsValue);
const { Provider } = BreadcrumbsContext;

const BreadcrumbsProvider: React.FC<Props> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<DrupalBreadcrumbs>([]);
  return (
    <Provider value={[breadcrumbs, setBreadcrumbs]}>{children}</Provider>
  )
}

export const useBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
}

export const useSetBreadcrumbs = () => {
  const [,setBreadcrumbs] = useContext(BreadcrumbsContext);
  return setBreadcrumbs;
}

export default BreadcrumbsProvider;
