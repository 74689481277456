import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

export type HeaderTheme = 'light' | 'dark';
type HeaderThemeContext = [HeaderTheme, Dispatch<SetStateAction<HeaderTheme>>]
type Props = { children: React.ReactNode; }

const defaultHeaderTheme: HeaderTheme = 'dark'
const HeaderThemeContext = createContext<HeaderThemeContext>([defaultHeaderTheme, () => null]);
const { Provider } = HeaderThemeContext;

const HeaderThemeProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<HeaderTheme>(defaultHeaderTheme);

  return (
    <Provider value={[state, setState]}>{children}</Provider>
  )
}

export const useHeaderThemeContext = () => {
  return useContext(HeaderThemeContext);
}

export default HeaderThemeProvider;
