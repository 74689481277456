import { spacing } from './spacing';

export const list = {
  container: {
    display: 'flex',
    listStyle: 'none',
    padding: 0
  },
  item: {
    margin: `0 ${spacing.small}`
  }
};
