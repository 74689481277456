import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import PagePreloader from "../components/PagePreloader";

type Props = {
  children: React.ReactNode;
}

const useStyles = createUseThemedStyles((theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.fonts.base,
    color: theme.color.grayText
  },
  main: {
    flex: '1 0 100%',
    minHeight: '100vh',
    background: theme.color.grayBg,
  },
  '@global': {
    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].join(', ')]: {
      fontFamily: theme.fonts.heading
    },
    '.ios body.disabled': ({
      touchAction: 'none',
      WebkitOverflowScrolling: 'none',
      overflow: 'hidden',
    } as any),
    '.android body.disabled': {
      overscrollBehavior: 'none'
    },
    body: {
      background: theme.color.gray,
      fontSize: theme.fonts.baseSize,
    },
    'html': {
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    'img': {
      maxWidth: '100%',
    },
  },
}));

const Layout: React.FC<Props> = ({ children }) => {
  const styles = useStyles();
  return (
    <div className={styles.app}>
      <Header/>
      <main className={styles.main}>
        {children}
      </main>
      <Footer/>
    </div>
  )
}

export default Layout;
