import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Marker, Popup, useMap } from "react-leaflet";
import { Reseller } from "../../shared/resellers";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { Markup } from "../Typography";
import { Marker as LeafletMarker, Icon } from 'leaflet';
import dotIcon from "../../assets/map-dot-red.svg";
import { useIsDesktop } from "../../context/ScreenSize";

type Props = {
  reseller: Reseller;
  setActiveReseller: Dispatch<SetStateAction<string | null>>
  isActiveReseller: boolean
}

const useStyles = createUseThemedStyles(theme => ({
  marker: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontStyle: 'italic',
    fontSize: 27,
    margin: [0, 0, 20],
    fontWeight: 400,
  },
  description: {
    color: theme.color.primary,
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
  },
  button: {
    marginTop: 20,
    color: theme.color.primary,
  },
  popup: {
    '& .leaflet-popup-content-wrapper': {
      borderRadius: 0,
    }
  }
}))

const ResellerMarker: React.FC<Props> = ({ reseller, setActiveReseller, isActiveReseller }) => {
  const styles = useStyles();
  const [markerRef, setMarkerRef] = useState<LeafletMarker | null>(null);
  const map = useMap();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (markerRef) {
      const onMarkerClick = () => { setActiveReseller(reseller.id);};
      markerRef.on('click', onMarkerClick);

      return () => { markerRef.off('click', onMarkerClick); }
    }
  }, [markerRef, reseller]);

  useEffect(() => {
    if (map && isActiveReseller) {
      map.flyTo([Number(reseller.lat), Number(reseller.lng)], 13)
    }
  }, [reseller, isActiveReseller, map, isDesktop]);

  return (reseller.lat && reseller.lng) ? (
    <Marker
      icon={new Icon({
        iconUrl: dotIcon,
        iconSize: [25, 32],
        iconAnchor: [0, 0],
        popupAnchor: [0, 0],
      })}
      ref={ref => {setMarkerRef(ref)}} position={[Number(reseller.lat), Number(reseller.lng)]}
    >
      <Popup className={styles.popup}>
        <div className={styles.marker}>
          <h3 className={styles.title}>{reseller.title}</h3>
          <Markup className={styles.description} html={reseller.field_revender_description} />
        </div>
      </Popup>
    </Marker>
  ) : null;
}

export default ResellerMarker;
