import { LanguageStrings } from "./en";

export const fr: LanguageStrings = {
  "load.more": "En voir",
  "show.more": "voir plus",
  "show.less": "voir moins",
  articles: "articles",
  article: "article",
  ago: "ago",
  "page.not.found": "Oups! Page non trouvée!",
  "back.home": "Rentrer à la maison",
  homepage: "Accueil",
  access: "Accéder",
  "grape.varieties": "Cépages",
  vintages: "millésimes",
  note: "note",
  download: "Télécharger",
  aging_potential: "Potentiel de garde",
  suggestions: "Les suggestions",
  "from.your.wine.merchant": "de votre caviste",
  assemblage: "assemblage",
  terroir: "terroir",
  "vinification.aging": "vinification et élevage",
  "food.pairings": "Accords mets et vins",
  search: "recherche",
  "refine.by.category": "AFFINER PAR CATégorie",
  "our.clients": "Nos Clients",
  routes: "itinéraires",
  website: "website",
  contact: "contacter",
  hours: "horaires",
  select: "sélectionner",
  submit: "envoyer",
  "wine.warning":
    "L’abus d’alcool est dangereux pour la santé. A consommer avec modération",
  "accept.selection.button.text": "Accepter les cookies optionnels",
  "accept.all.button.text": "Accepter tous les cookies",
  "decline.all.button.text": "Refuser les cookies",
  "necessary.option.text": "Nécessaire",
  "preferences.option.text": "Préférences",
  "statistics.option.text": "Statistiques",
  "marketing.option.text": "Commercialisation",
  "message.cookie.banner":
    "Nous utilisons des cookies à des fins d’analyse et pour vous montrer des publicités et contenus adaptés à votre expérience de navigation. Vous pouvez accepter tous les cookies ou gérer vos préférences dans le panneau de configuration. Pour plus d’informations, consultez ",
  "message.newsletter.unique":
    "L’adresse email fait déjà partie des abonnés à la newsletter. <br> Pour vous inscrire, vous devez utiliser une autre adresse email.",
  "lien.achat": "Acheter",
  "footer.keep.contact": "Gardons le contact",
  ageModalTitle: "Nous sommes désolés",
  ageModalMessage: "Vous n'avez pas l'âge requis pour participer",
  press_kit: "DOSSIER de presse",
  "newsletter.title": "Inscrivez-vous à notre newsletter",
  "newsletter.desc":
    "Pour découvrir le monde de Xavier Vignon, ses dernières créations et ses actualités nous vous invitons à vous inscrire à notre newsletter.",
  services_actives: "Services disponibles",
  informations: "Informations supplémentaires",
  "message.policy.link":
    "la page Mentions légales - Données personnelles et cookies.",
  journal: "JOURNAL DU VIN",
  "age.modal.description":
    "Pour visiter notre site, vous devez être en âge de consommer de l'alcool dans votre pays/région. S'il n'y a pas d'âge légal de consommation, vous devez avoir plus de 21 ans.",
  "age.modal.title": "JE CERTIFIE AVOIR L’ÂGE LÉGAL",
  "age.modal.footer.terms":
    'En cliquant sur "valider", j\'accepte les <a href="/">Conditions Générales d\'Utilisation</a> et déclare avoir lu la <a href="/">Charte données personnelles et cookies</a>.',
  "age.modal.footer":
    "L'ABUS D'ALCOOL EST DANGEREUX POUR LA SANTÉ, À CONSOMMER AVEC MODÉRATION.",
  popup_category: "ESPACE DE VENTE & DEGUSTATION - EPICERIE FINE",
  no : "Non",
  yes : "Oui",
  visiter_age : "Vous ne pouvez visiter notre site, revenez quand vous aurez l’âge !"

};
