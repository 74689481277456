import React, { useState, useCallback, useEffect } from 'react';
import { headerHeightDesktop, headerHeightMobile } from "../shared/styles";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { GlassIcon, MapDotIcon, RaisinIcon } from "../components/icons";
import hexToRgba from 'hex-to-rgba';
import Burger from "../components/Burger";
import { useSiteSettingsContext } from "../context/SiteSetting";
import MainMenu from "../components/MainMenu";
import { useBreadcrumbs } from "../context/Breadcrumbs";
import BreadcrumbsComponent from "../components/Breadcrumb/Breadcrumb";
import { useIsDesktop } from "../context/ScreenSize";
import { useHeaderThemeContext, HeaderTheme } from "../context/HeaderThemeContext";
import cn from "classnames";
import Distribution from "../components/Distribution";
import Reservation from "../components/Reservation";
import {useLanguageContext} from "../i18n/components/LanguageProvider";
import { Link } from 'react-router-dom';
import Image from '../components/Image';
import {buildFullAssetUrl} from "../utils";
import IconButton from "../components/IconButton";
import {AiFillCloseCircle} from "react-icons/ai";
import { restApi } from '../api';

type Props = {

}

const logoWidthMobile = 22;

const logoWidth = 36;

const useStyles = createUseThemedStyles(theme => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: headerHeightMobile,
    zIndex: 10,
    transition: 'background .3s ease',
    background: ({ ht }: ({ ht: HeaderTheme })) => ht === 'light' ? theme.color.white : 'transparent',
    [theme.breakpoints.md]: {
      minHeight: headerHeightDesktop,
      background: ({ ht }: ({ ht: HeaderTheme })) => ht === 'light' ? theme.color.white : hexToRgba(theme.color.gray, 0.7),
    },
  },
  logo: {
    filter: ({ ht }: { ht: HeaderTheme }) => ht === 'light' ? 'invert(1)' : 'none',
    flex: `0 0 ${logoWidthMobile}px`,
    width: logoWidthMobile,
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.md]: {
      flex: `0 0 ${logoWidth}px`,
      width: logoWidth,
    },
    transition: 'filter .3s ease',
  },
  navSecondary: {
    flex: `1 0 calc(50% - ${logoWidthMobile / 2}px)`,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    [theme.breakpoints.md]: {
      flex: `1 0 calc(50% - ${logoWidth / 2}px)`,
    }
  },
  burger: {
    flex: `1 0 calc(50% - ${logoWidthMobile / 2}px)`,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.md]: {
      flex: `1 0 calc(50% - ${logoWidth / 2}px)`,
    }
  },
  container: {
    ...theme.container.base,
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity .3s ease',
  },
  containerReady: {
    opacity: 1,
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    '&:last-child': {
      '& svg': {
        marginRight: 0,
      }
    },

    '& svg': {
      height: 20,
      width: 14,
      margin: [0, 14],
      [theme.breakpoints.md]: {
        margin: [0, theme.spacing.medium],
      },
      '& path': {
        transition: 'fill .3s ease',
        fill: ({ ht }: { ht: HeaderTheme }) => ht === 'light' ? theme.color.black : theme.color.white,
      }
    },
    '&$navItemActive': {
      '& $link': {
        color: theme.color.primary
      },
      '& svg': {
        '& path': {
          fill: theme.color.primary,
        }
      }
    }
  },
  navItemActive: {},
  link: {
    padding: 0,
    ...theme.link.primary,
    background: 'none',
    appearance: 'none',
    border: 'none',
    fontSize: 0,
    display: 'flex',
    alignItems: 'center',
    color: ({ ht }: { ht: HeaderTheme }) => ht === 'light' ? theme.color.black : theme.color.white,
    transition: 'color .3s ease',
    [theme.breakpoints.md]: {
      fontSize: 11,
    },
  },
}));

const Header: React.FC<Props> = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [headerTheme] = useHeaderThemeContext();
  const styles = useStyles({ menuActive, ht: headerTheme });
  const { loading, logo } = useSiteSettingsContext();
  const url = logo?.file?.data.attributes.uri.url;
  const [breadcrumbs] = useBreadcrumbs();
  const isDesktop = useIsDesktop();
  const [distributionActive, setDistributionActive] = useState<boolean>(false);
  const [reservationActive, setReservationActive] = useState<boolean>(false);
  const lang = useLanguageContext();
  const handleDistributionClose = useCallback(() => setDistributionActive(false), []);
  const [url_wineclub, setUrl_wineclub] = useState('');

  const fetchUrlWineclub = useCallback(async () => {
    try {
      const { link } = await restApi<any>('node/link');
      setUrl_wineclub(link);
    } catch (error) {
      console.log('Error al cargar el enlace de wineclub');
    }
  }, [])

  useEffect(() => {
    fetchUrlWineclub();
  }, [])

  return (
    <>
      <MainMenu active={menuActive} setMenuActive={setMenuActive} />
      <Distribution active={distributionActive} close={handleDistributionClose} />
      <Reservation active={reservationActive} setActive={setReservationActive} />
      <header className={styles.header}>
        <div className={`${styles.container}${!loading ? ` ${styles.containerReady}` : ''}`}>
          <div className={styles.burger}>
            <Burger color={headerTheme === 'dark' ? 'white' : 'black'} onClick={() => setMenuActive(active => !active)} />
            {isDesktop && <BreadcrumbsComponent items={breadcrumbs} />}
          </div>
          <div className={styles.logo}>
            {url ? <Link to={"/" + lang}><Image src={buildFullAssetUrl(url)} alt={logo?.alt} /></Link> : null}
          </div>
          <ul className={styles.navSecondary}>
            <li
              className={cn(styles.navItem, reservationActive && styles.navItemActive)}
              onClick={() => {
                setReservationActive(active => !active);
                setDistributionActive(false);
              }}
            >
              <button className={styles.link}>
              Oenotourisme</button><RaisinIcon style={{ width: '20px', margin: '0 0.75rem' }} />
            </li>
            <li
              className={cn(styles.navItem)}
              onClick={() => {
                window.open(url_wineclub, '_blank')
              }}
            >
              <button className={styles.link}>Wineclub</button><GlassIcon />
            </li>
            <li
              className={cn(styles.navItem, distributionActive && styles.navItemActive)}
              onClick={() => {
                setDistributionActive(active => !active);
                setReservationActive(false);
              }}
            >
              <button className={styles.link}>DIStribution</button><MapDotIcon />
            </li>
          </ul>
        </div>
      </header>
    </>
  )
}

export default Header;
