import React from 'react';
import { Reseller, ResellerImage } from "../../shared/resellers";
import Image from "../Image";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { MapDotIcon } from "../icons";
import { Markup } from "../Typography";

type Props = {
  reseller: Reseller;
  onClick: (reseller: Reseller) => void;
}

const useStyles = createUseThemedStyles(theme => ({
  reseller: {
    marginBottom: 50,
    cursor: 'pointer',
  },
  resellerImage: {
    width: '100%',
  },
  resellerContent: {
    background: theme.color.grayBg,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    padding: [24, 24, 40],
  },
  resellerTitle: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontStyle: 'italic',
    fontSize: 27,
    margin: 0,
    fontWeight: 400,
  },
  resellerCategory: {
    color: theme.color.primary,
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
  },
  resellerVille: {
    color: theme.color.grayText,
    fontSize: 12,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing.medium,
      width: 10,
      '& path': {
        fill: theme.color.grayText,
      }
    }
  }
}))

const ResellerPreviewBig: React.FC<Props> = ({ reseller, onClick }) => {
  const image = (reseller.field_revender_image?.length ? reseller.field_revender_image[0] : {}) as ResellerImage;
  const styles = useStyles();
  return (
    <article className={styles.reseller} onClick={() => onClick(reseller)}>
      <Image className={styles.resellerImage} src={image.image_url} alt={image.image_alt} />
      <div className={styles.resellerContent}>
        <div className={styles.resellerBlockTitle}>
          <h3 className={styles.resellerTitle}>{reseller.title}</h3>
          <Markup className={styles.resellerCategory} html={reseller.field_revender_category} />
        </div>
        <div className={styles.resellerVille}>
          {reseller.field_revender_ville}
          <MapDotIcon />
        </div>
      </div>
    </article>
  )
}

export default ResellerPreviewBig;

