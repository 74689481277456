import React, { useEffect, useMemo, useState } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { AnimatePresence, motion } from "framer-motion";
import useDisableBody from "../../hooks/useDisableBody";
import { headerHeightDesktop, headerHeightMobile } from "../../shared/styles";
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import hexToRgba from "hex-to-rgba";
import { useLanguageContext } from "../../i18n/components/LanguageProvider";
import { Reseller, ResellersRestResponse } from "../../shared/resellers";
import { getResellers } from "../../api";
import Aside from "./Aside";
import ResellerFull from "./ResllerFull";
import UserLocation from "./UserLocation";
import L from 'leaflet';
import ResellerMarker from "./ResellerMarker";
import { defaultLocation } from "../../shared/location";
import IconButton from "../IconButton";
import {AiFillCloseCircle} from "react-icons/ai";

delete (L as any).Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const useStyles = createUseThemedStyles(theme => ({
  distribution: {
    position: 'fixed',
    top: headerHeightMobile,
    left: 0,
    width: '100vw',
    height: `calc(var(--app-height) - ${headerHeightMobile}px)`,
    overflow: 'auto',
    zIndex: 14,
    transformOrigin: 'top',
    background: theme.color.gray,
    [theme.breakpoints.md]: {
      height: `calc(var(--app-height) - ${headerHeightDesktop}px)`,
      top: headerHeightDesktop,
    }
  },
  content: {
    height: '100%',
    position: 'relative',
  },
  map: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 4,
  },
  search: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 480,
    zIndex: 5,
    background: hexToRgba(theme.color.white, 0.9),
  },
  closeBlock: {
    display: 'block',
    alignItems: 'center',
    position: 'absolute',
    right: 20,
    top: 20,
    zIndex: 999,
  },
}));

const resellersDefaultState = {
  categories: [],
  countries: [],
  revenders: [],
}

type Props = {
  active?: boolean;
  close: () => void;
}

const Distribution: React.FC<Props> = ({ active = false, close }) => {
  const styles = useStyles();
  const language = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [resellerCountry, setResellerCountry] = useState<string>(language);
  const [resellers, setResellers] = useState<ResellersRestResponse["data"]>(resellersDefaultState);
  const [queryString, setQueryString] = useState('');
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [activeReseller, setActiveReseller] = useState<string | null>(null);
  const { countries, categories, revenders } = resellers;

  useDisableBody(active);

  useEffect(() => {
    setLoading(true);
    getResellers(resellerCountry)
      .then(response => {
        response.data.countries = [
          response.data.countries.find(c => c.code === 'FR')!,
          ...response.data.countries.filter(c => c.code !== 'FR'),
        ]
        setResellers(response.data)
      })
      .catch(console.warn)
      .finally(() => setLoading(false));
  }, [resellerCountry]);

  const filteredRevenders = useMemo<Array<Reseller>>(
    () => {
      return revenders.filter(
        r =>
          (queryString.length > 3 ? (
            r.title?.toLowerCase().includes(queryString.toLowerCase()) ||
            r.field_revender_ville?.toLowerCase().includes(queryString.toLowerCase())) : true)
          &&
          (categoryId ? r.field_revender_category_id === categoryId : true),
      );
    },
    [queryString, categoryId, revenders]
  );

  return (
    <AnimatePresence>
      {active && (
        <motion.nav
          className={styles.distribution}
          animate={{ scaleY: 1 }}
          initial={{ scaleY: 0 }}
          exit={{ scaleY: 0, transition: { delay: 0.1 } }}
        >
          <motion.section
            animate={{ opacity: 1, transition: { delay: 0.2 } }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
            className={styles.content}
          >
            <MapContainer
              className={styles.map}
              // TODO default position
              center={defaultLocation}
              zoom={9}
              scrollWheelZoom={false}
              zoomControl={false}
            >
              <div className={styles.closeBlock}>
                    <IconButton
                      onClick={close}
                      className={styles.closeButton}
                    >
                      <AiFillCloseCircle size={35}/>
                    </IconButton>
                  </div>
              {filteredRevenders.map(reseller => (
                <ResellerMarker
                  reseller={reseller}
                  key={reseller.id}
                  setActiveReseller={setActiveReseller}
                  isActiveReseller={activeReseller === reseller.id}
                />
              ))}
              <UserLocation location={currentLocation}/>
              <ZoomControl position="bottomright"/>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
            <Aside
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              setCurrentLocation={setCurrentLocation}
              country={resellerCountry}
              queryString={queryString}
              setQueryString={setQueryString}
              countries={countries}
              categories={categories}
              revenders={filteredRevenders}
              loading={loading}
              setCountry={setResellerCountry}
              setActiveReseller={setActiveReseller}
              hidden={!!activeReseller}
            />
            <ResellerFull
              reseller={resellers.revenders.find(r => r.id === activeReseller)}
              onClose={() => setActiveReseller(null)}
            />
          </motion.section>
        </motion.nav>
      )}
    </AnimatePresence>
  );
}

export default Distribution;
