import React from 'react';
import { LocalizedRouter } from "./i18n/components/LocalizedRouter";
import { BrowserRouter } from "react-router-dom";
import { AppLanguage } from "./shared/local";
import DecoupledSwitch from "./router/DecoupledSwitch";
import Layout from "./layout/Layout";
import { ThemeProvider } from "react-jss";
import * as theme from './theme';
import 'normalize.css';
import SiteSettingsProvider from "./context/SiteSetting";
import BreadcrumbsProvider from "./context/Breadcrumbs";
import { HelmetProvider } from 'react-helmet-async';
import AlternateLinksProvider from "./context/AlternateLinks";
import ScreenSizeProvider from "./context/ScreenSize";
import HeaderThemeProvider from "./context/HeaderThemeContext";
import GDPRBanner from "./components/CookieBanner/GDPRCookie";
import AgeCookie from './components/CookieBanner/AgeCookie';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <LocalizedRouter RouterComponent={BrowserRouter} languages={AppLanguage}>
          <ScreenSizeProvider>
            <HeaderThemeProvider>
              <BreadcrumbsProvider>
                <AlternateLinksProvider>
                  <SiteSettingsProvider>
                    <Layout>
                      <AgeCookie />
                      <GDPRBanner />
                      <DecoupledSwitch />
                    </Layout>
                  </SiteSettingsProvider>
                </AlternateLinksProvider>
              </BreadcrumbsProvider>
            </HeaderThemeProvider>
          </ScreenSizeProvider>
        </LocalizedRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
