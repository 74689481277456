import { api } from "./api";
import drupalBaseEndpoint, { drupalBaseRestApi } from "./base";
import { getApiLanguage } from "./i18n";
import { AppLanguage } from "../shared/local";
import { ResellersRestResponse } from "../shared/resellers";

export const restApi = <T>(url: string) => api<T>(`${drupalBaseEndpoint}/${getApiLanguage()}/${drupalBaseRestApi}/${url}`);

export const getPage = <T>({ type, bundle, id }: { type?: string; bundle?: string; id?: string; query?: string }, search?: string) => restApi<T>(`${type}/${bundle}/${id}${search ? `/${search}` : ''}`);
export const getView = <T>({ type, id }: { type?: string; id?: string }) => restApi<T>(`${type}/${id}`);
export const getResellers = (language: string) => restApi<ResellersRestResponse>(`revenders_block/${language}`)
