import React, { useMemo } from "react";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import drupalBaseEndpoint from "../../api/base";
import 'wysiwyg.css';
import './align.wysiwyg.css';

type TagNames = keyof JSX.IntrinsicElements;

type ElementProps = {
  tag?: TagNames,
  children?: React.ReactNode,
} & JSX.IntrinsicElements[TagNames];

type lineOption = 'top' | 'left'

const useStyles = createUseThemedStyles(theme => ({
  titleWithLine: {
    color: theme.color.primary,
    position: 'relative',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 500,
    paddingTop: ({ linePosition }: { linePosition: lineOption }) => linePosition === "top" ? 22 : 0,
    '&:before': {
      content: '""',
      height: 2,
      width: '1rem',
      background: theme.color.primary,
      position: 'absolute',
      top: ({ linePosition }: { linePosition: lineOption }) => linePosition === "top" ? 0 : '50%',
      left: ({ linePosition }: { linePosition: lineOption }) => linePosition === "top" ? 0 : -40,
      transform: ({ linePosition }: { linePosition: lineOption }) => linePosition === "top" ? undefined : 'translateY(-1px)',
      [theme.breakpoints.md]: {
        top: '50% !important',
        left: '-50px !important',
        transform: 'translateY(-1px) !important',
      }
    },
    [theme.breakpoints.md]: {
      fontSize: 16,
      paddingTop: '0 !important',
    },
  },
  heading: {
    fontFamily: theme.fonts.heading,
    fontWeight: 400,
    fontStyle: 'italic',
  },
  headingWithLine: {
    position: 'relative',
    margin: 0,
    fontFamily: theme.fonts.base,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 25,
    lineHeight: '29px',
    textTransform: 'uppercase',
    paddingTop: theme.spacing.large,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 9,
      height: 2,
      background: 'currentColor',
    }
  },
  wysiwyg: {
    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].join(', ')]: {
      fontFamily: theme.fonts.heading,
      fontWeight: 400,
      fontStyle: 'italic',
    },
    '& > *:first-child': {
      marginTop: 0,
      '& > *:first-child': {
        marginTop: 0,
      }
    },
    lineHeight: '22px',
    fontWeight: 400,
    fontSize: '14px',
  },
}));

export const TitleWithLine: React.FC<ElementProps & { linePosition?: lineOption }> = ({ tag: Wrapper = 'div', linePosition = 'top', className, children, ...props }) => {
  const styles = useStyles({ linePosition });

  return (
    <Wrapper className={`${styles.titleWithLine}${className ? ` ${className}` : ''}`} {...props as any}>
      {children}
    </Wrapper>
  )
}

export const Heading: React.FC<ElementProps> = ({ tag: Wrapper = 'h1', className, children, ...props }) => {
  const styles = useStyles();

  return (
    <Wrapper className={`${styles.heading}${className ? ` ${className}` : ''}`} {...props as any}>
      {children}
    </Wrapper>
  )
}

export const Markup: React.FC<{ html?: string, className?: string }> = ({ html = '', className }) => {
  const styles = useStyles();
  const processed = useMemo(() => html?.replaceAll('src="/sites/default', `src="${drupalBaseEndpoint}/sites/default`), [html])
  return <div className={`wysiwyg ${styles.wysiwyg}${className ? ` ${className}` : ''}`}
              dangerouslySetInnerHTML={{ __html: processed }}/>
}

export const HeadingWithLine: React.FC<ElementProps> = ({ tag: Wrapper = 'h1', className, children, ...props }) => {
  const styles = useStyles();

  return (
    <Wrapper className={`${styles.headingWithLine}${className ? ` ${className}` : ''}`} {...props as any}>
      {children}
    </Wrapper>
  )
}
