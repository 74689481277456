import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Overlay from "./overlay";
import Button from "./button";
import { getModalRoot } from './utils';
import { motion } from 'framer-motion';
import { useIsDesktop } from "../../context/ScreenSize";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import useDisableBody from "../../hooks/useDisableBody";
import cn from 'classnames';

const modalVariants = {
	isOpen: {
		scaleY: 1,
		scaleX: 1,
		y: 0,
		opacity: 1,
		transition: {
			scaleY: {
				duration: 0.3,
			},
			scaleX: {
				duration: 0.3,
				delay: 0.3,
			}
		}
	},
	isHidden: {
		scaleY: 0,
		scaleX: 0.02,
		y: 100,
		opacity: 0,
		transition: {
			y: {
				duration: 0.2,
				ease: 'easeIn'
			},
			opacity: {
				duration:  0.2,
				ease: 'linear',
			},
			scaleY: {
				duration: 0,
				delay:  0.2,
			},
			scaleX: {
				duration: 0,
				delay:  0.2,
			}
		}
	}
};

const bodyVariants = {
	isOpen: {
		opacity: 1,
		transition: {
			default: {
				delay: 0.6,
				duration: 0.4,
			}
		}
	},
	isHidden: {
		opacity: 0,
	}
}

const useStyles = createUseThemedStyles(theme => ({
  modal: {
    padding: 50,
    position: 'relative',
    backgroundColor: theme.color.gray,
    minHeight: 200,
    minWidth: 300,
    maxWidth: '90vw',
    maxHeight: '100vh',
    overflow: 'auto'
  }
}));

type Props = {
  children: React.ReactNode;
  handleClose?: () => void;
  isOpen?: boolean;
  renderInPortal?: boolean;
  className?: string;
  permanent?: boolean;
}

const Modal: React.FC<Props> = ({ children, handleClose = () => null, isOpen = false, renderInPortal = true, className, permanent = false }) => {
	const modalRoot = useRef(getModalRoot());
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [isVisible, setIsVisible] = useState(isOpen);
	const [isHiding, setIsHiding] = useState(false);
	const isDesktop = useIsDesktop();
  const styles = useStyles();

  useDisableBody(isOpen);

	const handleCloseClick = useCallback((e?: React.SyntheticEvent) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
		setIsHiding(true);
		setIsVisible(false);
		timeoutRef.current = setTimeout(() => {
			handleClose();
			setIsHiding(false);
			if (!isDesktop) { ; }
		}, 250);
	}, [handleClose, setIsHiding]);

	useEffect(() => {
		if (isOpen && !isVisible && !isHiding) {
			setIsVisible(true);
		} else if (!isOpen && isVisible && !isHiding) {
			handleCloseClick();
		}
	} , [isOpen, isVisible, isHiding, setIsVisible, handleCloseClick]);

	if (typeof window !== 'undefined') {
		const component = (
			<Overlay
				active={isVisible}
				onClick={() => !permanent ? handleCloseClick() : null}
			>
				<motion.div
					onClick={e => {
						e.stopPropagation();
					}}
          className={cn(styles.modal, className)}
          variants={modalVariants}
          animate={isVisible ? 'isOpen' : 'isHidden'}
          initial={'isHidden'}
				>
					<Button onClick={() => !permanent ? handleCloseClick() : null}/>
					<motion.div
            variants={bodyVariants}
            animate={isVisible ? 'isOpen' : 'isHidden'}
            initial={'isHidden'}
            exit={"isHidden"}
          >
						{children}
					</motion.div>
				</motion.div>
			</Overlay>
		);

		return renderInPortal ?
			ReactDOM.createPortal(component, modalRoot.current) :
			component;
	}
	return null;
};

export default Modal;
