import { useState, useEffect } from 'react';
import { formToJSON } from '../formToJSON';
import { FormElement } from "../../../../shared/form";
import { omit } from "../../../../utils";


// Re-export
export { formToJSON };

export const HTML_ATTRIBUTE_WHITELIST = ['required', 'defaultValue', 'disabled', 'readonly', 'placeholder', 'multiple'];
export const INPUT_TYPES_WHITELIST = ['button', 'checkbox', 'file','hidden','image','password','radio','reset','submit','text'];

/**
 * Check if form state conditions are true.
 *
 * @param element controlling element
 * @param state webform state
 *
 * @return true if element passes all conditions
 */
function checkConditions(element: HTMLFormElement, conditions: any) {
	return Object.entries(conditions).every(([condition, value]) => {
		// Ignore conditions that are not defined for this state.
		if (value == null) {
			return true
		}

		// Check condition:
		switch (condition) {
			case 'checked':
				return element.checked === true;
			case 'unchecked':
				return element.checked === false;
			case 'empty':
				return element.value === '';
			case 'filled':
				return element.value !== '';
			default:
				return false
		}
	})
}

/**
 * Attempt to guess initial state.
 *
 * This is to have something for the initial render. This is an attempt to
 * reduce flickering and elements jumping around.
 *
 * Guesses:
 *  - If hidden|visible states are present assume they are used to initally hide.
 *
 * @param states
 */
function guessInitialState(states: any) {
	const initialState: any = {};

	for (const state of states) {
		switch (state.state) {
			case 'hidden':
				initialState[state.state] = true;
				break;
			default:
				initialState[state.state] = false;
		}
	}

	return initialState
}

function handleOnChange(state: any, setState: any, event: any) {
	const { state: stateName, condition } = state;
	const element = event.currentTarget;

	setState((prevState: any) => ({
		...prevState,

		[stateName]: checkConditions(element, condition)
	}))
}

function useWebformStates(webformStates: any) {
	const [states, setStates] = useState(guessInitialState(webformStates));

	useEffect(() => {
		const initialState: { [key: string]: any } = {};

		// Keep track of all elements and their callbacks so they can be cleaned.
		const callbacks: any[] = [];

		// This loop does nothing if webformStates is empty.
		for (const state of webformStates) {
			// Webform uses jQuery selector that are prefixed with ':input'.
			// Strip leading ':' and hope it will not break horribly. :)
			// It will not work with textarea or select...
			const selector = state.selector.substr(1);
			const element = document.querySelector(selector);

			if (element) {
				// Compute initial value for each state.
				initialState[state.state] = checkConditions(element, state.condition);

				const callback = handleOnChange.bind(null, state, setStates);

				// Setup listener for state change.
				element.addEventListener('change', callback);

				callbacks.push([element, callback])
			}
		}

		// Set calculated initial state.
		if (Object.entries(initialState).length > 0) {
			setStates(initialState);
		}

		return () => {
			// Remove all callbacks.
			for (const [element, callback] of callbacks) {
				element.removeEventListener('change', callback);
			}
		}
		// eslint-disable-next-line
	}, webformStates);

	return states
}

/**
 * Return true if attribute should be spred to <input /> elements.
 *
 * @param attribute attribute name
 */
function isHTMLAttribute(attribute: string) {
	// Compare attribute name against whitelist.
	return HTML_ATTRIBUTE_WHITELIST.includes(attribute);
}

/**
 * Transform webform attribute name
 *
 * @param attribute attribute name
 */
function transformAttributeName(attribute: string) {
	// Handle special cases in a switch statement.
	switch (attribute) {
		case '#readonly':
			return 'readOnly';
		case '#default_value':
			return 'defaultValue';
		// Remove leading '#':
		default:
			return attribute.substr(1)
	}
}

/**
 * Transform WebformElement
 *
 * @param element webform element
 * @param options custom properties
 */
export function useWebformElement (element: FormElement, options: any) {
	const states = useWebformStates(element.states || []);

	const inputProps: { [key: string]: any } = {};
	const attributes: { [key: string]: any } = {};

	/**
	 * This will parse webform attributes and return object containing
	 * the props that you can spread over the <input /> element and other
	 * unrecognized attributes.
	 */
	element.attributes.forEach(attribute => {
		const transformedAttribute = transformAttributeName(attribute.name);

		if (isHTMLAttribute(transformedAttribute)) {
			inputProps[transformedAttribute] = attribute.value
		}

		attributes[transformedAttribute] = attribute.value
	});

	// Convert inputProps to correct type.
	Object.assign(inputProps, {
		required: inputProps.required === '1' || inputProps.required === 'true'
	});

	if (options) {
		// Overwrite inputProps with option props.
		Object.assign(inputProps, options)
	}

	return [inputProps, { attributes, states, pure: !!element.pure }]
}

export function getOptionId(name: string, value: string) {
	return `form-${name.replace('_', '-')}-${value.toLowerCase().replace('_', '-')}`
}

/**
 * Generate id for form element
 *
 * @param name element name
 */
export function getElementId(name: string) {
	return `form-${name.replace('_', '-')}`;
}

/**
 * Get and return attribute from array of WebformAttributes.
 *
 * @param attributeName name of the attribute to find.
 * @param attributes list of attributes to search.
 * @returns value of attribute if found or undefined.
 */
export function getAttributeValue(attributeName: string, element: FormElement) {
	// Find desired attribte
	const attribute = element?.attributes?.find(attribute => attribute.name === attributeName);

	if (attribute) {
		return attribute.value
	}

	return undefined
}
