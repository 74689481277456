export function getCookie(query: string): string | undefined {
  const cookie: { [key: string]: string } = {};

  if (!query) return

  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');

    cookie[key.trim()] = value;
  });

  return cookie[query];
}

export function setCookie(name: string, value: string, hours: number = 12) {
  const exdate = new Date();

  exdate.setMilliseconds(exdate.getMilliseconds() + (1000 * 60 * 60 * hours));

  const cValue = encodeURI(value) + ((hours === null) ? '' : `; expires=${exdate.toUTCString()}`);

  document.cookie = `${name} = ${cValue}`;
}

export function removeCookie(key: string) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}
