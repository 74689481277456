/**
 * @file Utils
 * @see: https://lengstorf.com/code/get-form-values-as-json/
 */

import { FormDataType } from "./types";

/**
 * Checks that an element has a non-empty `name` and `value` property.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is an input, false if not
 */
const isValidElement = (element: HTMLFormElement) => {
	return !!(element.name && element.value)
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = (element: HTMLFormElement) => {
	return !['checkbox', 'radio'].includes(element.type) || element.checked
};

/**
 * Checks if an input is a checkbox, because checkboxes allow multiple values.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a checkbox, false if not
 */
const isCheckbox = (element: HTMLFormElement) => element.type === 'checkbox';

/**
 * Checks if an input is a `select` with the `multiple` attribute.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a multiselect, false if not
 */
const isMultiSelect = (element: HTMLFormElement) => element.options && element.multiple;

const isFile = (element: HTMLFormElement) => element.files && element.files.length;

/**
 * Retrieves the selected options from a multi-select as an array.
 * @param  {HTMLOptionsCollection} options  the options for the select
 * @return {Array}                          an array of selected option values
 */
const getSelectValues = (options: HTMLOptionsCollection) =>
	[].reduce.call(
		options,
		(values: any, option: HTMLOptionElement) => (option.selected ? values.concat(option.value) : values),
		[]
	);

const getFileValues = async (files: any[]) => await toBase64(files[0]);

const getFileNames = (files: any[]) => files[0].name;

const toBase64 = (file: any) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
export const formToJSON = async (elements: HTMLFormControlsCollection) => {
	const data: FormDataType = {};
	// @ts-ignore
  for (const element of elements) {
		if (isValidElement(element) && isValidValue(element)) {
			/*
			 * Some fields allow for more than one value, so we need to check if this
			 * is one of those fields and, if so, store the values as an array.
			 */
			if (isCheckbox(element)) {
				data[element.name] = (data[element.name] || []).concat(element.value)
			} else if (isMultiSelect(element)) {
				data[element.name] = getSelectValues(element.options)
			} else if (isFile(element)) {
				data[element.name] = {
					file: {
						file_name: getFileNames(element.files),
						file_data: await getFileValues(element.files)
					}
				}
			} else {
				data[element.name] = element.value
			}
		}
	}
	return data;
};
