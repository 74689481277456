import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import useWebForm from '../../hooks/useWebForm';
import { getReservationForm } from '../../api/form';
import { AnimatePresence, motion } from 'framer-motion';
import 'leaflet/dist/leaflet.css';
import IconButton from '../IconButton';
import { AiFillCloseCircle, AiOutlineLeftCircle } from 'react-icons/ai';
import SocialsAside from '../SocialsAside';
import barrels from '../../assets/barrels-2.png';
import { SubmitFormSuccessResponse, WebForm } from '../../shared/form';
import ReservationFormPage from './ReservationFormPage';
import ReservationSelectPage from './ReservationSelectPage';
import Modal from '../Modal';

type Props = {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const useStyles = createUseThemedStyles((theme) => ({
  distribution: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: `var(--app-height)`,
    zIndex: 14,
    transformOrigin: 'top',
    background: theme.color.gray,
    overflowY: 'auto',
    overflowx: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.large,
    right: theme.spacing.large,
    color: theme.color.white,
    ...theme.button.rotating,
    zIndex: 1,
  },
  wrapper: {
    ...theme.container.base,
    minHeight: '100vh',
    position: 'relative',
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.md]: {
      height: '100%',
      padding: 0,
    },
  },
  content: {
    paddingTop: 146,
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.md]: {
      paddingLeft: '35%',
      height: '100%',
      overflowY: 'auto',
    },
  },
  contentScroll: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.md]: {
      minHeight: '100%',
      overflow: 'hidden',
    },
  },
  navTertiary: {
    position: 'absolute',
    color: theme.color.white,
    top: 80,
    right: theme.spacing.large,
    listStyle: 'none',
    fontSize: 14,
    textTransform: 'uppercase',
    zIndex: 1,
    '& > li': {
      cursor: 'pointer',
      verticalAlign: 'center',
      lineHeight: '23px',
      position: 'relative',
      padding: '0 5px 0 40px',
    },
  },
  navIcon: {
    position: 'absolute',
    left: 0,
  },
  success: {
    color: theme.color.white,
    fontFamily: theme.fonts.heading,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    [theme.breakpoints.md]: {
      minWidth: 500,
      minHeight: 300,
    },
  },
  successTitle: {
    fontSize: 25,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    textTransform: 'uppercase',
    [theme.breakpoints.md]: {
      fontSize: 32,
      marginBottom: theme.spacing.large,
    },
  },
  successDescription: {
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.md]: {
      fontSize: 20,
    },
  },
}));

//TODO: Hardcoded default value for particular field of particular form
const setSelectDefaultValue = (form: WebForm | null, optionIndex: number | undefined) => {
  const FORM_FIELD_INDEX = 2;
  const defaultValue = (optionIndex !== undefined) ?
    form?.attributes.elements?.at(FORM_FIELD_INDEX)?.options?.at(optionIndex) :
    undefined;
  return form ? {
    ...form,
    attributes: {
      ...form.attributes,
      elements: defaultValue ?
        form.attributes.elements.map((el, i) => (i === FORM_FIELD_INDEX ? ({...el, defaultValue}) : el)) :
        form.attributes.elements,
    },
  } : null;
};

const Reservation: React.FC<Props> = ({ active = false, setActive }) => {
  const styles = useStyles();
  const reservationForm = useWebForm(getReservationForm);
  const [success, setSuccess] = useState<SubmitFormSuccessResponse | null>(null);
  const [selected, setSelected] = useState<number>();

  const handleSuccess = (response: SubmitFormSuccessResponse) => {
    setActive(false);
    setSuccess(response);
  };

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const handleReset = () => {
    setSelected(undefined);
  };

  return (
    <>
      <Modal isOpen={!!success} handleClose={() => setSuccess(null)}>
        <div className={styles.success}>
          <h3 className={styles.successTitle}>{success?.success_title}</h3>
          <p className={styles.successDescription}>
            {success?.success_message}
          </p>
        </div>
      </Modal>
      <AnimatePresence>
        {active && (
          <motion.nav
            className={styles.distribution}
            animate={{ scaleY: 1 }}
            initial={{ scaleY: 0 }}
            exit={{ scaleY: 0, transition: { delay: 0.1 } }}
          >
            <motion.section
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
              className={styles.wrapper}
            >
              <div className={styles.content}>
                <div className={styles.contentScroll}>
                <IconButton
                  onClick={() => setActive(false)}
                  className={styles.closeButton}
                >
                  <AiFillCloseCircle size={35} />
                </IconButton>
                {selected !== undefined && (
                  <ul className={styles.navTertiary}>
                    <li onClick={handleReset}>
                      <span className={styles.navIcon}>
                        <AiOutlineLeftCircle size={23} />
                      </span>
                      Retour
                    </li>
                  </ul>
                )}
                {selected !== undefined ?
                  <ReservationFormPage
                    form={setSelectDefaultValue(reservationForm, selected)}
                    onSubmitSuccess={handleSuccess}
                  /> :
                  <ReservationSelectPage onSelect={handleSelect} />
                }
                </div>
              </div>
              <SocialsAside bg={barrels} position='left' />
            </motion.section>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default Reservation;
