import React from 'react'
import { getElementId, useWebformElement } from '../utils'

import WebformElementWrapper from './WebformElementWrapper'
import { FormComponentType } from "../types";
import { createUseThemedStyles } from "../../../../hooks/createUseThemedStyles";
import { Color } from "../../../../theme";

const useStyles = createUseThemedStyles(theme => ({
  textarea: {
    background: '#fff',
    color: ({ color }: { color: Color }) => theme.color[color],
    border: 'none',
    paddingLeft: 20,
    fontSize: 16,
    outline: 'none',
    paddingTop: 20,
    resize: 'none',
    '&::placeholder': {
      height: 60,
      opacity: 0.5,
      textTransform: 'capitalize',
    }
  }
}))

export const WebformTextarea: FormComponentType = ({ element, error, color }) => {
	const id = getElementId(element.name);
	const [inputProps, settings] = useWebformElement(element, {
		name: element.name,
		id,
	});

  const styles = useStyles();

	return (
		<WebformElementWrapper settings={settings} error={error} labelFor={id} color={color} columns={1}>
			<textarea rows={5} className={styles.textarea} {...inputProps} />
		</WebformElementWrapper>
	)
};

export default WebformTextarea
