import React from 'react';
import { getElementId, useWebformElement } from '../utils';

import WebformElementWrapper from './WebformElementWrapper';
import { INPUT_TYPES_WHITELIST } from '../utils';
import { FormComponentType } from "../types";
import { createUseThemedStyles } from "../../../../hooks/createUseThemedStyles";
import { Color } from "../../../../theme";

const useStyles = createUseThemedStyles(theme => ({
  input: {
    height: 60,
    lineHeight: '60px',
    background: '#fff',
    color: ({ color }: { color: Color }) => theme.color[color],
    border: 'none',
    paddingLeft: 20,
    fontSize: 16,
    outline: 'none',
    '&::placeholder': {
      height: 60,
      lineHeight: 60,
      opacity: 0.5,
      textTransform: 'capitalize',
    }
  }
}))

export const WebformInput: FormComponentType = ({ element, error, color, columns }) => {
	const id = getElementId(element.name);
  const styles = useStyles({ color });
	const [inputProps, settings] = useWebformElement(element, {
		name: element.name,
		type: INPUT_TYPES_WHITELIST.includes(element.type) ? element.type : 'text',
		id
	});

	if (element.type === 'hidden') {
		return <input {...inputProps} />
	}

	return (
		<WebformElementWrapper settings={settings} error={error} labelFor={id} color={color} columns={columns}>
			<input className={styles.input} {...inputProps} />
		</WebformElementWrapper>
	)
};

export default WebformInput;
