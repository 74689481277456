import React, { useState } from 'react';
import { getElementId, useWebformElement } from '../utils';

import WebformElementWrapper from './WebformElementWrapper';
import { FormComponentType } from "../types";

const DEFAULT_PLACEHOLDER = '';

export const WebformFile: FormComponentType = ({ element, error, color, columns }) => {
  const id = getElementId(element.name);
  const [fileName, setFileName] = useState(DEFAULT_PLACEHOLDER);
  const [inputProps, settings] = useWebformElement(element, {
    className: 'form-control',
    name: element.name,
    type: element.type,
    id
  });

  if (element.type === 'hidden') {
    return <input {...inputProps} />
  }

  const extensions = settings?.attributes?.file_extensions;
  const placeholder = settings?.attributes?.file_placeholder;

  return (
    <WebformElementWrapper settings={settings} error={error} labelFor={id} color={color} columns={columns}>
      <div>
        <input
          {...inputProps}
          onChange={(e) => {
          const files = e.target?.files
            if (!files?.length) {
              setFileName(DEFAULT_PLACEHOLDER);
            } else {
              setFileName((files[0].name).substring(0, 7) + '...');
            }
          }}
          type="file"
        />
        <div
          className="input"
          // p={2}
          // alignItems="center"
          // justifyContent="space-between"
          // sx={{ position: 'absolute', zIndex: 1, left: 0, top: 0, width: '100%', height: '100%', border: '1px solid', ...theme.forms.input }}
        >
          {fileName || ((placeholder || extensions) ? (
            <div
              dangerouslySetInnerHTML={{ __html: placeholder || extensions }}
            />) : null)}
        </div>
      </div>
    </WebformElementWrapper>
  )
};

export default WebformFile;
