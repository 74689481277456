export const en = {
  "load.more": "Load more",
  "show.more": "show more",
  "show.less": "show less",
  articles: "articles",
  article: "article",
  ago: "ago",
  "page.not.found": "Ooops! Page not found!",
  "back.home": "Go back home",
  homepage: "Home",
  access: "Access",
  "grape.varieties": "Varieties",
  vintages: "Vintages",
  note: "note",
  download: "download",
  aging_potential: "Aging potential",
  suggestions: "suggestions",
  "from.your.wine.merchant": "from your wine merchant",
  assemblage: "assemblage",
  terroir: "terroir",
  "vinification.aging": "vinification and aging",
  "food.pairings": "food and wine pairings",
  search: "search",
  "refine.by.category": "REFINE BY CATEGORY",
  "our.clients": "our clients",
  routes: "routes",
  website: "website",
  contact: "contact",
  hours: "hours",
  select: "select",
  submit: "submit",
  "wine.warning":
    "Alcohol abuse is dangerous for health. To consume with moderation",
  "accept.selection.button.text": "Accept optional cookies",
  "accept.all.button.text": "Accept all cookies",
  "decline.all.button.text": "Decline cookies",
  "necessary.option.text": "Necessary",
  "preferences.option.text": "Preferences",
  "statistics.option.text": "Statistics",
  "marketing.option.text": "Marketing",
  "message.cookie.banner":
    "We use cookies for analytical purposes and to show you advertisements and content tailored to your browsing experience. You can accept all cookies or manage your preferences in the control panel. For more information see ",
  "message.newsletter.unique":
    "The email has already been submitted. You may have already submitted <br> this webform, or you need to use a different email.",
  "lien.achat": "Buy",
  "footer.keep.contact": "Let’s keep in touch",
  ageModalTitle: "We are sorry",
  ageModalMessage: "You are not of the required age to enter",
  press_kit: "Press kit",
  "newsletter.title": "Subscribe to our newsletter",
  "newsletter.desc":
    "To discover the world of Xavier Vignon, his latest creations and his news, we invite you to subscribe to our newsletter.",
  services_actives: "Services available",
  informations: "Additional Information",
  "message.policy.link": "the legal notices - Personal data and cookies page.",
  journal: "WINE JOURNAL",
  "age.modal.description":
    "To visit our site, you must be of legal drinking age in your country/region. If there is no legal drinking age, you must be over 21.",
  "age.modal.title": "I CERTIFY THAT I AM OF LEGAL AGE", 
  "age.modal.footer.terms":
    'By clicking on "validate", I accept the <a href="/">Terms and Conditions of Use</a> and declare that I have read the <a href="/">Personal Data and Cookies Policy</a>.',
  "age.modal.footer":
    "ALCOHOL ABUSE IS DANGEROUS FOR YOUR HEALTH, CONSUME IN MODERATION.",
  popup_category: "WINE SHOP & WINE TASTING - DELICATESSEN",
  no : "No",
  yes : "Yes",
  visiter_age : "You cannot visit our site, come back when you are old enough!"
};

export type LanguageStrings = typeof en;
