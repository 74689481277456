import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";

type Props = {
  children?: React.ReactNode;
  loading?: number;
  empty?: boolean;
}

const useStyles = createUseThemedStyles(theme => ({
  loader: {
    background: theme.color.gray,
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 5,
  },
  progress: {
    position: 'fixed',
    background: theme.color.primary,
    height: 3,
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 11,
    transformOrigin: 'left',
    transform: 'scaleX(0)'
  }
}))

const PagePreloader: React.FC<Props> = ({ children, loading = 0, empty = true }) => {
  const [isLoading, setIsLoading] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    const isPageLoading = !!(loading && loading < 100);
    if (isPageLoading) {
      setIsLoading(isPageLoading);
    } else {
      const timeout = setTimeout(() =>  setIsLoading(false), 200);
      window.scrollTo({ top: 0, behavior: 'auto' });
      return () => clearTimeout(timeout);
    }
  }, [loading]);
  return (
      <>
        <AnimatePresence>
          {isLoading && (
              <motion.div
                  className={styles.progress}
                  animate={{
                    scaleX: loading / 100,
                  }}
                  initial={{
                    scaleX: 0,
                  }}
              />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {empty && (
            <motion.div
              className={styles.loader}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: .2 }}
            />
          )}
        </AnimatePresence>
        {children}
      </>

  )
}

export default PagePreloader;
