import React, { useState } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { ArrayElement, DrupalBreadcrumbs } from "../../shared";
import BreadcrumbItem from "./BreadcrumbItem";
import { BsChevronRight } from "react-icons/bs";
import hexToRgba from "hex-to-rgba";
import Popover from "../Popover";
import { useHeaderThemeContext } from "../../context/HeaderThemeContext";

type Props = {
  breadcrumbs: ArrayElement<DrupalBreadcrumbs>
}

const useStyles = createUseThemedStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.white,
    '& svg': {

    },
    '&:after': {
      content: "'\\002F'",
      display: 'inline-block',
      marginLeft: theme.spacing.medium,
      color: theme.color.white,
    },
    '&:last-child:after': {
      content: "normal",
    }
  },
  popover: {
    top: `calc(100% + ${theme.spacing.medium}) !important`,
    background: ({ headerTheme }: any) => headerTheme === 'dark' ?
      hexToRgba(theme.color.gray, 0.7) :
      hexToRgba(theme.color.white, 0.7),
    minWidth: 250,
  },
  popoverContent: {
    padding: [theme.spacing.medium, theme.spacing.large],
    margin: 0,
    listStyle: 'none',
  },
  icon: {
    marginLeft: theme.spacing.large,
    transition: 'transform .3s ease',
  },
  iconActive: {
    transform: 'rotate(90deg)'
  }
}));

const BreadcrumbList: React.FC<Props>= ({ breadcrumbs }) => {
  const [headerTheme] = useHeaderThemeContext();
  const styles = useStyles({ headerTheme });
  const [hovered, setHovered] = useState(false);
  const isMultiple = breadcrumbs.length > 1;
  const rest = isMultiple ? breadcrumbs : [];
  const currentBreadcrumb = breadcrumbs.find(({url}) => url === window.location.pathname);

  return (
    <li
      className={styles.container}
      onMouseEnter={() => isMultiple ? setHovered(true) : null}
      onMouseLeave={() => isMultiple ? setHovered(false) : null}
    >
      <BreadcrumbItem
        breadcrumb={currentBreadcrumb ?? breadcrumbs[0]}
        icon={isMultiple ? <BsChevronRight className={`${styles.icon}${hovered ? ` ${styles.iconActive}` : ''}`} size={16}/> : null} />
      <Popover active={hovered && isMultiple} className={styles.popover}>
        <ul className={styles.popoverContent}>
          {rest.map((breadcrumb, index) => (
            <BreadcrumbItem breadcrumb={breadcrumb} key={index} />
          ))}
        </ul>
      </Popover>
    </li>
  )
}

export default BreadcrumbList;
