import React from 'react';
import { DefaultViewProps } from "../shared/views";
import Meta from "../components/Meta";
import { ArrayElement } from "../shared";
import ArticlesPreview from "../components/ArticlesPreview/ArticlesPreview";
import BlogPageSlider from "../components/Blog/BlogPageSlider";
import { BlogPageData } from "../router/pageData";

const renderContent = (content: ArrayElement<BlogPageData["content"]>, index: number) => {
  if ("slider" in content) {
    return <BlogPageSlider key={index} content={content?.slider}/>
  } else {
    return <ArticlesPreview articles={content?.view?.data} key={index} />
  }
}

const Blog: React.FC<DefaultViewProps & { data?: BlogPageData }> = ({ data }) => {
  return (
    <>
      <Meta tags={data?.meta} />
      {data?.content.map(renderContent)}
    </>
  );
}

export default Blog;
