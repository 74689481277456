import React from 'react';
import { Heading, Markup, TitleWithLine } from "../Typography";
import SliderNavigation, { navHeight } from "../SliderNavigation";
import Slider from "../Slider/Slider";
import { DrupalRestResponseSlider } from "../../shared/blog";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { headerHeightDesktop } from "../../shared/styles";
import Image from "../Image";

type Props = {
  content: DrupalRestResponseSlider["slider"]
}

const useStyles = createUseThemedStyles((theme) => ({
  img: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: 1,
    '& img': {
      height: '100%',
      objectFit: 'cover',
    }
  },
  title: {
    marginBottom: theme.spacing.large,
  },
  description: {
    fontSize: 32,
    lineHeight: 1.12,
    margin: 0,
    [theme.breakpoints.md]: {
      fontSize: 40,
    },
    '& .wysiwyg': {
      fontSize: 40,
    },
  },
  content: {
    ...theme.container.base,
    maxWidth: 1224,
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: `calc(${theme.spacing.xlarge} + ${navHeight + 10}px)`,
    paddingTop: `calc(${theme.spacing.xlarge} + ${navHeight + 10 + headerHeightDesktop}px)`,
    color: '#fff',
    height: '100%',
    boxSizing: 'border-box',
  },
  nav: {
    ...theme.container.base,
    position: 'absolute',
    bottom: theme.spacing.large,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 15,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));


const BlogPageSlider: React.FC<Props> = ({ content }) => {
  const styles = useStyles();

  return (
    <Slider
      items={content.map((item, index) => (
        <React.Fragment key={index}>
          <Image
            className={styles.img}
            src={item.url}
            alt={item.alt}
          />
          <div className={styles.content}>
            <TitleWithLine className={styles.title}>{item.field_title}</TitleWithLine>
            <Heading className={styles.description} tag="h3"><Markup html={item.field_text} /></Heading>
          </div>
        </React.Fragment>
      ))}
      navigation={content.length > 1 ? (
        <div className={styles.nav}>
          <SliderNavigation
            slides={content.length}
          />
        </div>
      ) : null}
    />
  )
}

export default BlogPageSlider;
