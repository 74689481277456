import { AppLanguage } from "../shared/local";

const isEn = /^\/en($|\/)/;

let currentLanguage: AppLanguage = isEn.test(window.location.pathname) ? AppLanguage.English : AppLanguage.French

// only for internal use. In App use useLanguageContext();
export const setApiLanguage = (lang: AppLanguage) => {
  currentLanguage = lang;
}

export const getApiLanguage = (): AppLanguage => currentLanguage;
