import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination} from 'swiper';
import { DrupalRestResponseSlider } from "../../shared/blog";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import hexToRgba from "hex-to-rgba";
import Mask from "../Mask";
import { navHeight } from "../SliderNavigation";
import { useIsDesktop } from "../../context/ScreenSize";
import {AutoplayOptions} from "swiper/types";

type Props = {
  items?: React.ReactNode[],
  className?: string;
  navigation?: React.ReactNode;
  pagination?: boolean;
  autoplay?: boolean|AutoplayOptions
}

const useStyles = createUseThemedStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: '120%',
    [theme.breakpoints.sm]: {
      paddingTop: '64%',
    },
    [theme.breakpoints.md]: {
      paddingTop: '42%',
    }
  },
  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: theme.color.gray
  },
  slide: {
    position: 'relative',
  },
  mask: {
    position: "absolute",
    zIndex: 2,
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: hexToRgba(theme.color.grayLine, 0.2),
    opacity: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      zIndex: 2,
      background: 'radial-gradient(60% 60% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    },
    '&:after': {
      // content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      zIndex: 2,
      background: 'linear-gradient(180deg, #000000 -40%, rgba(255, 255, 255, 0) 100%);',
    }
  },
  swiper: {
    height: '100%',
    '& .swiper-pagination': {
      bottom: theme.spacing.large,
      height: navHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.md]: {
        bottom: theme.spacing.xlarge
      }
    },
    '& .swiper-pagination-bullet': {
      width: 7,
      height: 7,
      background: hexToRgba(theme.color.white, 0.4),
      transition: 'transform .3s ease, background .3s ease',
    },
    '& .swiper-pagination-bullet-active': {
      transform: 'scale(1.42)',
      background: theme.color.primary,
    }
  },
}));

const Slider: React.FC<Props> = ({ pagination = true, items = [], navigation, className, autoplay = false}) => {
  const styles = useStyles();
  const isDesktop = useIsDesktop();

  if (!items.length) return null;

  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      <div className={styles.slider}>
        <Swiper
          className={styles.swiper}
          modules={[Navigation, Pagination, Autoplay]}
          loop
          pagination={pagination && items.length > 1}
          enabled={items.length > 1}
          autoplay={autoplay}
        >
          {items.map((slide, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              {slide}
            </SwiperSlide>
          ))}
          {isDesktop ? navigation : null}
        </Swiper>
      </div>
    </div>
  )
}

export default Slider;
