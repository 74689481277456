// Views are jsonapi parts (can be nodes or any part) that we display as separated page from 'src/views'

import { TranslatePathResponse } from "./router";
import { PageData } from "../router/pageData";

export type DefaultViewProps = {
  individual: string;
  type: ViewType;
  entity: TranslatePathResponse["entity"];
  data?: PageData
}

export enum ViewType {
  PAGE_BLOG = "node--page_blog",
  PAGE_ARTICLE = 'node--article',
  PAGE_GAMME = "node--page_gamme",
  PAGE_404 = "__react--page-404",
  PAGE_HOME = "node--homepage",
  TERM_GAMME = "taxonomy_term--gamme",
  PAGE_PRODUCT = "node--product",
  PAGE_PAGE = "node--page",
  EMPTY = "__react--empty"
}
