import React from 'react';
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import { Heading } from "../components/Typography";
import useLanguageStrings from "../hooks/useLanguageStrings";
import Button from "../components/Button";
import { AiFillHome } from 'react-icons/ai';
import { Link } from "react-router-dom";

type Props = {

}

const useStyles = createUseThemedStyles(theme => ({
  '404': {
    minHeight: '100vh',
    width: '100%',
    background: theme.color.gray,
    color: theme.color.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    [theme.breakpoints.md]: {
      fontSize: 40,
    }
  }
}));

const Page404: React.FC<Props> = () => {
  const styles = useStyles();
  const dictionary = useLanguageStrings();
  return (
    <section className={styles['404']}>
      <Heading className={styles.title}>{dictionary["page.not.found"]}</Heading>
      <Button filled as={Link} to={"/"}>{dictionary["back.home"]}<AiFillHome size={16} /></Button>
    </section>
  )
}

export default Page404;
