import React from 'react';
import { DefaultPageData } from "../../router/pageData";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import BreadcrumbList from "./BreadcrumbList";

type Props = {
  items: DefaultPageData["breadcrumbs"]
}

const useStyles = createUseThemedStyles(theme => ({
  breadcrumbs: {
    padding: [0, 0, 0, theme.spacing.large],
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    gap: theme.spacing.medium,
  },
}));

const BreadcrumbsComponent: React.FC<Props> = ({
 items
}) => {
  const styles = useStyles();
  return (
    <ul className={styles.breadcrumbs}>
      {items.map((breadcrumbs, index) => (
        <BreadcrumbList breadcrumbs={breadcrumbs} key={index} />
       ))}
    </ul>
  )
}

export default BreadcrumbsComponent;
