import React from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { useLanguageContext } from "./LanguageProvider";
import { AppLanguage } from "../../shared/local";
import { useAlternateLinks } from "../../context/AlternateLinks";

const useStyles = createUseThemedStyles(theme => ({
  switcher: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    ...theme.link.primary,
    fontSize: 18,
    margin: [0, theme.spacing.medium],
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    }
  },
  linkActive: {
    color: theme.color.primary,
  }
}))

const LanguageSwitcher: React.FC = () => {
  const activeLanguage = useLanguageContext();
  const languages = (Object.keys(AppLanguage) as Array<keyof typeof AppLanguage>).map((key) => AppLanguage[key]);
  const [alternateLinks] = useAlternateLinks();

  const styles = useStyles();
  // TODO - redirect to particular page
  return (
    <div className={styles.switcher}>
      {languages.map((lang) => {
        const isActive = lang === activeLanguage;
        const linkEl = alternateLinks.find(([link]) => link.hreflang === lang);
        const href = (linkEl?.length && linkEl[0]?.href) ?? `/${lang}/`
        return  (
          <a
            key={lang}
            href={href}
            className={`${styles.link}${isActive ? ` ${styles.linkActive}` : ''}`}
          >
            {lang.toUpperCase()}
          </a>
        )
      })}
    </div>
  )
}

export default LanguageSwitcher;
