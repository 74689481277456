import React from 'react';
import { DefaultViewProps } from "../shared/views";
import { PageGammeData } from "../router/pageData";
import Meta from "../components/Meta";
import GammeComponent from "../components/GammeComponent";

const Gamme: React.FC<DefaultViewProps & { data: PageGammeData }> = ({
  data: pageData
}) => {
  return (
    <>
      <Meta tags={pageData.meta}/>
      <GammeComponent items={pageData.data?.field_gamme_item} />
    </>
  )
}

export default Gamme;
