import React from 'react';
import Image from "../Image";
import { Markup } from "../Typography";
import Button from "../Button";
import { IoChevronForward } from "react-icons/io5";
import { useIsDesktop } from "../../context/ScreenSize";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import useLanguageStrings from "../../hooks/useLanguageStrings";

type Props = {
  title?: string;
  subTitle?: string;
  image1?: string;
  image1alt?: string;
  image?: string;
  imageAlt?: string;
  imageRightSide?: string;
  imageRightSideAlt?: string;
  content?: string;
  contentBottom?: string;
  contentRight?: string;
  link?: string;
}

const useStyles = createUseThemedStyles(theme => ({
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.md]: {
      ...theme.container.small,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
  },
  contentLeft: {
    ...theme.container.small,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      width: '50%',
      paddingRight: theme.spacing.xlarge,
      paddingLeft: 0,
      paddingBottom: theme.spacing.large,
    },
  },
  contentRight: {
    ...theme.container.small,
    position: 'relative',
    background: theme.color.grayBg,
    paddingTop: theme.spacing.large,
    paddingBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      paddingTop: theme.spacing.xlarge,
      paddingLeft: theme.spacing.xlarge,
      paddingRight: 0,
      paddingBottom: theme.spacing.xlarge,
      margin: 0,
      width: '50%',
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: `calc(100% + ${theme.spacing.large})`,
        width: 1,
        backgroundColor: theme.color.grayLine,
        opacity: 0.5
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        left: '100%',
        top: 0,
        height: '100%',
        width: 9999,
        background: 'inherit'
      }
    },
  },
  contentTitle: {
    position: 'relative',
    paddingTop: theme.spacing.large,
    margin: [theme.spacing.large, 0, 0],
    color: theme.color.gray,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    fontSize: 25,
    lineHeight: '29px',
    textTransform: 'uppercase',
  },
  contentTitleMark: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 9,
    height: 2,
    backgroundColor: theme.color.grayMask,
  },
  contentSubtitle: {
    fontFamily: theme.fonts.heading,
    fontWeight: 400,
    fontSize: 20,
    color: theme.color.primary,
    fontStyle: 'italic',
    margin: [0, 0, theme.spacing.large],
  },
  contentContent: {
    fontFamily: theme.fonts.heading,
    color: theme.color.grayText,
    fontSize: 14,
    lineHeight: (25 / 14).toFixed(2),
    marginBottom: theme.spacing.large,
  },
  contentImage: {
    marginTop: '-40%',
    marginBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      margin: [theme.spacing.large, 0, 0],
      width: '80%',
      alignSelf: 'flex-end',
      position: 'relative',
      zIndex: 2,
      marginLeft: 'auto',
      marginRight: 0,
    }
  },
  contentImage1Container: {
    margin: [0, `-${theme.spacing.xlarge}`],
    [theme.breakpoints.md]: {
      position: 'absolute',
      bottom: theme.spacing.xlarge,
      margin: 0,
      right: '40%',
      width: '100%',
    }
  },
  contentImageRight: {
    marginBottom: theme.spacing.large,
    marginRight: `-${theme.spacing.xlarge}`,
    maxWidth: 450,
    [theme.breakpoints.md]: {
      marginRight: 0,
      maxWidth: 'none',
      width: `calc(100% + ${theme.spacing.xlarge})`,
    }
  },
  contentLink: {
    marginTop: theme.spacing.large,
  },
  contentImages: {
    [theme.breakpoints.md]: {
      position: 'relative',
      paddingBottom: '45%',
    }
  }
}))

const PageHomeContentBlock: React.FC<Props> = (props) => {
  const {
    title,
    subTitle,
    image1,
    image1alt,
    image,
    imageAlt,
    imageRightSide,
    imageRightSideAlt,
    content,
    contentBottom,
    contentRight,
    link
  } = props;
  const styles = useStyles();
  const isDesktop = useIsDesktop();
  const dictionary = useLanguageStrings();
  return (
    <div className={styles.contentContainer}>
      <div className={styles.contentLeft}>
        <h3 className={styles.contentTitle}>
          <span className={styles.contentTitleMark}/>
          {title}
        </h3>
        <p className={styles.contentSubtitle}>{subTitle}</p>
        {content && <Markup className={styles.contentContent} html={content} />}
        <div className={styles.contentImages}>
          {!isDesktop && (<>
              <div className={styles.contentImage1Container}>
                <Image aspectRatio={0.75} className={styles.contentImage1} src={image1}
                       alt={image1alt}/>
              </div>
              <div className={styles.contentImage}>
                <Image aspectRatio={0.75} src={image} alt={imageAlt}/>
              </div>
            </>
          )}
          {isDesktop && (<>
            <div className={styles.contentImage}>
              <Image aspectRatio={0.75} src={image} alt={imageAlt}/>
            </div>
            <div className={styles.contentImage1Container}>
              <Image aspectRatio={0.75} className={styles.contentImage1} src={image1}
                     alt={image1alt}/>
            </div>
          </>)}
        </div>
        {contentBottom && <Markup className={styles.contentContent} html={contentBottom} />}
      </div>
      <div className={styles.contentRight}>
        <Image className={styles.contentImageRight} src={imageRightSide}
               alt={imageRightSideAlt}/>
        <Markup className={styles.contentContent} html={contentRight}/>
        {link && <Button className={styles.contentLink} icon={<IoChevronForward/>} as="a"
                href={link}>{dictionary["access"]}</Button>}
      </div>
    </div>
  )
}

export default PageHomeContentBlock;
