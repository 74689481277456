import { BsInstagram, BsFacebook, BsYoutube, BsTwitter } from 'react-icons/bs';
import { SocialNetworksEnum } from "../../../shared/article";
import { IconType } from "react-icons/lib";

type Socials = Record<SocialNetworksEnum, IconType>

export const socials: Socials = {
  [SocialNetworksEnum.twitter]: BsTwitter,
  [SocialNetworksEnum.instagram]: BsInstagram,
  [SocialNetworksEnum.youtube]: BsYoutube,
  [SocialNetworksEnum.facebook]: BsFacebook,
}
