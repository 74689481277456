import React, { HTMLAttributes } from 'react';
import { FormElementSettings, WebFormError } from "../types";
import { Color, VignonTheme } from "../../../../theme";
import hexToRgba from "hex-to-rgba";
import { useTheme } from "react-jss";
import { createUseThemedStyles } from "../../../../hooks/createUseThemedStyles";
import cn from "classnames";
import { last } from "../../../../utils";

/**
 * Return true if element title should be visually hidden.
 */
export function isTitleHidden(webformAttributes: any) {
	return webformAttributes.title_display === 'invisible'
}

export function getTileStyle(webformAttributes: any): any {
	if (isTitleHidden(webformAttributes)) {
		return {
			position: 'absolute',
			overflow: 'hidden',
			clip: 'rect(1px,1px,1px,1px)',
			width: '1px',
			height: '1px',
			wordWrap: 'normal'
		}
	}

	return undefined
}

export function getTitleDisplay(webformAttributes: any) {
	return webformAttributes.title_display === 'after' ? 'after' : 'before'
}

export function isElementHidden(states: any) {
	return states.invisible === true || states.visible === false
}

type Props = {
  children?: React.ReactNode;
  settings: FormElementSettings;
  error?: WebFormError;
  labelFor?: string;
  full?: boolean;
  labelClassName?: string;
  color?: Color;
  columns?: number;
} & HTMLAttributes<HTMLDivElement>

const useStyles = createUseThemedStyles(theme => ({
  label: {
    marginBottom: theme.spacing.medium,
    display: 'inline-block'
  },
  formError: {
    color: theme.color.primary,
    marginTop: theme.spacing.small,
  },
  formItem: {
    position: 'relative',
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.md]: {
      flexBasis: ({ columns}: any) => `${Math.floor(10000 / columns) / 100}%`
    },
    ':not(:last-of-type)': { mb: 3 },
    'input, .input, textarea, select': {
      borderColor: ({ color }: {  color: Color }) => hexToRgba(theme.color[color], 0.8),
      '&::-webkit-input-placeholder': ({ color }: {  color: Color }) => hexToRgba(theme.color[color], 0.8),
      '&:-moz-placeholder': ({ color }: {  color: Color }) => hexToRgba(theme.color[color], 0.8),
      '&:-ms-input-placeholder': ({ color }: {  color: Color }) => hexToRgba(theme.color[color], 0.8),
    },
  },
}))

const ElementWrapper: React.FC<Props> = ({ children, settings, error, labelFor, full = false, labelClassName, color = 'gray', columns = 1, ...props }) => {
	const { states, attributes, pure } = settings;
  const theme = useTheme() as VignonTheme;
  const styles = useStyles({ columns, color });

	if (states && isElementHidden(states)) {
		return <></>
	}

	if (pure) {
		return <>{children}</>
	}

	const label = attributes.title ? (
		<label className={cn(styles.label, labelClassName)} style={getTileStyle(attributes)} htmlFor={labelFor}>
			{attributes.title}
			{attributes.required && (attributes.title && last(attributes.title) !== '*')? '*' : null}
		</label>
	) : null;

	const classNames = ['form-group', error != null && 'is-invalid', props.className].filter(item => typeof item === 'string').join(' ');

	return (
		<div
			{...props}
			className={cn(styles.formItem, classNames)}
		>
			{getTitleDisplay(attributes) === 'before' && label}

			{children}

			{getTitleDisplay(attributes) === 'after' && label}

			{error && (
				<div className={styles.formError} color="error" {...props} dangerouslySetInnerHTML={{ __html: error }} />
			)}

			{attributes.description && (
				<div className="form-text description" {...props} dangerouslySetInnerHTML={{ __html: attributes.description }} />
			)}
		</div>
	)
};

export default ElementWrapper
