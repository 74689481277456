import React, { createContext, useContext, useEffect } from 'react';
import { AppLanguage } from "../../shared/local";
import { setApiLanguage } from "../../api";

const LanguageContext = createContext<AppLanguage>(AppLanguage.English);

const { Provider } = LanguageContext;

type Props = {
  children: React.ReactNode;
  locale: AppLanguage;
}

const LanguageProvider: React.FC<Props> = ({ children, locale }) => {
  useEffect(() => {
    setApiLanguage(locale)
  }, [locale])
  return <Provider value={locale}>{children}</Provider>
}

export const useLanguageContext = () => {
  return useContext(LanguageContext);
}

export default LanguageProvider;
