import React, { useMemo } from 'react';
import { DefaultViewProps } from "../shared/views";
import Meta from "../components/Meta";
import Slider from "../components/Slider/Slider";
import { createUseThemedStyles } from "../hooks/createUseThemedStyles";
import SliderNavigation, { navHeight } from "../components/SliderNavigation";
import IconButton from "../components/IconButton";
import { AiOutlineFullscreen, AiOutlinePlus } from 'react-icons/ai';
import { BsFillPersonFill, BsClock } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
import { format, formatDistanceToNow, fromUnixTime } from "date-fns";
import { fr } from "date-fns/locale";
import { useLanguageContext } from "../i18n/components/LanguageProvider";
import useLanguageStrings from "../hooks/useLanguageStrings";
import { Markup } from "../components/Typography";
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import Button from "../components/Button";
import Mask from "../components/Mask";
import hexToRgba from "hex-to-rgba";
import { Link } from "react-router-dom";
import { ArticlePageData } from "../router/pageData";
import { useIsDesktop } from "../context/ScreenSize";
import { socials } from "../components/icons/socials";
import Image from '../components/Image';
import ImageNoLazy from "../components/ImageNoLazy";

const locales = {
  fr,
  en: undefined,
}

const asideWidth = 360;

const useStyles = createUseThemedStyles(theme => ({
  sliderImg: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: 1,
    '& img': {
      height: '100%',
      objectFit: 'cover',
    }
  },
  sliderNavigationContainer: {
    ...theme.container.base,
    position: 'absolute',
    bottom: theme.spacing.large,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 15,
    display: 'none',
    justifyContent: 'flex-end',
  },
  sliderNavigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: asideWidth,
  },
  sliderFull: {
    position: 'absolute',
    zIndex: 5,
    right: 20,
    transform: `translateY(calc(-${theme.spacing.xlarge} - 3px))`,
    color: theme.color.grayText,
    [theme.breakpoints.md]: {
      position: 'static',
      transform: 'none',
      marginRight: theme.spacing.large,
    }
  },
  section: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    zIndex: 2,
    [theme.breakpoints.md]: {
      ...theme.container.base,
      flexDirection: 'row',
    }
  },
  article: {
    background: theme.color.white,
    padding: [28, 40, 40],
    marginBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      marginTop: -99,
      flex: `1 1 ${theme.container.base.maxWidth - asideWidth - 20}px`,
      marginRight: 20,
      marginBottom: theme.spacing.xxlarge,
    }
  },
  articleTop: {
    display: 'flex',
    alignItems: 'center',
  },
  articleCategory: {
    fontFamily: theme.fonts.heading,
    fontSize: 27,
    color: theme.color.primary,
    fontStyle: 'italic',
  },
  articleCreated: {
    fontSize: 16,
    color: theme.color.grayMask,
    marginLeft: theme.spacing.medium,
    paddingLeft: theme.spacing.small,
    borderLeft: '1px solid',
    borderColor: theme.color.grayMask,
    transform: 'translateY(2px)',
  },
  articleTitle: {
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    fontSize: 30,
    lineHeight: '35px',
    color: theme.color.gray,
    margin: [theme.spacing.medium, 0, theme.spacing.large],
    [theme.breakpoints.md]: {
      fontSize: 50,
      lineHeight: '60px',
      margin: [theme.spacing.medium, 0, theme.spacing.medium],
    }
  },
  articleInfo: {
    display: 'flex',
    fontWeight: 400,
  },
  articleInfoContent: {
    color: theme.color.grayMask,
    marginRight: theme.spacing.small,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0,
    },
    '& svg': {
      color: theme.color.grayMask,
      display: 'inline-block',
      marginRight: theme.spacing.small,
    },
    [theme.breakpoints.md]: {
      marginRight: theme.spacing.large,
      fontSize: 14,
    }
  },
  articleBody: {
    paddingTop: theme.spacing.large,
    color: theme.color.grayText,
    paddingBottom: theme.spacing.large,
    [theme.breakpoints.md]: {
      paddingTop: theme.spacing.xlarge,
    }
  },
  author: {
    backgroundColor: theme.color.grayBg,
    padding: [24, 20, 40],
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.md]: {
      padding: [40, 40, 65],
      flexWrap: 'nowrap'
    }
  },
  authorImg: {
    width: 100,
    height: 100,
    flexShrink: 0,
    borderRadius: 9999,
    overflow: 'hidden',
    marginRight: 20,
    marginBottom: 20,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    [theme.breakpoints.md]: {
      marginRight: 40,
      marginBottom: 0,
    }
  },
  authorName: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 700,
    color: theme.color.gray,
    lineHeight: '22px',
    marginBottom: theme.spacing.medium,
    display: 'flex',
    alignItems: 'center',
  },
  authorSocials: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    marginTop: 20,
    fontSize: 20,
    color: theme.color.primary
  },
  articleNavigation: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: theme.spacing.large,
  },
  articlePreview: {
    all: "unset",
    position: 'relative',
    width: '50%',
    cursor: 'pointer',
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.md]: {
      height: 200,
    },
    '& img': {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    }
  },
  articlePreviewContainer: {
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.color.white,
    padding: 20,
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },

    '& svg': {
      color: theme.color.white,
    },
    '& button, & a': {
      margin: [0, 0, 20],
      flexShrink: 0,
      [theme.breakpoints.md]: {
        margin: [0, theme.spacing.large],
      }
    }
  },
  articlePreviewContainerNext: {
    textAlign: 'right',
    alignItems: 'flex-end',
    [theme.breakpoints.md]: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
    }
  },
  articlePreviewContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  articlePreviewTitle: {
    fontSize: 14,
    lineHeight: '16px',
    fontFamily: theme.fonts.base,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: [0, 0, theme.spacing.tiny],
    flexGrow: 1,
    [theme.breakpoints.md]: {
      fontSize: 16,
      lineHeight: '22px',
    }
  },
  articlePreviewCategory: {
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: theme.fonts.heading,
    color: theme.color.primary,
    fontWeight: 400,
  },
  aside: {
    ...theme.container.base,
    paddingBottom: 20,
    [theme.breakpoints.md]: {
      padding: [20, 0, 0],
      flex: `0 0 ${asideWidth}px`,
      width: asideWidth,
      margin: 0,
    }
  },
  asideBlock: {
    backgroundColor: theme.color.white,
    marginBottom: 20,
    padding: [theme.spacing.large, 20, 40]
  },
  asideBlockTitle: {
    fontFamily: theme.fonts.base,
    textTransform: 'uppercase',
    fontSize: 25,
    fontWeight: 700,
    color: theme.color.gray,
    margin: [0, 0, 40],
  },
  categoriesList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  categoriesCategory: {
    padding: 0,
    borderBottom: `1px solid ${hexToRgba(theme.color.grayLine, 0.5)}`,
    '&:last-child': {
      border: 'none',
    }
  },
  categoriesCategoryLink: {
    textDecoration: 'none',
    minHeight: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.color.grayText,
    fontSize: 12,
    fontWeight: 500,
  },
  categoriesCategoryName: {},
  categoriesCategoryCounter: {
    width: 32,
    height: 32,
    borderRadius: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.whiteText
  },
  recentList: {
    listStyle: 'none',
    padding: 0,
    margin: [0, 0, theme.spacing.large]
  },
  recentArticle: {
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  recentArticleTitle: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 700,
    width: '100%',
  },
  recentArticleLoadMore: {
    margin: [0, 'auto'],
  },
  recentLink: {
    all: 'unset',
    display: 'flex',
    cursor: 'pointer',
    '& img': {
      width: 150,
      height: 100,
      objectFit: 'cover',
      flex: '0 0 150px',
      marginRight: 10,
    }
  }
}))

const Article: React.FC<DefaultViewProps & { data: ArticlePageData }> = ({ data: pageData }) => {
  const styles = useStyles();
  const language = useLanguageContext();
  const dictionary = useLanguageStrings();

  const data = pageData.data;
  const blog_url = data.most_recent_articles?.url ?? '/';
  const slider = data?.slider ?? [];

  const created = useMemo(() => {
    const date = fromUnixTime(Number(data.created ?? 0))
    return {
      formatted: format(date, 'd MMMM y', { locale: locales[language] }),
      toNow: formatDistanceToNow(date, { locale: locales[language] })
    }
  }, [data.created, language]);

  const isDesktop = useIsDesktop();

  return (
    <>
      <Meta tags={pageData.meta}/>
      <Slider
        items={slider.map(item => <React.Fragment>
          <Image
            className={styles.sliderImg}
            src={item.url}
            alt={item.alt}
          />
        </React.Fragment>)}
        navigation={
          <div className={styles.sliderNavigationContainer}>
            <div className={styles.sliderNavigation}>
              <IconButton className={styles.sliderFull}>
                <AiOutlineFullscreen size={navHeight}/>
              </IconButton>
              {slider.length > 1 && isDesktop ? (
                <SliderNavigation
                  slides={slider.length}
                />
              ) : null}
            </div>
          </div>
        }
      />
      {!isDesktop && (
        <IconButton className={styles.sliderFull}>
          <AiOutlineFullscreen size={navHeight}/>
        </IconButton>
      )}
      <section className={styles.section}>
        <article className={styles.article}>
          <div className={styles.articleTop}>
            <div className={styles.articleCategory}>
              {data.field_category}
            </div>
            <div className={styles.articleCreated}>
              {created.formatted}
            </div>
          </div>
          <h1 className={styles.articleTitle}>
            {data.title}
          </h1>
          <div className={styles.articleInfo}>
            <div className={styles.articleInfoContent}>
              <BsFillPersonFill size={22}/>
              {data.field_author?.title}
            </div>
            <div className={styles.articleInfoContent}>
              <AiOutlineCalendar size={22}/>
              {created.toNow} {dictionary["ago"]}
            </div>
            <div className={styles.articleInfoContent}>
              <BsClock size={22}/>
              2 min read
            </div>
          </div>
          <div className={styles.articleBody}>
            <Markup html={data.field_content}/>
          </div>
          <div className={styles.author}>
            <div className={styles.authorImg}>
              <Image
                alt={data.field_author?.field_author_image?.alt}
                src={data.field_author?.field_author_image?.url}
              />
            </div>
            {!isDesktop && (
              <div className={styles.authorName}>
                {data.field_author?.title}
              </div>
            )}
            <div className={styles.authorContent}>
              {isDesktop && (
                <div className={styles.authorName}>
                  {data.field_author?.title}
                </div>
              )}
              <div className={styles.authorDescription}>
                <Markup html={data.field_author?.field_content}/>
              </div>
              {data.field_author?.field_social_networks && <div className={styles.authorSocials}>
                {data.field_author?.field_social_networks.map((social, index) => {
                  const Icon = socials[social.field_social_network]
                  if (typeof Icon !== 'undefined') {
                    return (
                      <IconButton tag="a" target="_blank" href={social.field_social_link} key={`${index}-${social}`}>
                        <Icon/>
                      </IconButton>
                    )
                  }
                })}
              </div>}
            </div>
          </div>
          <div className={styles.articleNavigation}>
            {data.previous_article?.url ? (
              <Link to={data.previous_article?.url as any} className={styles.articlePreview}>
                <Mask/>
                <ImageNoLazy
                  src={data.previous_article?.slider?.length ? data.previous_article?.slider[0]?.url : undefined}
                  alt={data.previous_article?.slider?.length ? data.previous_article?.slider[0]?.alt : undefined}
                />
                <div className={styles.articlePreviewContainer}>
                  <IconButton>
                    <IoChevronBackCircleOutline size={navHeight}/>
                  </IconButton>
                  <div className={styles.articlePreviewContent}>
                    <h5 className={styles.articlePreviewTitle}>{!isDesktop ? (data.previous_article?.title ?? '').slice(0, 25) + '...' : data.previous_article?.title}</h5>
                    <div className={styles.articlePreviewCategory}>{data.previous_article?.field_category}</div>
                  </div>
                </div>
              </Link>
            ) : null}
            {data.next_article?.url ? (
              <Link to={data.next_article?.url as any}  className={styles.articlePreview}>
                <Mask/>
                <ImageNoLazy
                  src={data.next_article?.slider?.length ? data.next_article?.slider[0]?.url : undefined}
                  alt={data.next_article?.slider?.length ? data.next_article?.slider[0]?.alt : undefined}
                />
                <div className={`${styles.articlePreviewContainer} ${styles.articlePreviewContainerNext}`}>
                  <IconButton>
                    <IoChevronForwardCircleOutline size={navHeight}/>
                  </IconButton>
                  <div className={styles.articlePreviewContent}>
                    <h5 className={styles.articlePreviewTitle}>{!isDesktop ? (data.next_article?.title ?? '').slice(0, 25) + '...' : data.next_article?.title}</h5>
                    <div className={styles.articlePreviewCategory}>{data.next_article?.field_category}</div>
                  </div>
                </div>
              </Link>
            ) : null}
          </div>
        </article>
        <aside className={styles.aside}>
          <div className={styles.asideBlock}>
            <h3 className={styles.asideBlockTitle}>{data.categories?.title}</h3>
            <ul className={styles.categoriesList}>
              {(data.categories?.data ?? []).map(category => (
                <li className={styles.categoriesCategory} key={category.id}>
                  <Link to={category.url} className={styles.categoriesCategoryLink}>
                    <div className={styles.categoriesCategoryName}>
                      {category.name}
                    </div>
                    <div className={styles.categoriesCategoryCounter}>
                      {category.count}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.asideBlock}>
            <h3 className={styles.asideBlockTitle}>{data.most_recent_articles?.title}</h3>
            <ul className={styles.recentList}>
              {(data.most_recent_articles?.data ?? []).map(article => (
                <li className={styles.recentArticle} key={article.nid}>
                  <Link to={article.url as any} className={styles.recentLink}>
                    <Image
                      src={article?.slider?.length ? article?.slider[0]?.url : undefined}
                      alt={article?.slider?.length ? article?.slider[0]?.alt : undefined}
                    />
                    <div className={styles.recentArticleTitle}>
                      {article.title}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <Button
              className={styles.recentArticleLoadMore}
              color="primary"
              onClick={() => window.location.href = blog_url}
              icon={<AiOutlinePlus size={11}/>}
            >
              {dictionary["load.more"]}
            </Button>
          </div>
        </aside>
      </section>
    </>
  );
}

export default Article;
