import drupalBaseEndpoint from "../api/base";
import { MenuResponse } from "../shared/settings";
import { ArrayElement } from "../shared";
import { Classes } from "jss";

export const buildFullAssetUrl = (url?: string) => url ? `${drupalBaseEndpoint}/${url}` : url;

export type NestedMenu = Array<{
  item: ArrayElement<MenuResponse["data"]>,
  children: MenuResponse["data"]
}>

export const createNestedMenu = (items: MenuResponse["data"]): NestedMenu  => {
  const parents = [] as MenuResponse["data"];
  const children = [] as MenuResponse["data"];
  items.forEach(item => item.attributes.parent ? children.push(item) : parents.push(item));

  return parents.map(item => ({
    item,
    children: children.filter(child => child.attributes.parent === item.id)
  }));
}

export const withActiveClassName = (active: boolean, styles: Classes) => (key: keyof Classes) => active ? `${styles[key]} ${styles[`${key}Active`]}`: styles[key];

type OmitFn = <T extends any, Keys extends string | number>(obj: any, keys: Array<number | string>) => Omit<T, Keys>
export const omit: OmitFn = (obj, keys) => {
  return keys.reduce((acc, key) => {
    const { [key]: omitted, ...rest } = acc;
    return rest;
  }, obj);
}

export const last = (arr: unknown[]): unknown => arr.slice(-1)[0]

