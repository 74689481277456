import React, { useEffect, useMemo, useState } from 'react';
import { createUseThemedStyles } from '../hooks/createUseThemedStyles';
import { useSiteSettingsContext } from '../context/SiteSetting';
import { buildFullAssetUrl, createNestedMenu } from '../utils';
import { Markup } from '../components/Typography';
import { MapDotIcon } from '../components/icons';
import IconButton from '../components/IconButton';
import { AiOutlineMail } from 'react-icons/ai';
import { useIsDesktop } from '../context/ScreenSize';
import FooterNav from '../components/Footer/Nav';
import Image from '../components/Image';
import useLanguageStrings from '../hooks/useLanguageStrings';
import useWebForm from '../hooks/useWebForm';
import { useLanguageContext } from '../i18n/components/LanguageProvider';
import { getSubscribeForm } from '../api/form';
import SubscribeForm from '../components/Subscribe/SubscribeForm';
import { SubmitFormSuccessResponse, WebForm } from '../shared/form';
import Modal from '../components/Modal';
import { restApi } from '../api';

type Props = {};

type DossierResponse = {
  url_dossier: string;
}

type FooterResponse = {
  email?: string;
  urlGoogle?: string;
}

const useStyles = createUseThemedStyles((theme) => ({
  footer: {
    width: '100%',
  },
  footerTop: {
    background: theme.color.gray,
    padding: [theme.spacing.xlarge, 0],
    [theme.breakpoints.md]: {
      minHeight: 510,
    },
  },
  footerBottom: {
    display: 'flex',
    background: theme.color.whiteText,
    alignItems: 'center',
    minHeight: 150,
    [theme.breakpoints.md]: {
      minHeight: 90,
    },
  },
  container: {
    ...theme.container.base,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0,
    transition: 'opacity .3s ease',
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  descriptionIcons: {
    marginTop: theme.spacing.large,
    marginBottom: theme.spacing.xlarge,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.color.primary,
    '& a + a': {
      marginLeft: theme.spacing.large,
    },
    '& svg': {
      height: 33,
      width: 'auto',
      [theme.breakpoints.md]: {
        height: 22,
      },
      '& path': {
        fill: theme.color.primary,
        [theme.breakpoints.md]: {
          fill: theme.color.primary,
        },
      },
    },
    [theme.breakpoints.md]: {
      justifyContent: 'flex-start',
      margin: [theme.spacing.xlarge, 0, 0],
    },
  },
  containerReady: {
    opacity: 1,
  },
  logo: {
    width: 70,
    marginBottom: theme.spacing.large,
    flexShrink: 0,
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.md]: {
      width: 54,
      marginBottom: 0,
      marginRight: theme.spacing.xlarge,
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing.large,
    minHeight: 440,
    width: '100%',
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  description: {
    fontFamily: theme.fonts.heading,
    color: theme.color.white,
    fontStyle: 'italic',
    width: 210,
    flex: '0 0 210px',
    textAlign: 'center',
    [['& h1', '& h2', '& h3', '& h4', '& h5', '& h6'].join(', ')]: {
      margin: 0,
    },
    '& h2': {
      fontSize: 38,
    },
    [theme.breakpoints.md]: {
      textAlign: 'left',
      margin: [0, theme.spacing.large, 0, 0],
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    alignSelf: 'stretch',
    [theme.breakpoints.md]: {
      alignItems: 'flex-start',
    },
  },
  mentionLegales: {
    ...theme.link.primary,
    fontSize: 12,
    textTransform: 'none',
    color: theme.color.grayText,
    marginBottom: theme.spacing.large,
    '&:after': {
      ...theme.link.primary['&:after'],
      background: theme.color.grayText,
    },
    [theme.breakpoints.md]: {
      marginBottom: 0,
    },
  },
  copyright: {
    fontSize: 12,
    color: theme.color.grayText,
    '& span': {
      color: theme.color.primary,
    },
  },
  wineWarning: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.color.grayText,
    marginBottom: '2rem',
    '& span': {
      color: theme.color.primary,
    },
    [theme.breakpoints.md]: {
      marginBottom: 0,
    },
  },
  containerBottom: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  success: {
    color: theme.color.white,
    fontFamily: theme.fonts.heading,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    [theme.breakpoints.md]: {
      minWidth: 500,
      minHeight: 300,
    },
  },
  successTitle: {
    fontSize: 25,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    marginTop: 0,
    marginBottom: theme.spacing.medium,
    textTransform: 'uppercase',
    [theme.breakpoints.md]: {
      fontSize: 32,
      marginBottom: theme.spacing.large,
    },
  },
  successDescription: {
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.md]: {
      fontSize: 20,
    },
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: [theme.spacing.large, 0],
    justifyContent: 'space-between',
    borderTop: '2px solid',
    flexWrap: 'wrap',
    borderColor: theme.color.grayMask,
    [theme.breakpoints.md]: {
      flexDirection: 'row',
      width: '100%',
    },
  },
  contactsTitle: {
    color: theme.color.primary,
    fontSize: 16,
    marginBottom: 24,
    textAlign: 'center',
    maxWidth: 300,
    [theme.breakpoints.md]: {
      marginBottom: 0,
      maxWidth: 'none',
    },
  },
  contactsDescription: {
    color: theme.color.white,
    fontFamily: theme.fonts.heading,
    fontSize: 13,
    marginBottom: 36,
    textAlign: 'center',
    maxWidth: 300,
    [theme.breakpoints.md]: {
      marginBottom: 0,
      maxWidth: 'none',
    },
  },
  buttonCta: {
    color: theme.color.grayMask,
    background: 'transparent',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '10px 18px',
    border: `2px solid ${theme.color.grayLine}`,
    width: '100%',
    textAlign: 'center',
    borderRadius: '3rem',
    textTransform: 'uppercase',
    fontFamily: "'Ubuntu', sans-serif",
    fontStyle: 'normal',
    fontSize: '14px',
    transition: 'color 150ms ease-in-out',
    '&:hover': {
      color: theme.color.grayLine,
    },
  },
}));

const Footer: React.FC<Props> = () => {
  const styles = useStyles();
  const { loading, logo, footer } = useSiteSettingsContext();
  const url = logo?.file?.data.attributes.uri.url;
  const dictionary = useLanguageStrings();

  const items = useMemo(() => createNestedMenu(footer.menu), [footer.menu]);
  const { subscribeForm, mentionLegales } = footer;
  const currentYear = new Date().getFullYear();
  const isDesktop = useIsDesktop();
  const lang = useLanguageContext();
  let external =
    mentionLegales?.full_url &&
    (mentionLegales?.full_url.includes('http') ||
      mentionLegales?.full_url.includes(lang + '/'));
  const mlUrl = external
    ? mentionLegales?.full_url
    : lang + '/' + mentionLegales?.full_url;

  //const [webForm, setWebForm] = useState<WebForm | null>(null);
  const webForm = useWebForm(getSubscribeForm);
  const [success, setSuccess] = useState<SubmitFormSuccessResponse | null>(
    null
  );
  const [dataFooter, setDataFooter] = useState<FooterResponse | null>();

  const handleSuccess = (response: SubmitFormSuccessResponse) => {
    setSuccess(response);
  };

  const downloadPressKit = async () => {
    try {
      const { url_dossier } = await restApi<DossierResponse>('node/dossier');
      const a = document.createElement('a');

      a.href = url_dossier;
      a.target = '_blank';
      a.download = url_dossier.split('/').pop() ?? `${dictionary.press_kit}.pdf`;
      document.body.appendChild(a);

      a.click();
      a.remove();
    } catch (error) {
      console.error('Ups! No se descargo el documento');
    }
  }

  const loadDataFooter = async () => {
    try {
      const data = await restApi<FooterResponse>('node/footer');
      setDataFooter(data);
    } catch (error) {
      console.log('Ups! No se cargaron los enlaces del footer')
    }
  }

  useEffect(() => {
    loadDataFooter();
  }, [])

  return (
    <footer className={styles.footer}>
      <Modal isOpen={!!success} handleClose={() => setSuccess(null)}>
        <div className={styles.success}>
          <h3 className={styles.successTitle}>{success?.success_title}</h3>
          <p className={styles.successDescription}>
            {success?.success_message}
          </p>
        </div>
      </Modal>
      <div className={styles.footerTop}>
        <div
          className={`${styles.container}${
            !loading ? ` ${styles.containerReady}` : ''
          }`}
        >
          <div className={styles.logo}>
            {url ? (
              <Image src={buildFullAssetUrl(url)} alt={logo?.alt} />
            ) : null}
          </div>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.description}>
                <Markup html={footer.text} />
                <div className={styles.descriptionIcons}>
                  <a href={`mailto:${dataFooter?.email ?? ''}`}>
                    <IconButton>
                      <AiOutlineMail />
                    </IconButton>
                  </a>
                  <a
                    target='_new'
                    href={`${dataFooter?.urlGoogle ?? ''}`}
                  >
                    <IconButton>
                      <MapDotIcon />
                    </IconButton>
                  </a>
                </div>
                <div className={styles.descriptionIcons}>
                  <button
                    className={styles.buttonCta}
                    onClick={() => downloadPressKit()}
                  >
                    {dictionary.press_kit}
                  </button>
                </div>
              </div>
              <FooterNav items={items} />
            </div>
            <div className={styles.bottom}>
              <div className={styles.contactsTitle}>{dictionary['footer.keep.contact']}</div>
              <div className={styles.contactsDescription}>{subscribeForm?.description}</div>
              <SubscribeForm
                webForm={webForm}
                onSubmitSuccess={handleSuccess}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div
          className={`${styles.container} ${styles.containerBottom}${
            !loading ? ` ${styles.containerReady}` : ''
          }`}
        >
          <a href={mlUrl} className={styles.mentionLegales}>
            {mentionLegales?.title}
          </a>
          <div className={styles.wineWarning}>{dictionary['wine.warning']}</div>
          <div id='copyright' className={styles.copyright}>
            <Markup html={footer.copyright} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
