import React, { forwardRef, HTMLAttributes } from 'react';
import { createUseStyles } from "react-jss";
import { buildFullAssetUrl } from "../utils";

type Props = {
  alt?: string;
  src?: string;
  aspectRatio?: number,
} &  HTMLAttributes<HTMLPictureElement>;

const useStyles = createUseStyles({
  picture: {
    display: 'block',
    position: 'relative',
    width: '100%',
    paddingTop: ({ aspectRatio }: { aspectRatio?: number }) =>  aspectRatio ? `${(aspectRatio * 100).toFixed(0)}%` : 0,
  },
  img: ({ aspectRatio }: { aspectRatio?: number }) => ({
    display: 'block',
    width: '100%',
    ...(aspectRatio ? {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      objectFit: 'cover',
    } : {})
  })
})

const ImageNoLazy: React.FC<Props> = ({  aspectRatio, src, alt, className, ...props }) => {
  const styles = useStyles({ aspectRatio });
  return (
    <img className={styles.img} src={src} alt={alt} />
  );
};

export default ImageNoLazy;
