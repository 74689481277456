// @ts-ignore

import {
  SubmitFormSuccessResponse,
  WebForm,
} from '../../shared/form';
import React, { useEffect, useState } from 'react';
import { createUseThemedStyles } from '../../hooks/createUseThemedStyles';
import Webform from '../Form/Webform';
import { WebformInputEmail } from './WebformInputEmail';
import WebformInputAction from './WebformInputAction';
import { WebFormErrors } from '../Form/Webform/types';
import useLanguageStrings from '../../hooks/useLanguageStrings';

type Props = {
  webForm: WebForm | null;
  onSubmitSuccess: (response: SubmitFormSuccessResponse) => void;
};

const useStyles = createUseThemedStyles((theme) => ({
  contactsForm: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'normal',
    [theme.breakpoints.md]: {
      //width: '39%',
      whiteSpace: 'nowrap'
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.xs]: {
      display: 'block',
    },
  },
  formError: {
    color: theme.color.primary,
    marginTop: theme.spacing.small,
    fontSize: '12px',
  },
}));

const SubscribeForm: React.FC<Props> = ({
  webForm,
  onSubmitSuccess,
}) => {
  const styles = useStyles();
  const components = { email: WebformInputEmail, actions: WebformInputAction };
  const [errors, setErrors] = useState<WebFormErrors>({});
  const dictionary = useLanguageStrings();

  const onErrorHandel = (errors: any) => {
    setErrors(errors.response.error);
  };

  return (
    <div className={styles.contactsForm}>
      {webForm && (
        <Webform
          className={styles.form}
          webform={webForm}
          onSuccess={onSubmitSuccess}
          onError={onErrorHandel}
          customComponents={components}
        />
      )}
      {errors && errors.email && (
        <div
          className={styles.formError}
          color='error'
          dangerouslySetInnerHTML={{ __html: dictionary['message.newsletter.unique'] }}
        />
      )}
    </div>
  );
};

export default SubscribeForm;
