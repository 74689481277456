import React, { Dispatch, SetStateAction } from 'react';
import { ArticleCategory } from "../../shared/article";
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";

type Props = {
  categories?: ArticleCategory[],
  setActiveCategoriesId?: Dispatch<SetStateAction<string[]>>,
  activeCategoriesId?: string[];
}

const useStyles = createUseThemedStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.md]: {
      ...theme.container.base,
    }
  },
  categories: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.medium,
    overflow: 'auto',
    margin: [0, 'auto'],
    padding: [0, theme.spacing.medium, theme.spacing.medium],
    [theme.breakpoints.md]: {
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'initial',
      padding: 0,
    }
  },
  input: {
    display: 'none',
  },
  filterButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
    minWidth: 110,
    padding: [10, 30],
    borderColor: theme.color.whiteText,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 9999,
    fontSize: 12,
    transition: 'all .3s ease',
  },
  filterButtonActive: {
    borderColor: theme.color.primary,
    backgroundColor: theme.color.primary,
    color: theme.color.white,
  }
}));

const ArticlesCategories: React.FC<Props> = ({
  categories = [],
  setActiveCategoriesId = () => null,
  activeCategoriesId = []
}) => {
  const styles = useStyles();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, checked } = event.target;
    const includes = activeCategoriesId.includes(value);
    if (checked !== includes) {
      setActiveCategoriesId(ids => checked ?
          [...ids, value] :
          ids.filter(id => id !== value))
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.categories}>{
        categories.map((category) => {
          const isActive = activeCategoriesId.includes(category.field_category_id);
          return (
            <label
              className={`${styles.filterButton}${isActive ? ` ${styles.filterButtonActive}` : ''}`}
              htmlFor={`article-preview-${category.field_category_id}`}
              key={category.field_category_id}
            >
              {category.field_category}
              <input
                checked={isActive}
                onChange={handleInputChange}
                className={styles.input}
                type="checkbox"
                value={category.field_category_id}
                id={`article-preview-${category.field_category_id}`}
              />
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default ArticlesCategories;
