import React from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import { NavLink } from 'react-router-dom';
import { ArrayElement, DrupalBreadcrumbs } from "../../shared";
import { HeaderTheme, useHeaderThemeContext } from "../../context/HeaderThemeContext";

type Props = {
  breadcrumb: ArrayElement<ArrayElement<DrupalBreadcrumbs>>
  icon?: React.ReactNode,
}

const useStyles = createUseThemedStyles(theme => ({
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    all: 'unset',
    padding: [theme.spacing.small, 0],
  },
  text: {
    ...theme.link.primary,
    color: ({ ht }: any) => ht === 'light' ? theme.color.black : theme.color.white,
    fontSize: 11,
  },
  breadcrumbActive: {
    color: theme.color.primary,
    '& $text': {
      color: theme.color.primary,
    }
  }
}));

const BreadcrumbItem: React.FC<Props>= ({ breadcrumb, icon }) => {
  const [headerTheme] = useHeaderThemeContext();
  const styles = useStyles({ ht: headerTheme });

  return (
    <NavLink
      exact
      activeClassName={styles.breadcrumbActive}
      className={styles.breadcrumb}
      to={breadcrumb.url}
      key={breadcrumb.url}>
      <span className={styles.text}>
        {breadcrumb.text}
      </span>
      {icon}
    </NavLink>
  )
}

export default BreadcrumbItem;
