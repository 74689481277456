import { Dispatch, TouchEvent, useCallback, useRef, useState } from "react";

const useTouch = (
  open: Dispatch<React.SetStateAction<boolean>>,
  close: Dispatch<React.SetStateAction<boolean>>
) => {
  const touchRef = useRef<any>(null);
  const [scrollTop, setScrollTop] = useState<number | null>(0);
  const [screenY, setScreenY] = useState<number>(0);

  const handleTouchStart = useCallback((event: TouchEvent<HTMLDivElement>) => {
    const touchStart = event.changedTouches[event.changedTouches.length - 1];

    setScreenY(touchStart.screenY);
    setScrollTop((touchRef.current as HTMLDivElement | null)?.scrollTop ?? null);
  }, []);

  const handleTouchEnd = useCallback((event: TouchEvent<HTMLDivElement>) => {
    const touchEnd = event.changedTouches[event.changedTouches.length - 1];

    if (scrollTop !== 0) {
      return
    }

    if (touchEnd.screenY > screenY) {
      open(true);
    } else if (touchEnd.screenY < screenY) {
      close(false);
    }
  }, [scrollTop, screenY, open, close]);

  return {
    touchRef,
    handleTouchStart,
    handleTouchEnd,
  }
}

export default useTouch;
