import { useState, useEffect } from 'react';
import { WebForm, WebFormResponse } from '../shared/form';

const useWebForm = (getForm: () => Promise<WebFormResponse>) => {
  const [webForm, setWebForm] = useState<WebForm | null>(null);

  useEffect(() => {
    getForm()
      .then((response) =>
        setWebForm({
          ...response.data,
          attributes: {
            ...response.data.attributes,
            elements: response.data.attributes.elements.map((element) => ({
              ...element,
              attributes: [
                // REMOVE Title and make placeholder instead
                // ...element.attributes,
                {
                  name: '#placeholder',
                  value:
                    element.attributes?.find(({ name }) => name === '#title')
                      ?.value ?? '',
                },
                {
                  name: '#submit__label',
                  value:
                    element.attributes?.find(
                      ({ name }) => name === '#submit__label'
                    )?.value ?? '',
                },
              ],
            })),
          },
        })
      )
      .catch(console.warn);
  }, [getForm]);
  
  return webForm;
};

export default useWebForm;
