import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { createUseThemedStyles } from "../../hooks/createUseThemedStyles";
import "leaflet/dist/leaflet.css";
import hexToRgba from "hex-to-rgba";
import { Reseller, ResellerCategory, ResellerCountry, ResellersRestResponse } from "../../shared/resellers";
import Select from "../Form/Select";
import IconButton from "../IconButton";
import useLanguageStrings from "../../hooks/useLanguageStrings";
import { AiOutlineSearch } from 'react-icons/ai';
import { BiCurrentLocation } from 'react-icons/bi';
import { IoChevronForwardCircleSharp } from 'react-icons/io5';
import { VignonTheme } from "../../theme";
import { useTheme } from "react-jss";
import { Markup } from "../Typography";
import ResellerPreview from "./ResellerPreview";
import ResellerPreviewBig from "./ResellerPreviewBig";
import { m, motion } from "framer-motion";
import cn from "classnames";
import { useIsDesktop } from "../../context/ScreenSize";
import { headerHeightMobile } from "../../shared/styles";
import useTouch from '../../hooks/useTouch';

type Props = {
  countries: Array<ResellerCountry>;
  categories: Array<ResellerCategory>;
  revenders: Array<Reseller>;
  loading: boolean
  country: string
  setCountry: Dispatch<SetStateAction<string>>
  queryString: string
  setQueryString: Dispatch<SetStateAction<string>>
  setCurrentLocation: Dispatch<SetStateAction<GeolocationPosition | null>>
  categoryId: string | null
  setCategoryId: Dispatch<SetStateAction<string | null>>
  setActiveReseller: Dispatch<SetStateAction<string | null>>
  hidden: boolean
}

const useStyles = createUseThemedStyles(theme => ({
  aside: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    maxWidth: 480,
    width: '100%',
    zIndex: 6,
    background: hexToRgba(theme.color.white, 0.9),
    transition: 'background .3s ease',
    overflow: 'hidden',
  },
  asideHidden: {
    background: hexToRgba(theme.color.white, 0.5),
  },
  asideContent: {
    overflow: 'auto',
    height: '100%',
    padding: 40,
    opacity: 1,
    transition: 'opacity .3s ease',
    '$asideHidden &': {
      opacity: 0,
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    flexGrow: 1,
    position: 'relative',
    marginRight: theme.spacing.medium,
    '& input': {
      height: 45,
      linHeight: 45,
      width: '100%',
      borderRadius: 9999,
      border: `1px solid ${theme.color.grayLine}`,
      fontSize: 14,
      color: theme.color.grayText,
      fontFamily: theme.fonts.base,
      background: 'transparent',
      padding: [0, 70, 0, 20],
      outline: 'none',
      appearance: 'none',
      '&::placeholder': {
        height: 45,
        linHeight: 45,
        color: theme.color.grayText,
        opacity: 0.5,
        textTransform: 'capitalize',
      }
    }
  },
  searchIcon: {
    position: 'absolute',
    color: theme.color.grayText,
    top: '50%',
    right: 50,
    transform: 'translateY(-50%)',
  },
  currentLocationButton: {
    position: 'absolute',
    color: theme.color.primary,
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)',
  },
  categories: {
    padding: [30, 20],
  },
  category: {
    padding: [30, 0, 20],
    borderTop: `0.5px solid ${theme.color.grayMask}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: 25,
  },
  categoryTitle: {
    fontSize: 30,
    lineHeight: 1,
    fontStyle: 'italic',
    color: theme.color.gray,
    margin: 0,
    [theme.breakpoints.md]: {
      fontSize: 36,
    },
  },
  categoryDescription: {
    fontSize: 12,
    color: theme.color.grayMask,
  },
  categoryLine: {
    display: 'block',
    width: 10,
    height: 2,
    background: theme.color.primary,
  },
  resellers: {
    padding: [30, 0],
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    borderTop: `0.5px solid ${theme.color.grayLine}`,
  },
  resellersTitle: {
    fontSize: 17,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    color: theme.color.gray,
    marginBottom: 25,
    textTransform: 'uppercase',
    marginTop: 0,
  },
  showButton: {
    position: 'absolute',
    top: 50,
    right: 15,
    zIndex: 1,
    border: 'none',
    padding: 0,
    outline: 'none',
    color: theme.color.primary,
    background: 'transparent',
    cursor: 'pointer',
  },
  minimizeButton: {
    display: 'block',
    background: theme.color.grayLine,
    border: 'none',
    borderRadius: 5,
    height: 10,
    appearance: 'none',
    padding: 0,
    width: 70,
    margin: [10 , 'auto', 0],
    opacity: 0.8
  }
}));

const createCountrySelectStyles = (theme: VignonTheme): any => ({
  control: {
    minWidth: 50,
  },
  menu: {
    minWidth: 50,
  },
  option: {
    fontSize: 14,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
  },
  singleValue: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
  },
  input: {
    fontSize: 14,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
  },
  dropdownIndicator: {
    padding: 0,
    color: theme.color.grayText,
  },
  valueContainer: {
    padding: 0,
  },
});
const createCategorySelectStyles = (theme: VignonTheme): any => ({
  control: {
    minWidth: '100%',
  },
  menu: {
    minWidth: '100%',
  },
  option: {
    fontSize: 14,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  singleValue: {
    fontSize: 14,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
    color: theme.color.gray,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  input: {
    fontSize: 14,
    fontFamily: theme.fonts.base,
    fontStyle: 'normal',
  },
  dropdownIndicator: {
    padding: 0,
    color: theme.color.grayText,
  },
  valueContainer: {
    padding: 0,
  },
  placeholder: {
    color: theme.color.gray,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
})

type SelectOption = { label: string, value: string }

const Aside: React.FC<Props> = ({ countries: defaultCountries, categories: defaultCategories, revenders, setCurrentLocation, setActiveReseller, hidden, loading, setCountry, country: countryId, categoryId, setCategoryId, queryString, setQueryString }) => {
  const styles = useStyles();
  const countries = defaultCountries.map(({ code, label }) => ({ value: code.toLowerCase(), label: code.toUpperCase() }));
  const categories = defaultCategories.map(({ label, id }) => ({ value: id, label: label ?? '' }));
  const countryValue = countries.find(c => c.value === countryId);
  const categoryValue = categories.find(c => c.value === categoryId);
  const dictionary = useLanguageStrings();
  const theme = useTheme() as VignonTheme;
  const countrySelectStyles = createCountrySelectStyles(theme)
  const categorySelectStyles = createCategorySelectStyles(theme);
  const category = defaultCategories.find(c => c.id === categoryId);
  const isDesktop = useIsDesktop();
  const [minimized, setMinimized] = useState(!isDesktop);
  const {
    touchRef,
    handleTouchStart,
    handleTouchEnd,
  } = useTouch(setMinimized, setMinimized);

  const createHandleSelectChange = (setter: Dispatch<SetStateAction<any>>) => (option: SelectOption | null) => {
    if (option?.value) {
      setter(option.value)
    }
  }

  const handleOnShopClick = useCallback((reseller: Reseller) => {
    setActiveReseller(reseller.id);
    setMinimized(true);
  }, [setActiveReseller]);

  const handleOnLocationClick = () => {
    navigator?.geolocation?.getCurrentPosition(setCurrentLocation)
  }

  useEffect(() => {
    return () => {
      setActiveReseller(null);
    }
  }, []);

  return (
    <motion.aside
      animate={{
        x: hidden ? (isDesktop ? -415 : -480) : 0,
        y: minimized ? (isDesktop ? 0 : `calc(var(--app-height) - ${200 + headerHeightMobile}px)`) : 0,
        transition: {
          ease: 'easeInOut',
        },
        height: minimized ? (isDesktop ? '100%' : `calc(100% - (var(--app-height) - ${200 + headerHeightMobile}px))`) : '100%',
      }}
      className={cn(styles.aside, hidden && styles.asideHidden)}
    >
      {!isDesktop && (
        <button className={styles.minimizeButton} onClick={() => setMinimized(m => !m)} />
      )}
      <motion.button
        className={styles.showButton}
        animate={{ opacity: hidden ? 1 : 0, transition: { duration: 0.15 } }}
        onClick={() => setActiveReseller(null)}
      >
        <IoChevronForwardCircleSharp size={35} />
      </motion.button>
      <div
        ref={touchRef}
        className={styles.asideContent}
        style={{
          overflowY: minimized ? (isDesktop ? 'visible' : 'hidden') : 'visible',
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className={styles.search}>
          <div className={styles.searchInput}>
          <input
            type="text"
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            placeholder={dictionary.search}
          />
            <AiOutlineSearch className={styles.searchIcon} size={22} />
            <IconButton className={styles.currentLocationButton}
              onClick={handleOnLocationClick}
            >
              <BiCurrentLocation size={22} />
            </IconButton>
          </div>
          <Select<SelectOption>
            options={countries}
            value={countryValue}
            onChange={createHandleSelectChange(setCountry)}
            isSearchable={false}
            styles={countrySelectStyles}
          />
        </div>
        <div className={styles.categories}>
          <Select<SelectOption>
            options={categories}
            value={categoryValue}
            onChange={createHandleSelectChange(setCategoryId)}
            isSearchable={false}
            styles={categorySelectStyles}
            placeholder={dictionary['refine.by.category']}
          />
        </div>
        {category && (
          <div className={styles.category}>
            <h3 className={styles.categoryTitle}>{category.label}</h3>
            <div className={styles.categoryLine} />
            <Markup className={styles.categoryDescription} html={category.description} />
          </div>
        )}
        <div className={styles.resellers}>
          {/* <h4 className={styles.resellersTitle}>{dictionary['our.clients']}</h4> */}
          {revenders.map((reseller, index) => index ?
            <ResellerPreview reseller={reseller} key={index} onClick={handleOnShopClick}  /> :
            <ResellerPreviewBig reseller={reseller} key={index} onClick={handleOnShopClick} />
          )}
        </div>
      </div>
    </motion.aside>
  );
}

export default Aside;
